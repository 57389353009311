import React, { Component } from "react";
import Product from "Models/Product";
import General from "Helpers/General";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import Moment from "react-moment";

class LiveStreamingList extends Component {
  constructor(props) {
    super(props);
    // const userId = this.props.userId;
    console.log(props.products);
    this.state = {
      // userId: userId,
      allLiveStreamingProducts: props.products,
      allUpcomingLiveStreamingProducts: props.upcomingProducts,
      showHideListData: true,
      showHideGridData: false,
      openProductFilter: false,
    };
    this.hideComponent = this.hideComponent.bind(this);
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.products !== state.allLiveStreamingProducts) {
      return {
        allLiveStreamingProducts: props.products,
      };
    }
  };

  hideComponent(name) {
    switch (name) {
      case "showHideListData":
        this.setState({ showHideListData: !this.state.showHideListData });
        this.setState({ showHideGridData: false });
        break;
      case "showHideGridData":
        this.setState({ showHideGridData: !this.state.showHideGridData });
        this.setState({ showHideListData: false });
        break;
      default:
        this.setState({ showHideListData: !this.state.showHideListData });
        break;
    }
  }

  // componentDidMount = () => {
  //   var self = this;
  //   Product.postApi(
  //     "APIS_GET_LIVE_STREAMING_PRODUCTS_BY_USER",
  //     { userId: this.state.userId },
  //     async (response) => {
  //       if (response.length) {
  //         console.log(response);
  //         self.setState({ allLiveStreamingProducts: response });
  //       }
  //     },
  //     false
  //   );
  // };

  render() {
    const {
      showHideListData,
      showHideGridData,
      allLiveStreamingProducts,
      allUpcomingLiveStreamingProducts,
    } = this.state;
    // console.log(allLiveStreamingProducts)
    return (
      <React.Fragment>
        <div
          className="tab-pane tabactive_target allcom_rodtab02"
          id="Jcompany_activity"
          role="tabpanel"
        >
          <div className="allprod_adverts">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-3 subtab_firstmain paddingrigt_none_Cfilter"
                  id="subtab_fourthfixed"
                >
                  <div className="allpeod_adstitle">
                    <h4>Live Streaming</h4>
                  </div>
                  <div
                    className={`product-sideBar-parent new_perenttop_forspecals ${this.state.openProductFilter ? "active" : ""
                      }`}
                  >
                    <div className="product-listing-sideBar">
                      <div className="sidebarleft_newinner">
                        <div id="filter-closer">
                          <img
                            src="/images/cancel.svg"
                            alt="icon"
                            onClick={() =>
                              this.setState({ openProductFilter: false })
                            }
                          />
                        </div>
                        <div className="product-listing-sideBar-colum sideBar-category">
                          <h2 className="toggle-category">
                            Category
                            {/* <i className="fas fa-chevron-down"></i> */}
                          </h2>
                          <ul className="toggle-bx">
                            <li
                              className={`${this.props.selectedCategory === ""
                                  ? "active"
                                  : ""
                                }`}
                            >
                              <Link onClick={() => this.props.categoryFilter()}>
                                All
                              </Link>
                            </li>
                            {this.props.categories &&
                              this.props.categories.map((category) => (
                                <li
                                  key={category._id}
                                  className={`${category._id === this.props.selectedCategory
                                      ? "active"
                                      : ""
                                    }`}
                                >
                                  <Link
                                    onClick={() =>
                                      this.props.categoryFilter(category._id)
                                    }
                                  >
                                    {category.categoryName}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                        {/* <div className="product-listing-sideBar-colum sideBar-location">
                          <h2>Filters</h2>
                          <div className="form-group">
                            <input
                              type="text"
                              name="location"
                              className="form-control-line"
                              placeholder="Keyword"
                            />
                          </div>
                          <div className="form-group mb-0 sideBar-choose-distance">
                            <div className="form-group-icon-bx">
                              <select className="form-control-line">
                                <option>Title</option>
                                <option>Most two</option>
                                <option>Most three</option>
                              </select>
                              <span className="form-group-icon">
                                <img
                                  src="/images/down-arrow-icon.png"
                                  alt="icon"
                                />
                              </span>
                            </div>
                            <button className="go-btn">GO</button>
                          </div>
                        </div> */}
                        <div className="product-listing-sideBar-colum sideBar-category">
                          <h2>Price</h2>
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="min_price"
                                  className="form-control-line"
                                  placeholder="Min"
                                  onChange={(e) => this.props.handlePrice(e)}
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="max_price"
                                  className="form-control-line"
                                  placeholder="Max"
                                  onChange={(e) => this.props.handlePrice(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="product-listing-sideBar-colum other-option otheropt_newcls">
                        <h2>Other Options</h2>
                        <div className="otheroption_cheperent">
                          <div className="check-box">
                            <input
                              type="checkbox"
                              name="avaliableNegotiation"
                              id="results-with-pic1"
                              onClick={(e) => this.props.otherOptionHandler(e)}
                            />
                            <label htmlFor="results-with-pic1">
                              Upcoming Live Auction
                            </label>
                          </div>
                          <div className="check-box">
                            <input
                              type="checkbox"
                              name="avaliableExchange"
                              id="avaliable-exchange1"
                              onClick={(e) => this.props.otherOptionHandler(e)}
                            />
                            <label htmlFor="avaliable-exchange1">
                              Live Auction
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 subtab_secondmain productlist_righhtt allprodlist_adverd">
                  <div
                    id="filter-toggler"
                    className="btnx btn-secondaryx mx-3"
                    onClick={() => this.setState({ openProductFilter: true })}
                  >
                    All Filter
                  </div>
                  <span className="allprod_pageonlyy">
                    Live Streaming by JMG
                  </span>
                  <div className="new_placetab_icon2">
                    <div className="form-group-icon-bx">
                      <select
                        className="form-control-line"
                        onChange={this.props.filterSortingHandler}
                      >
                        <option value="">Most Recent First</option>
                        <option value="LowToHigh">Price: Low to High</option>
                        <option value="HighToLow">Price: High to Low</option>
                      </select>
                      <span className="form-group-icon">
                        <img src="/images/down-arrow-icon.png" alt="icon" />
                      </span>
                    </div>
                    <div className="showing-result">
                      {/* {showHideListData ? (
                        <Link className="gridList" id="tabOne">
                          <i className="fas fa-list "></i>
                        </Link>
                      ) : (
                        <Link
                          className=""
                          id="tabOne"
                          onClick={() => this.hideComponent("showHideListData")}
                        >
                          <i className="fas fa-list "></i>
                        </Link>
                      )}

                      {showHideGridData ? (
                        <Link className="gridList" id="tabOne">
                          <i className="fas fa-th "></i>
                        </Link>
                      ) : (
                        <Link
                          className=""
                          id="tabOne"
                          onClick={() => this.hideComponent("showHideGridData")}
                        >
                          <i className="fas fa-th "></i>
                        </Link>
                      )} */}
                    </div>
                  </div>
                  <div className="Jcompny_allprod  tab_themeB">
                    <div className="Jcompny_actin tab_Jcompny tab-themeB">
                      <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            data-toggle="tab"
                            href="#tabs-1"
                            role="tab"
                          >
                            live Streaming
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabs-2"
                            role="tab"
                          >
                            Upcomimg live Streaming
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="tab-content border-0 p-0">
                      <div class="tab-pane active" id="tabs-1" role="tabpanel">
                        {showHideListData && (
                          <div className="Jtaballp_content atlistingside_new  mt-3">
                            <div className="row">
                              {allLiveStreamingProducts &&
                                allLiveStreamingProducts.length > 0 ? (
                                <>
                                  {allLiveStreamingProducts.map(function (row) {
                                    return (
                                      <div
                                        className="col-lg-12 listside_newperent"
                                        key={
                                          "allLiveStreamingProducts" + row._id
                                        }
                                      >
                                        <div className="product-listing-product">
                                          <div className="row">
                                            <div className="col-3">
                                              <div className="product-img-bx">
                                                <Link
                                                  to={
                                                    "/product/" +
                                                    row.productSlug
                                                  }
                                                >
                                                  {row.adType &&
                                                    row.adType ===
                                                    "Featured" && (
                                                      <div className="specialtags">
                                                        <span
                                                          className={
                                                            (row.adType ===
                                                              "Featured"
                                                              ? "black"
                                                              : "green") +
                                                            "_special"
                                                          }
                                                        >
                                                          {row.adType}{" "}
                                                        </span>
                                                      </div>
                                                    )}
                                                  {row.productMainImage ? (
                                                    <img
                                                      src={General.showImage(
                                                        "products/resize-566-400",
                                                        row.productMainImage
                                                      )}
                                                      alt={row.productName}
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/images/no-image.jpg"
                                                      alt="product"
                                                    />
                                                  )}
                                                  <div className="totalimg_sectab">
                                                    <Link
                                                      to={`/product/${row.productSlug}`}
                                                      className="totlimg_qt"
                                                    >
                                                      {row.productImages
                                                        ? row.productImages
                                                          .length
                                                        : 0}{" "}
                                                      <i className="fas fa-camera"></i>
                                                    </Link>
                                                  </div>
                                                </Link>
                                              </div>
                                            </div>
                                            <div className="col-9">
                                              <div className="product-outer-bx productBig-outer-bx">
                                                <div className="product-name-bx content_gobottom_new">
                                                  <Moment fromNow>
                                                    {row.time}
                                                  </Moment>
                                                  <Link
                                                    to={
                                                      "/product/" +
                                                      row.productSlug
                                                    }
                                                  >
                                                    <h4>{row.productName}</h4>
                                                  </Link>
                                                  <h5>{row.location}</h5>
                                                  <h6>
                                                    <span>
                                                      {General.showPriceDecimal(
                                                        row.productPrice
                                                      )}
                                                    </span>
                                                  </h6>
                                                  <p>
                                                    {row.productDescription}
                                                  </p>
                                                  {/* <button className="add-to-wishlist">
                                                <i className="far fa-heart"></i>
                                              </button>
                                              <div className="usednew_button">
                                                <a
                                                  href="#"
                                                  className="btnnew_used"
                                                >
                                                  New
                                                </a>
                                              </div> */}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <div className="col-lg-12">
                                  <div className="alert alert-danger ml-0">
                                    <strong>Info!</strong> Nothing here.
                                  </div>{" "}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {showHideGridData && (
                          <div className="Jtaballp_content02 auctionplist_main ">
                            <div className="col-sm-12 actionpage_list">
                              <div className="row low-margin">
                                {allLiveStreamingProducts &&
                                  allLiveStreamingProducts.length > 0 ? (
                                  <>
                                    {allLiveStreamingProducts.map(function (
                                      row
                                    ) {
                                      return (
                                        <div
                                          className="col-sm-3"
                                          key={
                                            "allLiveStreamingProducts" + row._id
                                          }
                                        >
                                          <div className="product-outer-bx">
                                            <Link to={"/product/" + row._id}>
                                              <div className="product-img-bx">
                                                {row.productMainImage ? (
                                                  <img
                                                    src={General.showImage(
                                                      "products/resize-566-400",
                                                      row.productMainImage
                                                    )}
                                                    alt={row.productName}
                                                  />
                                                ) : (
                                                  <img
                                                    src="/images/no-image.jpg"
                                                    alt="product"
                                                  />
                                                )}
                                                {row.adType &&
                                                  row.adType === "Featured" && (
                                                    <div className="specialtags">
                                                      <span
                                                        className={
                                                          (row.adType ===
                                                            "Featured"
                                                            ? "black"
                                                            : "green") +
                                                          "_special"
                                                        }
                                                      >
                                                        {row.adType}{" "}
                                                      </span>
                                                    </div>
                                                  )}
                                                <div className="totalimg_sectab">
                                                  <Link
                                                    to={`/product/${row._id}`}
                                                    className="totlimg_qt"
                                                  >
                                                    {row.productImages
                                                      ? row.productImages.length
                                                      : 0}{" "}
                                                    <i className="fas fa-camera"></i>
                                                  </Link>
                                                </div>
                                              </div>
                                              <div className="product-name-bx">
                                                <Moment fromNow>
                                                  {row.time}
                                                </Moment>
                                                <h4>{row.productName}</h4>
                                                <h3>{row.location}</h3>
                                                <h6>
                                                  {/* <Link
                                                to={"/product/" + row._id}
                                                className="btnnew_used"
                                              >
                                                New
                                              </Link>{" "} */}
                                                  <span>
                                                    {General.showPriceDecimal(
                                                      row.productPrice
                                                    )}
                                                  </span>
                                                </h6>
                                              </div>
                                            </Link>
                                            {/* <button className="add-to-wishlist">
                                          <i className="far fa-heart"></i>
                                        </button> */}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <div className="col-lg-12">
                                    <div className="alert alert-danger ml-0">
                                      <strong>Info!</strong> Nothing here.
                                    </div>{" "}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div class="tab-pane" id="tabs-2" role="tabpanel">
                        {showHideListData && (
                          <div className="Jtaballp_content atlistingside_new  mt-3">
                            <div className="row">
                              {allUpcomingLiveStreamingProducts &&
                                allUpcomingLiveStreamingProducts.length > 0 ? (
                                <>
                                  {allUpcomingLiveStreamingProducts.map(
                                    function (row) {
                                      return (
                                        <div
                                          className="col-lg-12 listside_newperent"
                                          key={
                                            "allLiveStreamingProducts" + row._id
                                          }
                                        >
                                          <div className="product-listing-product">
                                            <div className="row">
                                              <div className="col-3">
                                                <div className="product-img-bx">
                                                  <Link
                                                    to={
                                                      "/product/" +
                                                      row.productSlug
                                                    }
                                                  >
                                                    {row.adType &&
                                                      row.adType ===
                                                      "Featured" && (
                                                        <div className="specialtags">
                                                          <span
                                                            className={
                                                              (row.adType ===
                                                                "Featured"
                                                                ? "black"
                                                                : "green") +
                                                              "_special"
                                                            }
                                                          >
                                                            {row.adType}{" "}
                                                          </span>
                                                        </div>
                                                      )}
                                                    {row.productMainImage ? (
                                                      <img
                                                        src={General.showImage(
                                                          "products/resize-566-400",
                                                          row.productMainImage
                                                        )}
                                                        alt={row.productName}
                                                      />
                                                    ) : (
                                                      <img
                                                        src="/images/no-image.jpg"
                                                        alt="product"
                                                      />
                                                    )}
                                                    <div className="totalimg_sectab">
                                                      <Link
                                                        to={`/product/${row.productSlug}`}
                                                        className="totlimg_qt"
                                                      >
                                                        {row.productImages
                                                          ? row.productImages
                                                            .length
                                                          : 0}{" "}
                                                        <i className="fas fa-camera"></i>
                                                      </Link>
                                                    </div>
                                                  </Link>
                                                </div>
                                              </div>
                                              <div className="col-9">
                                                <div className="product-outer-bx productBig-outer-bx">
                                                  <div className="product-name-bx content_gobottom_new">
                                                    <Moment fromNow>
                                                      {row.time}
                                                    </Moment>
                                                    <Link
                                                      to={
                                                        "/product/" +
                                                        row.productSlug
                                                      }
                                                    >
                                                      <h4>{row.productName}</h4>
                                                    </Link>
                                                    <h5>{row.location}</h5>
                                                    <h6>
                                                      <span>
                                                        {General.showPriceDecimal(
                                                          row.productPrice
                                                        )}
                                                      </span>
                                                    </h6>
                                                    <p>
                                                      {row.productDescription}
                                                    </p>
                                                    {/* <button className="add-to-wishlist">
                                                <i className="far fa-heart"></i>
                                              </button>
                                              <div className="usednew_button">
                                                <a
                                                  href="#"
                                                  className="btnnew_used"
                                                >
                                                  New
                                                </a>
                                              </div> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <div className="col-lg-12">
                                  <div className="alert alert-danger ml-0">
                                    <strong>Info!</strong> Nothing here.
                                  </div>{" "}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {showHideGridData && (
                          <div className="Jtaballp_content02 auctionplist_main ">
                            <div className="col-sm-12 actionpage_list">
                              <div className="row low-margin">
                                {allUpcomingLiveStreamingProducts &&
                                  allUpcomingLiveStreamingProducts.length > 0 ? (
                                  <>
                                    {allUpcomingLiveStreamingProducts.map(
                                      function (row) {
                                        return (
                                          <div
                                            className="col-sm-3"
                                            key={
                                              "allLiveStreamingProducts" +
                                              row._id
                                            }
                                          >
                                            <div className="product-outer-bx">
                                              <Link to={"/product/" + row._id}>
                                                <div className="product-img-bx">
                                                  {row.productMainImage ? (
                                                    <img
                                                      src={General.showImage(
                                                        "products/resize-566-400",
                                                        row.productMainImage
                                                      )}
                                                      alt={row.productName}
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/images/no-image.jpg"
                                                      alt="product"
                                                    />
                                                  )}
                                                  {row.adType &&
                                                    row.adType ===
                                                    "Featured" && (
                                                      <div className="specialtags">
                                                        <span
                                                          className={
                                                            (row.adType ===
                                                              "Featured"
                                                              ? "black"
                                                              : "green") +
                                                            "_special"
                                                          }
                                                        >
                                                          {row.adType}{" "}
                                                        </span>
                                                      </div>
                                                    )}
                                                  <div className="totalimg_sectab">
                                                    <Link
                                                      to={`/product/${row._id}`}
                                                      className="totlimg_qt"
                                                    >
                                                      {row.productImages
                                                        ? row.productImages
                                                          .length
                                                        : 0}{" "}
                                                      <i className="fas fa-camera"></i>
                                                    </Link>
                                                  </div>
                                                </div>
                                                <div className="product-name-bx">
                                                  <Moment fromNow>
                                                    {row.time}
                                                  </Moment>
                                                  <h4>{row.productName}</h4>
                                                  <h3>{row.location}</h3>
                                                  <h6>
                                                    {/* <Link
                                                to={"/product/" + row._id}
                                                className="btnnew_used"
                                              >
                                                New
                                              </Link>{" "} */}
                                                    <span>
                                                      {General.showPriceDecimal(
                                                        row.productPrice
                                                      )}
                                                    </span>
                                                  </h6>
                                                </div>
                                              </Link>
                                              {/* <button className="add-to-wishlist">
                                          <i className="far fa-heart"></i>
                                        </button> */}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <div className="col-lg-12">
                                    <div className="alert alert-danger ml-0">
                                      <strong>Info!</strong> Nothing here.
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/*<div className="loading-bx">
                          <button><i className="fas fa-spinner fa-spin"></i> Loading</button>
                       </div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LiveStreamingList;
