import General from "Helpers/General";
import React, { Component } from "react";
import Moment from "react-moment";
import MyProfile from "Models/MyProfile";
import { Link } from "react-router-dom";
import Modal from "react-modal";

export class DisputeLiveAuction extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      userId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
      disputeAuctionProducts: [],
      showModal: false,
      modalProducts: [],
      bidEnded: "",
      showWinner: true,
      isLoading: false,
    };
  }

  dateTimeConverson = (dateObj) => {
    const dateTime = new Date(dateObj);
    return dateTime.toLocaleString("en-ZA", { hour12: true });
  };

  componentDidMount = () => {
    this.getAllDisputeAuctionProducts();
  };

  getAllDisputeAuctionProducts = () => {
    MyProfile.postApi(
      "API_GET_USER_DISPUTE_AUCTION_PRODUCTS",
      {},
      async (response) => {
        this.setState({
          disputeAuctionProducts: response.disputeAuctionProducts,
        });
        this.setState({ isLoading: false });
      },
      true
    );
  };

  render() {
    const { showModal, modalProducts, userId } = this.state;
    const { productData, loginUser } = this.props;
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    const orderStatusHandler = (orderId, orderStatus) => {
      if (orderStatus) {
        MyProfile.postApi(
          "API_CHANGE_LIVE_AUCTION_STATUS",
          { id: orderId, status: orderStatus },
          async (res, status) => {
            if (status === "success") {
              productData["status"] = Number(orderStatus);
              this.setState({ productData: productData });
            }
          },
          true
        );
      }
    };

    return (
      <div key={`upcomingLive_${productData._id}`}>
        <div className="col-lg-12 listside_newperent">
          <div className="product-listing-product">
            <div className="row">
              <div className="col-md-3">
                <div className="product-img-bx">
                  <Link to={MyProfile.isLogin() ? {
                    pathname: "/livestreamingProduct/" +
                      productData.streamId,
                    search: `?product_id=${productData.products._id}`,
                    query: {
                      productId: productData.products.productSlug,
                    },
                  } : "/upcomingLiveStreamingProduct/" +
                  productData.products.productSlug +
                  "/" +
                  productData.streamId
                  }>
                    {productData.products.adType &&
                      productData.products.adType === "Featured" && (
                        <div className="specialtags">
                          <span
                            className={
                              (productData.products.adType === "Featured"
                                ? "black"
                                : "green") + "_special"
                            }
                          >
                            {productData.products.adType}{" "}
                          </span>
                        </div>
                      )}
                    {productData.products.productMainImage ? (
                      <img
                        src={General.showImage(
                          "products/resize-566-400",
                          productData.products.productMainImage
                        )}
                        onError={(e) => (e.target.src = "/images/no-image.jpg")}
                        alt={productData.products.productName}
                      />
                    ) : (
                      <img src="/images/no-image.jpg" alt="product" />
                    )}

                    <div className="totalimg_sectab">
                      {productData.products.productImages
                        ? productData.products.productImages.length
                        : 0}{" "}
                      <i className="fas fa-camera"></i>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-9">
                <div className="product-outer-bx productBig-outer-bx">
                  <div className="product-name-bx content_gobottom_new">
                    <div className="main_div_crd">
                      <div className="main_div_left">
                        {productData?.createdAt && (
                          <Moment fromNow>{productData?.createdAt}</Moment>
                        )}
                        <Link
                          to={MyProfile.isLogin() ? {
                            pathname: "/livestreamingProduct/" +
                              productData.streamId,
                            search: `?product_id=${productData.products._id}`,
                            query: {
                              productId: productData.products.productSlug,
                            },
                          } : "/upcomingLiveStreamingProduct/" +
                          productData.products.productSlug +
                          "/" +
                          productData.streamId
                          }
                        >
                          <h4 className="product_mobile_view">{productData.products.productName}</h4>
                        </Link>
                        {/*  <h5>Duration: { ((Date.parse(productData.endTime) - Date.parse(productData.startTime))/60000) } mins</h5> */}
                        <h5>
                          Duration: {productData.live_stream?.length} mins
                        </h5>
                        <h6>
                          <span></span>
                        </h6>
                        <ul className="listofsubtabbx">
                          <li>
                            Auction Ended :{" "}
                            {this.dateTimeConverson(productData.endTime)}
                          </li>
                          {productData.isPaid !== 0 &&
                            productData.status === 4 && (
                              <>
                                {productData.user._id == userId ? (
                                  <>
                                    <li>
                                      Bought Out By :{" "}
                                      {productData?.winner.users.firstName +
                                        " " +
                                        productData?.winner.users.lastName}
                                    </li>
                                    <li>
                                      Winning Price :{" "}
                                      {General.showPriceDecimal(
                                        productData?.winner.amount
                                      )}
                                    </li>
                                  </>
                                ) : (
                                  <>
                                    <li>
                                      Seller :{" "}
                                      {productData?.user.firstName +
                                        " " +
                                        productData?.user.lastName}
                                    </li>
                                  </>
                                )}
                              </>
                            )}
                        </ul>

                        {productData.status === 6 && (
                          <ul>
                            <li>
                              <strong>Return Reson :</strong>
                              {productData?.reson_id !== null ? (
                                <span>{productData?.reson_id["name"]}</span>
                              ) : (
                                <span>{productData?.other_reson}</span>
                              )}
                            </li>
                          </ul>
                        )}
                        <li>
                          <strong>Dispute: </strong>
                          <span>
                            <Moment format="YYYY/MM/DD">
                              {productData?.createdAt}
                            </Moment>
                          </span>
                        </li>
                      </div>
                      <div className="main_div_right">
                        <div className="subtab_edit_ mt-2_ coustom_right_block">
                          <div className="subtab_editlabl btn_div">
                            {productData?.bidders &&
                              productData.bidders.length > 0 && (
                                <span
                                  className="labltable lablesct view_btn"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.setState({
                                      modalProducts: productData?.bidders ?? [],
                                      bidEnded: productData.endTime,
                                      showModal: true,
                                    })
                                  }
                                >
                                  <i className="far fa-eye"></i> View
                                </span>
                              )}
                            <div className="subtab_editlabl">
                              <span className="labltable lablesct">
                                {(() => {
                                  if (productData.status === 1) {
                                    return "Order Posted";
                                  } else if (productData.status === 2) {
                                    return "Shipped";
                                  } else if (productData.status === 3) {
                                    return "Delivered";
                                  } else if (productData.status === 4) {
                                    return "Completed";
                                  } else if (productData.status === 5) {
                                    return "Suspend";
                                  } else if (productData.status === 6) {
                                    return "Dispute";
                                  } else if (productData.status === 7) {
                                    return "Cancelled";
                                  } else if (productData.status === 8) {
                                    return "Resolved for Buyer";
                                  } else if (productData.status === 9) {
                                    return "Resolved for Seller";
                                  }
                                })()}
                              </span>
                            </div>
                          </div>
                          <div className="subtab_edit newPrdList_col-2 blankspace action-select-box">
                            <div className="subtab_editlabl select_bx">
                              {productData.status === 6 &&
                                productData?.return_status === "Rejected" &&
                                productData.userId === this.state.userId && (
                                  <select
                                    className="custom-select form-control"
                                    value={productData?.status}
                                    onChange={(e) =>
                                      orderStatusHandler(
                                        productData._id,
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="">Select Option</option>
                                    <option value="8">Resolve for Buyer</option>
                                  </select>
                                )}
                              {productData.status === 6 &&
                                productData?.return_status === "Rejected" &&
                                productData.userId !== this.state.userId && (
                                  <select
                                    className="custom-select form-control"
                                    value={productData?.status}
                                    onChange={(e) =>
                                      orderStatusHandler(
                                        productData._id,
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="">Select Option</option>
                                    <option value="9">
                                      Resolve for Seller
                                    </option>
                                  </select>
                                )}
                            </div>
                            {(() => {
                              if (productData.userId === this.state.userId) {
                                if (productData.sellerRequest === 1) {
                                  return (
                                    <>
                                      <div className="subtab_editlabl">
                                        <span className="labltable lablesct mb-2">
                                          Request Money
                                        </span>
                                      </div>
                                    </>
                                  );
                                } else if (productData.sellerRequest === 2)
                                  return (
                                    <>
                                      <div className="subtab_editlabl">
                                        <span className="labltable lablesct mb-2">
                                          Release Money
                                        </span>
                                      </div>
                                    </>
                                  );
                              }
                            })()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={showModal}
          ariaHideApp={false}
          className="popupModal stripePaymentModal"
          onRequestClose={() =>
            this.setState({ showModal: false, modalProducts: [] })
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
            },
          }}
        >
          <button
            onClick={() =>
              this.setState({ showModal: false, modalProducts: [] })
            }
            className="shipCloseBtn"
          >
            <i className="fa fa-times"></i>
          </button>
          <div className="scrollblePopUp">
            <div className="col-lg-12 listside_newperent">
              <p className="font-weight-bold">
                <span>Bidders : {modalProducts.length}</span>
                <span className="ml-5">
                  Bid Time Ended : {this.dateTimeConverson(this.state.bidEnded)}
                </span>
              </p>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Bidders</th>
                      <th>Bid Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalProducts &&
                      modalProducts.map((bidder, index) => (
                        <tr key={`bidder_${bidder._id}`}>
                          <td>
                            <div>
                              {bidder?.users?.profilePicture ? (
                                <img
                                  src={General.showImageNew(
                                    "profile",
                                    bidder?.users?.profilePicture
                                  )}
                                  onError={(e) =>
                                    (e.target.src = "/images/user.png")
                                  }
                                  alt={
                                    bidder?.users?.firstName +
                                    " " +
                                    bidder?.users?.lastName
                                  }
                                  width="30"
                                  className="mCS_img_loaded"
                                />
                              ) : (
                                <img
                                  src="/images/user.png"
                                  alt={
                                    bidder?.users?.firstName +
                                    " " +
                                    bidder?.users?.lastName
                                  }
                                  width="30"
                                  className="mCS_img_loaded"
                                />
                              )}
                              <p>
                                <small>
                                  {bidder?.users?.firstName +
                                    " " +
                                    bidder?.users?.lastName}
                                </small>
                              </p>
                            </div>
                          </td>
                          <td>
                            {index === 0 ? (
                              <b>
                                You won the bid{" "}
                                {General.showPriceDecimal(bidder?.amount)}{" "}
                              </b>
                            ) : (
                              <b>
                                You were out bid{" "}
                                {General.showPriceDecimal(bidder?.amount)}{" "}
                              </b>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DisputeLiveAuction;
