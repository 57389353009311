import React, { Component } from "react";
import SocialForm from 'Forms/SocialForm';
import User from "Models/User";
import Lodash from "lodash";
import { Link } from "react-router-dom";

class ForgetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = User.dbSchema("FORGET_PASSWORD")
  }

  componentDidMount = () => {
  }

  submitHandler = async (event) => {
    event.preventDefault();
    if (await User.checkValidation(await User.validationRules(), this.state) === true) {
      User.postApi("APIS_FORGET_PASSWORD", this.state, async (response) => {
        if (response == true) {
          User.redirectTo("login", true);
        }
      }, false);
    }
  };

  onChangeHanlde = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <form onSubmit={this.submitHandler} method="post">
        <div className="formgrop_main_New row">
          <div className="col-sm-12 margin_top_new">
            <div className="form-group">
              <input type="text" onChange={this.onChangeHanlde} value={this.state.email} name="email" className="form-control New_control" placeholder="Email" autoCapitalize="none" />
            </div>
          </div>
          <div className="col-sm-12 marginfour_side">
            <div className="form-group text-center">
              <button type="submit" className="btnx btn-secondaryx">Send Request</button>
            </div>
          </div>
        </div>
        <div className="donthave_New text-center">
          <p>Login to your account <Link to="/login">Login</Link></p>
        </div>
      </form>
    );
  }
}
export default ForgetPasswordForm;
