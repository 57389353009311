import React, { Component } from 'react';
import moment from 'moment';

class HorizontalLineTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            duration: moment(this.props.streamingTime).diff(moment(), 'seconds'),
            remainingTime: moment(this.props.streamingTime).diff(moment(), 'seconds'),
        };
        this.timerInterval = null;
    }

    componentDidMount() {
        this.startTimer();
    }

    componentWillUnmount() {
        clearInterval(this.timerInterval);
    }

    startTimer = () => {
        this.timerInterval = setInterval(() => {
            this.setState((prevState) => {
                const newRemainingTime = prevState.remainingTime - 1;
                if (newRemainingTime <= 0) {
                    clearInterval(this.timerInterval);
                    return { remainingTime: 0 };
                }
                return { remainingTime: newRemainingTime };
            });
        }, 1000);
    };

    render() {
        const { duration, remainingTime } = this.state;
        const percentage = (remainingTime / duration) * 100;
        return (
            <div style={{ width: "150px", height: "16px", backgroundColor: "#20A151", borderRadius: "8px", overflow: "hidden" }}>
                <div
                    style={{
                        width: `${percentage}%`,
                        height: "100%",
                        backgroundColor: "#28a745",
                        transition: "width 1s linear",
                    }}
                ></div>
            </div>
        );
    }
}

export default HorizontalLineTimer;
