import General from "Helpers/General";
import Product from "Models/Product";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import ContentLoader from "react-content-loader";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Moment from "react-moment";

export class LiveStreaming extends Component {
  constructor(props) {
    super(props);
    const liveNothingD = localStorage.getItem("AllLiveStreamings")
      ? true
      : false;
    const upcomingNothingD = localStorage.getItem("AllUpcomingStreamings")
      ? true
      : false;

    // All Categories
    const AllCategories = localStorage.getItem("MainCategories")
      ? JSON.parse(localStorage.getItem("MainCategories"))
      : [];

    this.state = {
      liveStreamingFilters: {
        page_number: 1,
      },
      LiveStreamings: localStorage.getItem("AllLiveStreamings")
        ? JSON.parse(localStorage.getItem("AllLiveStreamings"))
        : [],
      UpcomingStreamings: localStorage.getItem("AllUpcomingStreamings")
        ? JSON.parse(localStorage.getItem("AllUpcomingStreamings"))
        : [],
      liveStreamNothingD: liveNothingD,
      upcomingNothingD: upcomingNothingD,
      openProductFilter: false,
      categories: AllCategories,
      auctionType: "live",
      totalRecordsLiveStreaming: localStorage.getItem(
        "totalRecordsLiveStreaming"
      )
        ? localStorage.getItem("totalRecordsLiveStreaming")
        : 0,
      totalRecordsUpcomingLiveStreaming: localStorage.getItem(
        "totalRecordsUpcomingLiveStreaming"
      )
        ? localStorage.getItem("totalRecordsUpcomingLiveStreaming")
        : 0,
      productSkeleton: [
        { id: 0 },
        { id: 340 },
        { id: 680 },
        { id: 1020 },
        { id: 1360 },
      ],
    };
  }

  componentDidMount = () => {
    this.getAllLiveStreamingProducts();
  };

  getAllLiveStreamingProducts = () => {
    Product.postApiByParams(
      "API_GET_Live_STREAMING_PAGE_DATA",
      this.state.liveStreamingFilters,
      async (response, status) => {
        if (response) {

          localStorage.setItem(
            "totalRecordsLiveStreaming",
            response.totalRecords
          );
          localStorage.setItem(
            "totalRecordsUpcomingLiveStreaming",
            response.totalRecordsUpcoming
          );
          this.setState({ totalRecords: response.totalRecords });
          this.setState({
            totalRecordsUpcomingLiveStreaming: response.totalRecordsUpcoming,
          });
          this.setState({ next_page_number: response.next_page_number });
          this.setState({ limit: response.limit });

          localStorage.setItem(
            "AllLiveStreamings",
            JSON.stringify(response.data.liveStreaming)
          );
          this.setState({ LiveStreamings: response.data.liveStreaming });
          this.setState({ liveStreamNothingD: true });

          localStorage.setItem(
            "AllUpcomingStreamings",
            JSON.stringify(response.data.upcomingLiveStreaming)
          );
          this.setState({
            UpcomingStreamings: response.data.upcomingLiveStreaming,
          });
          this.setState({ upcomingNothingD: true });
        } else {
          this.setState({ upcomingNothingD: true });
          this.setState({ liveStreamNothingD: true });
        }
      },
      false
    );
  };

  getDatabyPageno = (e, next_page_number) => {
    e.preventDefault();
    let { liveStreamingFilters } = this.state;
    liveStreamingFilters["page_number"] = next_page_number;
    this.setState({ liveStreamingFilters: liveStreamingFilters });
    this.getAllLiveStreamingProducts();
  };

  notifyHandler = (id, productId) => {
    if (Product.isLogin()) {
      Product.postApi(
        "APIS_NOTIFY_UPCOMING_STREAMING",
        { liveStreamingId: id, productId },
        async (response) => {
          this.getAllLiveStreamingProducts();
        },
        true
      );
    } else {
      Product.toastAlert("error", "Please Login First");
    }
  };

  removeNotifyHandler = (id, productId) => {
    if (Product.isLogin()) {
      Product.postApi(
        "APIS_REMOVE_NOTIFY_UPCOMING_STREAMING",
        { liveStreamingId: id, productId },
        async (response) => {
          this.getAllLiveStreamingProducts();
        },
        true
      );
    } else {
      Product.toastAlert("error", "Please Login First");
    }
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address).then((results) => {

      const address = results[0].formatted_address;
      var self = this;
      self.setState({ address });
      let { liveStreamingFilters } = this.state;
      liveStreamingFilters["location"] = address;
      self.setState({ liveStreamingFilters: liveStreamingFilters });
      getLatLng(results[0])
        .then((latLng) => {
          var self = this;
          let { liveStreamingFilters } = this.state;
          liveStreamingFilters["lat"] = latLng.lat;
          liveStreamingFilters["lng"] = latLng.lng;
          self.setState({ liveStreamingFilters: liveStreamingFilters });
          // this.getDataByfilters();
          this.getAllLiveStreamingProducts();
        })
        .catch((error) => console.error("Error", error));
    });
  };

  handleCatMinPrice = (event) => {
    var self = this;
    let { liveStreamingFilters } = this.state;
    liveStreamingFilters["min_price"] = event.target.value;
    self.setState({ liveStreamingFilters: liveStreamingFilters });
    this.getAllLiveStreamingProducts();
  };

  handleCatMaxPrice = (event) => {
    var self = this;
    let { liveStreamingFilters } = this.state;
    liveStreamingFilters["max_price"] = event.target.value;
    self.setState({ liveStreamingFilters: liveStreamingFilters });
    this.getAllLiveStreamingProducts();
  };

  goBackHandler = (event) => {
    // let { liveStreamingFilters } = this.state;
    // liveStreamingFilters = {
    //   page_number: 1,
    // };
    this.setState({
      // liveStreamingFilters,
      auctionType: "live",
    });
  };

  handleFilterSorting = (event) => {
    let { liveStreamingFilters } = this.state;
    liveStreamingFilters["sortType"] = event.target.value;
    this.getAllLiveStreamingProducts();
  };

  handleRadiusFilter = (event) => {
    let { liveStreamingFilters } = this.state;
    liveStreamingFilters["radius"] = event.target.value;
  };

  handleCatNegotiation = (event) => {
    this.getAllLiveStreamingProducts();
  };

  render() {
    var context = this;
    const {
      totalRecordsLiveStreaming,
      totalRecordsUpcomingLiveStreaming,
      LiveStreamings,
      UpcomingStreamings,
      liveStreamNothingD,
      upcomingNothingD,
      categories,
      auctionType,
      productSkeleton,
    } = this.state;
    return (
      <>
        <section className="product-header special_prheadr_new">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-6">
                <div className="breadcrumb-bx">
                  {auctionType === "live" && (
                    <h2>{totalRecordsLiveStreaming} Ads in Live Streaming</h2>
                  )}
                  {auctionType === "upcoming" && (
                    <h2>
                      {totalRecordsUpcomingLiveStreaming} Ads in Upcoming
                      Livestreaming
                    </h2>
                  )}
                  <div className="breadcrumb-bx-inner">
                    <Link to="/" className="btnx btn-primaryx">
                      <i className="fas fa-long-arrow-alt-left"></i> Back
                    </Link>
                    <ol className="breadcrumb mb-0 ml-3">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">Live Streaming</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-5">
                <div className="new_placetab_icon">
                  <div className="form-group-icon-bx">
                    <select
                      className="form-control-line"
                      onChange={context.handleFilterSorting}
                    >
                      <option value="">Most Recent First</option>
                      <option value="LowToHigh">Price: Low to High</option>
                      <option value="HighToLow">Price: High to Low</option>
                    </select>
                    <span className="form-group-icon">
                      <img src="/images/down-arrow-icon.png" alt="icon" />
                    </span>
                  </div>
                  <div className="showing-result"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="repeat-section product-listing-main-bx bg-light-dark forsalepage_listing">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 paddingrigt_none_Cfilter">
                <div
                  className={`product-sideBar-parent new_perenttop_forspecals ${this.state.openProductFilter ? "active" : ""
                    }`}
                >
                  <div className="transparent-layer"></div>
                  <div className="product-listing-sideBar" id="side-bar-fixed">
                    <div className="sidebarleft_newinner">
                      <div id="filter-closer">
                        <img
                          src="/images/cancel.svg"
                          alt="icon"
                          onClick={() =>
                            this.setState({
                              openProductFilter: false,
                            })
                          }
                        />
                      </div>

                      {/* location */}
                      <div className="product-listing-sideBar-colum sideBar-location">
                        <h2>Location</h2>
                        <div className="form-group">
                          <PlacesAutocomplete
                            searchOptions={{
                              componentRestrictions: {
                                country: "UK",
                              },
                            }}
                            value={this.state.address}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Search Places ...",
                                    className: "location-search-input",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                      : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        key={suggestion.placeId}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                                <div class="form-group mb-0 sideBar-choose-distance">
                                  <div class="form-group-icon-bx">
                                    <input
                                      type="number"
                                      name="radius"
                                      onChange={context.handleRadiusFilter}
                                      class="form-control-line"
                                      placeholder="Distance"
                                    />

                                    {/*<span class="form-group-icon"><img src="/images/down-arrow-icon.png" alt="icon" /></span>*/}
                                  </div>
                                  <button
                                    class="go-btn"
                                    onClick={context.handleCatNegotiation}
                                  >
                                    GO
                                  </button>
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </div>


                      <div className="product-listing-sideBar-colum sideBar-category">
                        <h2>Category</h2>
                        <ul class="toggletab-bx">
                          <li className="innerfilt_topcat_perent">
                            <span
                              onClick={() =>
                                auctionType === "upcoming"
                                  ? this.goBackHandler()
                                  : ""
                              }
                            >
                              <i className="fas fa-angle-double-left"></i>
                            </span>{" "}
                            <a href>
                              {auctionType === "upcoming"
                                ? "Upcoming Live Streaming"
                                : "Live Streaming"}
                            </a>
                            <ul className="innerfilt_topcat">
                              {categories &&
                                categories
                                  .filter((cat) => cat.categoryName !== "Services")
                                  .map((rowcat, i) => (
                                    <li>
                                      <Link
                                        to={"/live-streaming/" + rowcat.slug}
                                        id="GoFarm_machinery"
                                        className="goclass01"
                                      >
                                        {" "}
                                        {rowcat.categoryName}
                                        {/* (
                                      {context.getCategoryCount(rowsubcat._id)}) */}
                                      </Link>
                                    </li>
                                  ))}
                              {auctionType === "live" && (
                                <li>
                                  <a
                                    href
                                    className="goclass01"
                                    onClick={() => {
                                      // let { liveStreamingFilters } = this.state;
                                      // liveStreamingFilters = { page_number: 1 };
                                      this.setState({
                                        // liveStreamingFilters,
                                        auctionType: "upcoming",
                                      });
                                    }}
                                  >
                                    Upcoming Live Streaming
                                  </a>
                                </li>
                              )}
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div class="product-listing-sideBar-colum sideBar-category">
                        <h2>Price</h2>

                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <input
                                type="number"
                                name="min_price"
                                onChange={this.handleCatMinPrice}
                                class="form-control-line"
                                placeholder="Min"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <input
                                type="number"
                                name="max_price"
                                onChange={this.handleCatMaxPrice}
                                class="form-control-line"
                                placeholder="Max"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div
                  id="filter-toggler"
                  className="btnx btn-secondaryx mx-3"
                  onClick={() => this.setState({ openProductFilter: true })}
                >
                  All Filter
                </div>
                <div className="Jcompny_allprod">
                  {auctionType === "live" && (
                    <div className="Jtaballp_content atlistingside_new">
                      {LiveStreamings && LiveStreamings.length > 0 ? (
                        LiveStreamings.map((row) => (
                          <div className="row">
                            <div
                              className="col-lg-12 listside_newperent"
                              key={"liveProduct_" + row.id}
                            >
                              <div className="product-listing-product">
                                <div className="row">
                                  <div className="col-3">
                                    <div className="product-img-bx">
                                      <span className="live-tag">
                                        <i className="fas fa-circle"></i> Live
                                      </span>
                                      <Link
                                        to={"/livestreamingProduct/" + row.id}
                                      >
                                        {/* {row.adType == "Featured" && (
                                          <div className="specialtags">
                                            <span className="black_special">
                                              {row.adType}
                                            </span>
                                          </div>
                                        )} */}
                                        {/*row.adType == "Urgent" && (
                                          <div className="specialtags">
                                            <span className="red_special">
                                              {row.adType}
                                            </span>
                                          </div>
                                        )}
                                        {row.adType == "Spotlight" && (
                                          <div className="specialtags">
                                            <span className="green_special">
                                              {row.adType}
                                            </span>
                                          </div>
                                        )*/}

                                        {row.productMainImage ? (
                                          <img
                                            src={General.showImage(
                                              "products/resize-180-180",
                                              row.productMainImage
                                            )}
                                            alt="uploaded image"
                                          />
                                        ) : (
                                          <img
                                            src="/images/no-image.jpg"
                                            alt="product"
                                          />
                                        )}
                                        <div className="totalimg_sectab">
                                          {/*<Link to="#" className="totlimg_qt">2 <i className="fas fa-camera"></i></Link>*/}
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="col-9">
                                    <div className="product-outer-bx productBig-outer-bx">
                                      <div className="product-name-bx content_gobottom_new">
                                        <Moment fromNow>{row.time}</Moment>
                                        <Link
                                          to={"/livestreamingProduct/" + row.id}
                                        >
                                          <h4>{row.productName}</h4>
                                        </Link>
                                        <h5>
                                          {/* {row.categoryId.categoryName}{" "}
                                              {row.subCategoryId &&
                                                "- " +
                                                  row.subCategoryId
                                                    .subCategoryName}{" "} */}
                                          {/* {
                                                row.subCategoryId
                                                  .subCategoryName
                                              } */}
                                          {row?.location}
                                        </h5>
                                        {General.showWeekendDeals() &&
                                          row.isWeekEndDeal ? (
                                          <div class="d-flex align-items-center">
                                            <del>
                                              {General.showPriceDecimal(
                                                row.productPrice
                                              )}
                                            </del>
                                            <span className="discountt mr-2">
                                              -{row?.discountPercent}%
                                            </span>
                                            <h6 className="mt-0">
                                              <span>
                                                {General.showPriceDecimal(
                                                  row.weekendPrice
                                                )}
                                              </span>
                                            </h6>
                                          </div>
                                        ) : (
                                          <h6>
                                            <span>
                                              {General.showPriceDecimal(
                                                row.productPrice
                                              )}
                                            </span>
                                          </h6>
                                        )}

                                        {row.productDescription && (
                                          <p>{`${row.productDescription.substring(
                                            0,
                                            200
                                          )}...`}</p>
                                        )}
                                        {/* {row.isWishlist === 1 ? (
                                          <button
                                            className="add-to-wishlist"
                                            onClick={() =>
                                              context.removeWishlistHandler(
                                                row._id
                                              )
                                            }
                                          >
                                            <i className="fas fa-heart"></i>
                                          </button>
                                        ) : (
                                          <button
                                            className="add-to-wishlist"
                                            onClick={() =>
                                              context.addWishlistHandler(
                                                row._id
                                              )
                                            }
                                          >
                                            <i className="far fa-heart"></i>
                                          </button>
                                        )} */}
                                        {/* {row.condition && (
                                          <div className="usednew_button">
                                            <button
                                              type="button"
                                              className="btnnew_used"
                                            >
                                              {row.condition}
                                            </button>
                                          </div>
                                        )} */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <>
                          {liveStreamNothingD ? (
                            <div className="alert alert-danger col-lg-12">
                              <strong>Info!</strong> Nothing here.
                            </div>
                          ) : (
                            productSkeleton &&
                            productSkeleton.map((rowsskeleton) => (
                              <ContentLoader
                                viewBox="0 0 1380 300"
                                height={340}
                                width={1380}
                              >
                                <rect
                                  x="20"
                                  y="{rowsskeleton.id}"
                                  rx="10"
                                  ry="10"
                                  width="300"
                                  height="300"
                                />
                                <rect
                                  x="360"
                                  y="{rowsskeleton.id}"
                                  rx="10"
                                  ry="10"
                                  width="1000"
                                  height="300"
                                />
                              </ContentLoader>
                            ))
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {auctionType === "upcoming" && (
                    <div className="Jtaballp_content atlistingside_new">
                      {UpcomingStreamings && UpcomingStreamings.length > 0 ? (
                        UpcomingStreamings.map((row) => (
                          <div className="row">
                            <div
                              className="col-lg-12 listside_newperent"
                              key={"upcomingPro_" + row.id}
                            >
                              <div className="product-listing-product">
                                <div className="row">
                                  <div className="col-3">
                                    <div className="product-img-bx">
                                      <Link
                                        to={
                                          "/upcomingLiveStreamingProduct/" +
                                          row.productSlug +
                                          "/" +
                                          row.id
                                        }
                                      >
                                        {row.adType == "Featured" && (
                                          <div className="specialtags">
                                            <span className="black_special">
                                              {row.adType}
                                            </span>
                                          </div>
                                        )}
                                        {/*row.adType == "Urgent" && (
                                          <div className="specialtags">
                                            <span className="red_special">
                                              {row.adType}
                                            </span>
                                          </div>
                                        )}
                                        {row.adType == "Spotlight" && (
                                          <div className="specialtags">
                                            <span className="green_special">
                                              {row.adType}
                                            </span>
                                          </div>
                                        )*/}

                                        {row.productMainImage ? (
                                          <img
                                            src={General.showImage(
                                              "products/resize-180-180",
                                              row.productMainImage
                                            )}
                                            alt="uploaded image"
                                          />
                                        ) : (
                                          <img
                                            src="/images/no-image.jpg"
                                            alt="product"
                                          />
                                        )}
                                        <div className="totalimg_sectab">
                                          {/*<Link to="#" className="totlimg_qt">2 <i className="fas fa-camera"></i></Link>*/}
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="col-9">
                                    <div className="product-outer-bx productBig-outer-bx">
                                      <div className="product-name-bx content_gobottom_new">
                                        <Moment fromNow>{row.time}</Moment>
                                        <Link
                                          to={"/product/" + row.productSlug}
                                        >
                                          <h4>{row.productName}</h4>
                                        </Link>
                                        <h5>
                                          {/* {row.categoryId.categoryName}{" "}
                                              {row.subCategoryId &&
                                                "- " +
                                                  row.subCategoryId
                                                    .subCategoryName}{" "} */}
                                          {/* {
                                                row.subCategoryId
                                                  .subCategoryName
                                              } */}
                                          {row?.location}
                                        </h5>
                                        {General.showWeekendDeals() &&
                                          row.isWeekEndDeal ? (
                                          <div class="d-flex align-items-center">
                                            <del>
                                              {General.showPriceDecimal(
                                                row.productPrice
                                              )}
                                            </del>
                                            <span className="discountt mr-2">
                                              -{row?.discountPercent}%
                                            </span>
                                            <h6 className="mt-0">
                                              <span>
                                                {General.showPriceDecimal(
                                                  row.weekendPrice
                                                )}
                                              </span>
                                            </h6>
                                          </div>
                                        ) : (
                                          <h6>
                                            <span>
                                              {General.showPriceDecimal(
                                                row.productPrice
                                              )}
                                            </span>
                                          </h6>
                                        )}

                                        {row.productDescription && (
                                          <p>{`${row.productDescription.substring(
                                            0,
                                            200
                                          )}...`}</p>
                                        )}
                                        {/* {row.isWishlist === 1 ? (
                                          <button
                                            className="add-to-wishlist"
                                            onClick={() =>
                                              context.removeWishlistHandler(
                                                row._id
                                              )
                                            }
                                          >
                                            <i className="fas fa-heart"></i>
                                          </button>
                                        ) : (
                                          <button
                                            className="add-to-wishlist"
                                            onClick={() =>
                                              context.addWishlistHandler(
                                                row._id
                                              )
                                            }
                                          >
                                            <i className="far fa-heart"></i>
                                          </button>
                                        )} */}
                                        {/* {row.condition && (
                                          <div className="usednew_button">
                                            <button
                                              type="button"
                                              className="btnnew_used"
                                            >
                                              {row.condition}
                                            </button>
                                          </div>
                                        )} */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <>
                          {upcomingNothingD ? (
                            <div className="alert alert-danger col-lg-12">
                              <strong>Info!</strong> Nothing here.
                            </div>
                          ) : (
                            productSkeleton &&
                            productSkeleton.map((rowsskeleton) => (
                              <ContentLoader
                                viewBox="0 0 1380 300"
                                height={340}
                                width={1380}
                              >
                                <rect
                                  x="20"
                                  y="{rowsskeleton.id}"
                                  rx="10"
                                  ry="10"
                                  width="300"
                                  height="300"
                                />
                                <rect
                                  x="360"
                                  y="{rowsskeleton.id}"
                                  rx="10"
                                  ry="10"
                                  width="1000"
                                  height="300"
                                />
                              </ContentLoader>
                            ))
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>

                {auctionType === "live" && (
                  <div className="loading-bx">
                    {totalRecordsLiveStreaming > 0 && (
                      <>
                        <p>
                          <span>
                            You have viewed {this.state.limit} of{" "}
                            {totalRecordsLiveStreaming} Ads
                          </span>
                        </p>
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow="70"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <span className="sr-only">70% Complete</span>
                          </div>
                        </div>
                      </>
                    )}

                    {this.state.next_page_number > 0 && (
                      <button
                        onClick={(e) =>
                          this.getDatabyPageno(e, this.state.next_page_number)
                        }
                      >
                        LOAD MORE
                      </button>
                    )}
                  </div>
                )}
                {auctionType === "upcoming" && (
                  <div className="loading-bx">
                    {totalRecordsUpcomingLiveStreaming > 0 && (
                      <>
                        <p>
                          <span>
                            You have viewed {this.state.limit} of{" "}
                            {totalRecordsUpcomingLiveStreaming} Ads
                          </span>
                        </p>
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow="70"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <span className="sr-only">70% Complete</span>
                          </div>
                        </div>
                      </>
                    )}

                    {this.state.next_page_number > 0 && (
                      <button
                        onClick={(e) =>
                          this.getDatabyPageno(e, this.state.next_page_number)
                        }
                      >
                        LOAD MORE
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default LiveStreaming;
