import React, { Component } from "react";
import General from "Helpers/General";
import MyProfile from "Models/MyProfile";
import swal from 'sweetalert';
import socket from "Helpers/Socket";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import Countdown, { zeroPad } from "react-countdown";

export class StartLiveAuction extends Component {

  constructor(props) {

    super(props);

    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      userId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
      bankDetail: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["bankDetail"]
        : "",
      socket: null,
      totalBalance: 0,
      upcomingStreamingAds: []
    }

  }

  componentDidMount = () => {
    //this.getLiveStreamingProducts();
  };

  /*  getLiveStreamingProducts = () => {
     socket.emit("GetUserUpcomingLiveStreaming", {
       userId: MyProfile.getAuthUserId(),
     });
     socket.on("GetUserUpcomingLiveStreamingResponse", (data1) => {
       if (data1 && data1.length > 0) {
 
         socket.emit("getLiveStreamingMulti", { id: data1 });
         socket.on("getLiveStreamingResponseMulti", async (respo) => {
           if (respo && respo.length > 0) {
             var upcomingStreamings = [];
             var completedStreamings = [];
             let totalStartLiveAuction = 0;
             for (let i = 0; i < respo.length; i++) {
               const data = respo[i];
               var parseTime = Date.parse(data.time);
               const streamingTime = new Date(parseTime);
               const streamingEndTime = new Date(parseTime + data.duration * 60 * 1000);
               data.streamingEndTime = streamingEndTime;
               data.streamingTime = streamingTime;
               data.adLengthInSeconds = data.duration * 60;
               data.liveStreamingId = data._id;
 
               // Before 10 mins :: Start
               const beforeTenMinutes = new Date(parseTime - 10 * 60 * 1000);
 
               const liveStreamingCompleteTime = new Date(
                 parseTime + data.duration * 60 * 1000
               );
 
               if (new Date() > beforeTenMinutes) {
                 data.showStartLiveStreaming = true;
               } else {
                 data.showStartLiveStreaming = false;
               }
               // Before 10 mins :: End
 
               // Before 5 mins :: Start
               const beforeFiveMinutes = new Date(parseTime - 5 * 60 * 1000);
               if (new Date() > beforeFiveMinutes) {
                 data.showStartLiveStreaming = true;
               }
               // Before 5 mins :: End
 
               // After LiveStreaming Complete:: Start
 
               if (new Date() > liveStreamingCompleteTime) {
                 data.showStartLiveStreaming = false;
               }
 
               if (new Date() > streamingTime && new Date() < liveStreamingCompleteTime) {
                 totalStartLiveAuction += 1;
               }
               // After LiveStreaming Complete:: End
 
               let endTime = data.streamingTime;
 
               let diff  = Number(new Date(endTime).getTime())-Number(Date.now());
 
               data.timerTimeStampMiliSeconds =  Number(Date.now())+Number(diff);
 
               if (new Date() <= streamingEndTime) {
                 upcomingStreamings.push(data);
               } else {
                 completedStreamings.push(data);
               }
 
               this.setState({
                 upcomingStreamingAds: upcomingStreamings,
                 completedStreamingAds: completedStreamings,
                 totalStartLiveAuction : totalStartLiveAuction
               });
             }
           } else {
             this.setState({
               upcomingStreamingAds: [],
               completedStreamingAds: [],
               upcomingLiveStreamingTime: "",
               showLiveStreaming: false,
               showStartLiveStreaming: false,
               highestBidder: "",
               totalStartLiveAuction : 0
             });
           }
         });
       } else {
         this.setState({
           upcomingStreamingAds: [],
           completedStreamingAds: [],
           upcomingLiveStreamingTime: "",
           showLiveStreaming: false,
           showStartLiveStreaming: false,
           highestBidder: "",
         });
       }
     });
   }; */



  render() {
    const { upcomingStreamingAds } = this.props;
    return (
      <>
        <div
          className="tab-pane active"
          id="subtabpackage01"
          role="tabpanel"
        >
          <div className="Streaming_live">
            {upcomingStreamingAds &&
              upcomingStreamingAds.length > 0 ? (
              <>
                {upcomingStreamingAds.map((upcomingStrAds) => (
                  <div key={upcomingStrAds._id}>
                    <h6 className="Streaming_heading font-size-16">
                      Auction Time :  <Moment format="h:mm a">{upcomingStrAds?.streamingTime}</Moment> to <Moment format="h:mm a">{upcomingStrAds?.streamingEndTime}</Moment>
                    </h6>
                    {moment(
                      upcomingStrAds.streamingTime
                    ).diff(moment(), "seconds") > 0 ? (
                      <div className="Streaming_heading font-size-14">
                        Live Auction Show Will Start
                        in:{" "}
                        <span>
                          <Countdown
                            date={
                              upcomingStrAds.timerTimeStampMiliSeconds
                            }
                            key={
                              upcomingStrAds.streamingTime
                            }
                            daysInHours={true}
                            onComplete={() =>
                              this.props.getLiveStreamingProducts()
                            }
                          />{" "}
                          to go
                        </span>
                      </div>
                    ) : (
                      <div className="Streaming_heading">
                        Live Auction Show has
                        started
                      </div>
                    )}

                    {upcomingStrAds?.showStartLiveStreaming && (
                      <div className=" text-center pt-4">
                        <div className="text-center">
                          <Link
                            to={{
                              pathname: `/livestreamingProduct/${upcomingStrAds?.liveStreamingId}`,
                              query: {
                                productId:
                                  upcomingStrAds?.productSlug,
                              },
                            }}
                          >
                            <button className="btn-sm btn-secondary-sm mb-3">Go To Auction Show</button>
                          </Link>
                        </div>
                        <Link
                          to={{
                            pathname: `/livestreamingProduct/${upcomingStrAds?.liveStreamingId}`
                          }}
                        >
                          <img
                            src={this.state.headerLogo}
                            alt="logo.png"
                          /* width="100"
                          height="46" */
                          />
                        </Link>
                      </div>
                    )}

                    {  /* <div className="Streaming_text">
                    <div className="h_text">
                    Guide
                    </div>
                    <div className="txt">
                    <h3>
                        Lorem Ipsum is simply dummy
                        text of the printing and
                        typesetting industry.
                    </h3>
                    <div className="text_para">
                        Lorem Ipsum is simply dummy
                        text of the printing and
                        typesetting industry.
                    </div>
                    </div>

                    <div className="txt">
                    <h3>
                        Lorem Ipsum is simply dummy
                        text of the printing and
                        typesetting industry.
                    </h3>
                    <div className="text_para">
                        Lorem Ipsum is simply dummy
                        text of the printing and
                        typesetting industry.
                    </div>
                    </div>
                </div> */}
                    <hr />
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="Streaming_heading">
                  You have not schedule live auction
                </div>

                <div className=" text-center pt-4">
                  <img
                    src={this.state.headerLogo}
                    alt="logo.png"
                    /* width="100"
                    height="46" */
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.props.handleTabes("schedule_live_auction")
                    }
                    title="Schedule Live Auction"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default StartLiveAuction;
