import React, { Component } from "react";
import Rating from "@material-ui/lab/Rating";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import General from "Helpers/General";
import Category from "Models/Category";
import Product from "Models/Product";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { connect } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import Modal from "react-modal";
import {
  OpenMessageBox,
  MessageData,
  SetSelectedChannel,
  SetOutgoingCallState,
  SetQuickbloxSession,
  SetSelectedUser,
  SetContactList,
} from "../../Helpers/Actions";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import StarRatings from "react-star-ratings";
import { LinearProgress } from "@material-ui/core";
import ProductReview from "Models/ProductReview";
import Storage from "Helpers/Storage";
import Moment from "react-moment";
import moment from "moment";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Report from "Models/Report";
import { ChannelList } from "./ChannelList";
import { MessagesPanel } from "./MessagesPanel";
import socketClient from "socket.io-client";
import Countdown from "react-countdown";
import QB, { playCallAudio } from "Helpers/Quickblox";
import socket from "Helpers/Socket";
import { Fancybox } from '@fancyapps/fancybox';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
const SERVER = process.env.REACT_APP_SERVER;
let frontUrl = process.env.REACT_APP_FRONTEND_URL;

class UpcomingLiveStreamingProductDetailComponent extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    // this.logEmoji = this.logEmoji.bind(this)
    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      currentIndex: 0,
      userData: {},
      items: [1, 2, 3, 4, 5],
      productData: {},
      productDataId: "",
      liveStreamingId: "",
      is_review_post: 0,
      // products: localStorage.getItem("recentlyViewProducts")
      //   ? Object.assign(
      //       {},
      //       ...JSON.parse(Storage.getLocalData("recentlyViewProducts")).filter(
      //         (product) =>
      //           product.productSlug ===
      //           this.props.match.params.upcomingstreamingId
      //       )
      //     )
      //   : {}, 

      products: {},
      isWishlist: 0,
      email: Product.isLogin() ? Storage.getLocalData("username") : "",
      profilePicture: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["profilePicture"]
        : "",
      id: "",
      name: "",
      rating: 0,
      title: "",
      review: "",
      showPhoneNumber: false,
      showReplyAsSeller: "",
      reply: "",
      reports: [],
      showReport: false,
      showSecurityTips: false,
      productUserId: "",
      showDeliveryCharge: true,
      selectedReports: [],
      reportEmail: "",
      reportName: "",
      productSafetyTips: localStorage.getItem("ProductSafetyTips")
        ? JSON.parse(localStorage.getItem("ProductSafetyTips"))
        : "",
      generalSettingsData: localStorage.getItem("generalSettingsData")
        ? JSON.parse(localStorage.getItem("generalSettingsData"))
        : {},
      minLength: process.env.REACT_APP_MIN_CHARACTERS_LENGTH_COMMENT,
      maxLength: process.env.REACT_APP_MAX_CHARACTERS_LENGTH_COMMENT,
      showCommentHourMinute: false,
      showMapModuleSetting: false,
      defaultZoomMap: 0,
      adminCost: 0,
      loadClient: true,
      refresh: false,
      isLoading: false,
      startTime: "",
      showBidders: false,
      isModalOpen: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ isModalOpen: true });
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }
  // socket;

  startVideoCall = (email) => {
    if (email) {
      QB.users.get({ login: email }, (error, user) => {
        if (!error) {
          this.initiateCall(user.id);
        } else {
          console.log("Couldn't find user");
        }
      });
    }
  };

  initiateCall = (userId) => {
    this.props.SetOutgoingCallState(true);
    var calleesIds = [userId]; // Users' ids
    var sessionType = QB.webrtc.CallType.VIDEO; // AUDIO is also possible
    var additionalOptions = {};

    var session = QB.webrtc.createNewSession(
      calleesIds,
      sessionType,
      null,
      additionalOptions
    );
    // console.log(session);
    var mediaParams = {
      audio: true,
      video: { min: 320, ideal: 720, max: 1024 },
      options: {
        muted: true,
        mirror: true,
      },
      elemId: "localVideoElem",
    };
    this.props.SetQuickbloxSession(session);
    // session.stop({});
    session.getUserMedia(mediaParams, (error, stream) => {
      if (error) {
        this.props.SetOutgoingCallState(false);
        this.props.SetQuickbloxSession(null);
        session.stop({});
        console.log("Get user stream error => ", error);
      } else {
        //run call function here
        // console.log(stream);
        // session.attachMediaStream("localVideoElem", stream);
        if (stream) {
          const callee = JSON.parse(localStorage.getItem("QB_USER"));
          session.call({ callee: callee?.full_name }, (error1) => {
            playCallAudio();
            console.log(error1);
          });
        }
      }
    });
  };

  setClassActive = async () => {

    if (localStorage.getItem("AuthIdentity")) {
      QB.users.get(
        { login: this.state.products.userId.email },
        (error, user) => {
          if (!error) {
            try {
              QB.chat.roster.add(user.id, function () { });
            } catch (e) {
              if (e.name === "ChatNotConnectedError") {
                // not connected to chat
              }
            }
            const contacts = JSON.parse(
              localStorage.getItem("QB_CONTACT_LIST")
            );
            let allContacts = [];

            const restContacts = contacts.filter(
              (user1) => user1.user.id !== user.id
            );

            allContacts = [{ user }, ...restContacts];
            localStorage.setItem(
              "QB_CONTACT_LIST",
              JSON.stringify(allContacts)
            );
            this.props.SetContactList(allContacts);
            this.props.SetSelectedUser([{ user }]);
            this.props.OpenMessageBox(true);
            localStorage.setItem("isOpenMessageBox", JSON.stringify(true));
            localStorage.setItem("isClosedMessageBox", JSON.stringify(false));
          }
        }
      );
    }
  };

  componentDidMount = () => {
    //  if(Product.isLogin()){
    //         console.log("hello")
    //         console.log("user",this.state.id)
    //         console.log("seller",this.state.products)
    //       //   if(this.state.id == this.state.products.userId._id){
    //       //     console.log("hello")
    //       //     window.location.href = `${frontUrl}/livestreamingProduct/${livestreamingId}?productId=${productId}`;
    //       //     localStorage.setItem("CurrentUrl", "");
    //       //   }else{
    //       //     window.location.href = `${frontUrl}/upcomingLiveStreamingProduct/${productId}/${livestreamingId}`;;
    //       //     localStorage.setItem("CurrentUrl", "");
    //       //   }
    //       }else{
    //         console.log("hi")
    //         // localStorage.setItem("CurrentUrl", window.location.href);
    //        }
    // if(Product.isLogin()){
    //   localStorage.setItem("CurrentUrl", "");
    // }else{
    //   localStorage.setItem("CurrentUrl", window.location.href);
    // }
    //  if (!Product.isLogin()) {
    //    window.location = `/login`;
    //   //this.props.history.push("/");
    //}
    // if (localStorage.getItem("AuthIdentity")) {
    //   this.configureSocket();
    //   this.loadChannels();
    // }
    // var self = this;
    // Category.getApi(
    //   "APIS_ALL_CATEGORIES",
    //   async (response) => {
    //     self.setState({ Categories: response });
    //   },
    //   false
    // );

    socket.emit("post_visit", {
      productSlug: this.props.match.params.upcomingstreamingId,
    });

    Product.postApi(
      "API_GET_USER_BY_EMAIL",
      { email: localStorage.getItem("username") },
      async (response) => {
        console.log("response userdetails", response)
        if (response) {
          this.setState({
            name: response.firstName + (response.lastName ? " " + response.lastName : "")
          });
          this.setState({ id: response._id });
          this.setState({ userData: response });
        }
      },
      false
    );

    // Comments setting
    Product.getApi("APIS_GET_COMMENT_SETTING", async (response) => {
      if (response && response[0]["common_setting"]) {
        if (response[0]["common_setting"][0]["maximumCharacters"]) {
          this.setState({
            maxLength: Number(
              response[0]["common_setting"][0]["maximumCharacters"]
            ),
          });
        }
        if (response[0]["common_setting"][0]["showHourMinuteForComments"]) {
          this.setState({
            showCommentHourMinute:
              response[0]["common_setting"][0]["showHourMinuteForComments"],
          });
        }
      }
    });

    // Map Module Setting
    Product.getApi("APIS_GET_MAP_MODULE_SETTING", async (response) => {
      if (response && response[0]["common_setting"]) {
        if (response[0]["common_setting"][0]["mapsModule"] === true) {
          this.setState({
            showMapModuleSetting:
              response[0]["common_setting"][0]["mapsModule"],
          });

          this.setState({
            defaultZoomMap: Number(
              response[0]["common_setting"][0]["locationDefault"]
            ),
          });
        }
      }
    });

    this.getProductById(true);
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.match.params.upcomingstreamingId !==
      prevProps.match.params.upcomingstreamingId
    ) {
      this.getProductById();
    }
  };

  getProductById = (checkSeller = false) => {
    const productId = this.props.match.params.upcomingstreamingId;
    const livestreamingId = this.props.match.params.livestreamingId;
    console.log("productId", productId, "livestreamingId", livestreamingId)
    Product.postApi(
      "APIS_GET_LIVE_PRODUCT_BY_SLUG",
      { productSlug: productId, streamId: livestreamingId },
      async (response, status) => {
        console.log("response getproducts", response)
        if (response) {
          console.log("response", response);
          console.log("response", response.products);
          window.scrollTo(0, 0);
          this.setState({ productData: response });
          this.setState({ productUserId: response.products.userId._id });
          this.setState({ products: response.products });
          this.setState({ isWishlist: response.is_wishlist });
          this.setState({ weekendData: response.weekendDealData });
          this.setState({ productDataId: productId });
          this.setState({ is_review_post: response.is_review_post });
          this.setState({ liveStreamingId: livestreamingId });
          console.log("response.products.delivery ", response.products.delivery);
          if (response.products.delivery === 1) {
            this.setState({ showDeliveryCharge: false });
          }
          if (checkSeller) {
            if (Product.isLogin()) {
              if (this.state.id == this.state.products.userId._id) {
                window.location.href = `/livestreamingProduct/${livestreamingId}?productId=${productId}`;
                localStorage.setItem("CurrentUrl", "");
              } else {
                localStorage.setItem("CurrentUrl", "");
              }
            } else {
              localStorage.setItem("CurrentUrl", window.location.href);
            }
          }
          // sorting Images order
          if (response.products &&
            response.products.productMainImage &&
            response.products.productImages
          ) {
            response.products.productImages.sort(function (x, y) {
              return x === response.products.productMainImage
                ? -1
                : y === response.products.productMainImage
                  ? 1
                  : 0;
            });
          }


          if (this.state.generalSettingsData && this.state.generalSettingsData?.miscellaneous) {
            let adminCost = this.state.generalSettingsData.miscellaneous.map((item) => {
              //return ((this.state.products.productPrice*item.adminServiceCharge)/100);
              return (item.adminServiceCharge);
            });
            this.setState({ adminCost: Number(adminCost) });
          }

          // Recently View Product::Start

          const oldRecentlyViewProducts = (Storage.getLocalData("recentlyViewProducts")) ? JSON.parse(
            Storage.getLocalData("recentlyViewProducts")) : null;
          var recentlyViewProducts;
          if (oldRecentlyViewProducts !== null) {
            recentlyViewProducts = [
              ...oldRecentlyViewProducts,
              response.products,
            ];
          } else {
            recentlyViewProducts = [response.products];
          }

          const removeDuplicateRecords = this.removeDuplicates(
            recentlyViewProducts,
            (item) => item?._id
          );

          Storage.setLocalData(
            "recentlyViewProducts",
            JSON.stringify(removeDuplicateRecords)
          );
          // Recently View Product::End

          this.setState({ showPhoneNumber: false });

          // Get All Reports
          Product.getApi(
            "APIS_GET_ALL_REPORTS",
            async (response) => {
              this.setState({ reports: response });
            },
            false
          );

          Product.postApi(
            "API_GET_Live_STREAMING_DATA_BY_PRODUCT",
            { livestreamingId: livestreamingId },
            async (response, status) => {
              if (status === "success") {
                this.setState({ startTime: response.time });
              }
            },
            true
          );

        } else {
          //window.location.href = '/';
        }
      },
      true
    );
  };

  removeDuplicates = (data, key) => {
    return [...new Map(data.map((item) => [key(item), item])).values()];
  };

  addWishlistHandler = (id) => {

    if (Product.isLogin()) {
      this.setState({ isLoading: true });
      Product.postApi(
        "APIS_ADD_TO_WISHLIST",
        { productId: id },
        async (response) => {
          this.getProductById();
          this.setState({ isLoading: false });
        },
        true
      );
    } else {
      window.location = `/login`;
    }
  };

  removeWishlistHandler = (id) => {
    if (Product.isLogin()) {
      this.setState({ isLoading: true });
      Product.postApi(
        "APIS_REMOVE_FROM_WISHLIST",
        { productId: id },
        async (response) => {
          this.getProductById();
          this.setState({ isLoading: false });
        },
        true
      );
    } else {
      window.location = `/login`;
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const { minLength, maxLength } = this.state;
    const { name, email, review, title, rating, productData } = this.state;
    const data = {
      // name,
      // email,
      review,
      // title,
      rating,
      productId: productData.products._id,
    };

    if (
      (await ProductReview.checkValidation(
        await ProductReview.validationRules(minLength, maxLength),
        data
      )) === true
    ) {
      Product.postApi(
        "APIS_ADD_PRODUCT_REVIEW",
        data,
        async (response) => {
          this.setState({
            review: "",
            title: "",
            rating: 0,
            name: Product.isLogin() ? name : "",
            email: Product.isLogin() ? Storage.getLocalData("username") : "",
          });
          this.getProductById();
        },
        true
      );
    }
  };

  // Reveal Phone Number
  revealPhoneNumberHandler = () => {
    if (Product.isLogin()) {
      this.setState({ showPhoneNumber: !this.state.showPhoneNumber });
    } else {
      Product.toastAlert("warn", "Please Login");
    }
  };

  // Toggle Reply as Seller
  toggleReplyAsSeller = (e, id) => {
    e.preventDefault();
    const { showReplyAsSeller } = this.state;

    showReplyAsSeller === id
      ? this.setState({ showReplyAsSeller: "" })
      : this.setState({ showReplyAsSeller: id, reply: "" });
  };

  // Submit Reply
  handleReplySubmit = async (e, id) => {
    e.preventDefault();
    const { reply } = this.state;
    if (
      (await ProductReview.checkValidation(
        await ProductReview.validationRules(),
        { reply }
      )) === true
    ) {
      this.setState({ isLoading: true });
      Product.postApi(
        "APIS_POST_REPLY_REVIEW",
        { id, reply },
        async (response) => {
          this.setState({ showReplyAsSeller: "", reply: "" });
          this.getProductById();
          this.setState({ isLoading: false });
        },
        true
      );
    }
  };

  // Report handler
  reportCheckHandler = (e) => {
    const { selectedReports } = this.state;
    if (e.target.checked) {
      var newValues = [...selectedReports, e.target.value];
      this.setState({ selectedReports: newValues });
    } else {
      this.setState({
        selectedReports: selectedReports.filter((id) => id !== e.target.value),
      });
    }
  };

  reportSubmitHandle = async (e) => {
    e.preventDefault();
    const { id, reportName, reportEmail, selectedReports, productData } =
      this.state;

    if (Report.isLogin()) {
      this.setState({ isLoading: true });
      Report.postApi(
        "APIS_POST_REPORT",
        {
          id,
          report_id: selectedReports,
          product_id: productData.products._id,
        },
        async (response) => {
          this.setState({ selectedReports: [] });
          this.setState({ isLoading: false });
          // this.getProductById();
        },
        true
      );
    } else {
      if (
        (await Report.checkValidation(await Report.validationRules(), {
          reportName,
          reportEmail,
        })) === true
      ) {
        var data = {
          name: reportName,
          email: reportEmail,
          report_id: selectedReports,
          product_id: productData.products._id,
        };
        this.setState({ isLoading: true });
        Report.postApi(
          "APIS_POST_REPORT",
          data,
          async (response) => {
            this.setState({
              reportName: "",
              reportEmail: "",
              selectedReports: [],
            });
            this.setState({ isLoading: false });
            // this.getProductById();
          },
          false
        );
      }
    }
  };

  getPostingFor = (givenTime) => {
    if (givenTime) {
      var days = moment().diff(givenTime, "days");

      if (days > 30) {
        var months = moment().diff(givenTime, "months");
        if (months > 12) {
          var years = moment().diff(givenTime, "years");
          return years + "+" + " years";
        } else {
          return months + "+" + " months";
        }
      } else {
        return days + "+" + " days";
      }
    }
  };

  getDiffInDayHour = (givenTime) => {
    if (givenTime) {
      let hours = Math.abs(moment().diff(givenTime, "hours"));
      if (hours > 23) {
        let days = hours / 24;
        let day = parseInt(days.toString().split(".")[0]);
        let hour = hours - (day * 24);
        return day + "d " + hour + "h ";
      } else {
        if (hours == 0) {
          let min = Math.abs(moment().diff(givenTime, "minutes"));
          return min + " min";
        } else {
          return hours + "h";
        }
      }
    }
  };
  goback = () => {
    window.location.href = '/';
  }

  render() {
    const {
      products,
      reviews,
      user,
      avgRating,
      similarProducts,
      productCustomFields,
      weekendDealData,
      preBidDetail
    } = this.state.productData;
    const { isLoading, productDataId, liveStreamingId, startTime, isModalOpen, userData } = this.state;
    console.log("---similarProducts---", similarProducts);
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    return (
      <React.Fragment>
        {/* <video id="localVideoElem" autoPlay width="200" height="200"></video> */}
        {isLoading && (
          <div className="loader-wrapper" id="loader_img">
            <div className="loader">
              <img src={this.state.headerLogo} alt="" />
              <div className="material-spinner"></div>
            </div>
          </div>
        )}
        {products ? (
          <>
            <section className="video-chat-header">
              <div className="container">
                <Link className="video-chat-btn" to="#!">
                  <span>
                    <img src="/images/video-chat-icon.png" alt="icon" />
                  </span>{" "}
                  Open Video Chat
                </Link>
                <a href={void (0)} className="number-reveal-btn ml-2">
                  <img src="/images/phone-icon.png" alt="icon" />{" "}
                  <span>Reveal Phone Number</span>
                </a>
              </div>
            </section>
            <section className="product-header bg-light-dark">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-12">
                    <div className="breadcrumb-bx">
                      <div className="breadcrumb-bx-inner">
                        <button
                          onClick={() => this.goback()}
                          className="btnx btn-primaryx"
                        >
                          <i className="fas fa-long-arrow-alt-left"></i> Back
                        </button>
                        <ol className="breadcrumb mb-0 ml-3">
                          <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to="/category/live-auction">Auction Show</Link>
                          </li>
                          {products.categoryId && (
                            <li className="breadcrumb-item">
                              <Link
                                to={`/live-auction/${products.categoryId.slug}`}
                              >
                                {products.categoryId.categoryName}
                              </Link>
                            </li>
                          )}
                          {products.subCategoryId && (
                            <li className="breadcrumb-item">
                              <Link
                                to={`/live-auctions/sub-category/${products.subCategoryId.slug}`}
                              >
                                {products.subCategoryId.subCategoryName}
                              </Link>
                            </li>
                          )}
                          {products.thirdCategoryId && (
                            <li className="breadcrumb-item">
                              {/* <Link
                                to={`/live-auctions/sub-category/${products.thirdCategoryId.slug}`}
                              > */}
                              {products.thirdCategoryId.subCategoryName}
                              {/* </Link> */}
                            </li>
                          )}
                          {/* <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            {products.productName}
                          </li> */}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="repeat-section product-detail-main-bx bg-light-dark onmobilp_topnon ondesktop_topnon mobiledisplay_oneline">
              <div className="container">
                <div className="row">
                  <div className="col-xl-6 col-lg-7">
                    <div className="mobile_order ">
                      <div className="product-detail-heading prodctdetil_newtitles productDetails_box">
                        <h2 className="font-size-16 font-weight-600 product_title">{products.productName}</h2>
                        <div className="newrigh_ppris text-right">

                          <div className="d-flex align-items-center justify-content-end mobile-left">
                            <h4 className="font-size-14 font-weight-400 upcoming_product_price_track">
                              Starting Price : <b className="font-size-16">{General.showPriceDecimal(products.starting_price)}</b>
                            </h4>
                          </div>
                          <div className="d-flex align-items-center justify-content-end mobile-left">
                            <h4 className="font-size-14 font-weight-400 upcoming_product_price_track">
                              Selling Estimate : <b className="font-size-16">{General.showPriceDecimal(products.selling_estimate)}</b>
                            </h4>
                          </div>
                          <p>
                            {products && products?.location ? products.location : "N/A"}
                          </p>
                        </div>
                        <Modal
                          isOpen={isModalOpen}
                          onRequestClose={this.handleCloseModal}
                          contentLabel="Share"
                          className="modal-content modal_box"
                          overlayClassName="modal-overlay"
                        >
                          <div className="share-bx share_modal">
                            <p>Share:</p>
                            <ul>
                              <li>
                                <FacebookShareButton
                                  url={
                                    process.env.REACT_APP_FRONTEND_URL +
                                    `/product/${products ? products._id : ""}`
                                  }
                                >
                                  <FacebookIcon
                                    iconFillColor="white"
                                    size={48}
                                    round={true}
                                  />
                                </FacebookShareButton>
                              </li>
                              <li>
                                <TwitterShareButton
                                  url={
                                    process.env.REACT_APP_FRONTEND_URL +
                                    `/product/${products ? products._id : ""}`
                                  }
                                >
                                  <TwitterIcon
                                    iconFillColor="white"
                                    size={48}
                                    round={true}
                                  />
                                </TwitterShareButton>
                              </li>
                              <li>
                                <EmailShareButton
                                  url={
                                    process.env.REACT_APP_FRONTEND_URL +
                                    `/product/${products ? products._id : ""}`
                                  }
                                >
                                  <EmailIcon
                                    iconFillColor="white"
                                    size={48}
                                    round={true}
                                  />
                                </EmailShareButton>
                              </li>
                            </ul>
                          </div>
                        </Modal>
                      </div>
                      <div className="product-tab-bx d-none d-md-block">
                        <ul className="nav nav-tabs my-custom-tab" role="tablist">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              data-toggle="tab"
                              href="#product-image"
                              role="tab"
                            >
                              Images
                            </a>
                          </li>
                          {products.showMap &&
                            products.showMap === true &&
                            this.state.showMapModuleSetting === true && (
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  data-toggle="tab"
                                  href="#product-map"
                                  role="tab"
                                >
                                  Map
                                </a>
                              </li>
                            )}
                        </ul>
                      </div>
                      <div className="tab-content">
                        <div
                          className="tab-pane active"
                          id="product-image"
                          role="tabpanel"
                        >
                          <div className="product-detailslider">
                            <Carousel
                              autoPlay
                              showIndicators={false}
                              thumbWidth={110}
                              infiniteLoop={true}
                              showStatus={false}
                              useKeyboardArrows={true}
                              showThumbs={false}
                              labels={{
                                leftArrow: "prev",
                                rightArrow: "next",
                              }}
                              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                hasPrev && (
                                  <button
                                    type="button"
                                    onClick={onClickHandler}
                                    title={label}
                                    className={"slider-btnProduct"}
                                    style={{
                                      position: "absolute",
                                      zIndex: 2,
                                      top: "calc(50% - 15px)",

                                      cursor: "pointer",
                                      left: 15,
                                    }}
                                  >
                                    <div className="lSNext"></div>
                                  </button>
                                )
                              }
                              renderArrowNext={(onClickHandler, hasNext, label) =>
                                hasNext && (
                                  <button
                                    type="button"
                                    onClick={onClickHandler}
                                    title={label}
                                    className={"slider-btnProduct"}
                                    style={{
                                      position: "absolute",
                                      zIndex: 2,
                                      top: "calc(50% - 15px)",

                                      cursor: "pointer",
                                      right: 15,
                                    }}
                                  >
                                    <div className="lSprev"></div>
                                  </button>
                                )
                              }
                            >
                              {products.productImages ? (
                                products.productImages.map((image, index) => (
                                  <a
                                    key={index}
                                    href={General.showImage("products/resize-566-400", image)}
                                    data-fancybox="gallery"
                                    data-caption={`Image ${index + 1}`}
                                  >
                                    {moment(products?.liveStreamingData?.time).diff(
                                      moment(),
                                      "seconds"
                                    ) <= 0 ? (<span className="live-tag">
                                      <i className="fas fa-circle"></i> Live
                                    </span>) : ""}
                                    <img
                                      key={image}
                                      src={General.showImage(
                                        "products/resize-566-400",
                                        image
                                      )}
                                      alt={image}
                                    />
                                  </a>
                                ))
                              ) : (
                                <Zoom>
                                  <img src="/images/no-image.jpg" alt="" />
                                </Zoom>
                              )}
                            </Carousel>
                          </div>
                        </div>
                        {products.showMap && products.showMap === true && (
                          <div
                            className="tab-pane"
                            id="product-map"
                            role="tabpanel"
                          >
                            <div className="product-map-bx">
                              <iframe
                                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC5IZF8PneazdRSRmFWtTAQZRFo-CB79Fg&q=${products
                                  ? encodeURIComponent(products.location)
                                  : ""
                                  }&center=${products ? products.latitude : ""},${products ? products.longitude : ""
                                  }&zoom=${this.state.defaultZoomMap}`}
                                width="600"
                                height="450"
                                frameBorder="0"
                                title="maps"
                              ></iframe>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="product-detail-owner-bx mobile_view">
                      <div className="safety-tips-bx">
                        <h2>Delivery</h2>
                        <div>
                          <ul>
                            {this.state.showDeliveryCharge === true ? (
                              <>
                                {
                                  products.delivery == 2 && products.freeDeliveryOptions == 1 ? (
                                    <li className="delivery_itesm" >
                                      Shipping :
                                      <span className="delivery_status_track pl-1">
                                        Free
                                      </span>{" "}
                                    </li>
                                  ) : (
                                    <li className="delivery_itesm" >
                                      Shipping :
                                      <span className="delivery_status_track pl-1">
                                        {General.showPriceDecimal(
                                          products?.deliveryCost
                                        )}
                                      </span>{" "}
                                    </li>
                                  )
                                }
                              </>
                            ) : (
                              <li className="delivery_itesm">Local collection from seller</li>
                            )}
                            <li>Order protection : <span className="delivery_status_track pl-1">
                              {General.showPercentDecimal(
                                products?.buyerProtectionCharge
                              )} + {General.showPriceDecimal(this.state.adminCost)}</span></li>
                            {/* <li>Service charge : ({General.showPercentDecimal(this.state.adminCost)})</li> */}
                            <li>Secure payment</li>
                          </ul>
                        </div>
                      </div>
                      <div className="contact-bx">
                        <div className="livestriming_lsec">
                          <div className="joinleft_lsec">
                            {/* <p>Join</p> */}
                          </div>
                          <div className="joinright_lsec">
                            <span className="hammer_icon">
                              <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_290_552)">
                                  <path d="M25.4236 18.6237L18.1709 12.7615C17.479 12.2023 16.5668 12.1174 15.8028 12.4463L13.5574 10.6314C13.9871 9.9723 14.3153 9.30119 14.538 8.65199C14.3923 8.61972 14.2553 8.56052 14.1377 8.46547L10.3773 5.42604C10.2607 5.33179 10.1754 5.20925 10.115 5.07153C9.26457 5.5825 8.41291 6.31788 7.66402 7.24442C6.90035 8.18923 6.35145 9.19696 6.03293 10.1525C6.16996 10.1861 6.2996 10.2419 6.41159 10.332L10.172 13.3714C10.283 13.4612 10.369 13.573 10.4321 13.7011C11.0365 13.3437 11.6411 12.87 12.2115 12.296L14.4569 14.1109C14.2955 14.927 14.5698 15.8011 15.2617 16.3603L22.5145 22.2233C23.5084 23.0267 24.9653 22.8723 25.7687 21.8783C26.572 20.8844 26.4175 19.4271 25.4236 18.6237Z" fill="white" />
                                  <path d="M14.3844 8.07101C14.452 8.13037 14.535 8.16559 14.6259 8.18464C14.9377 8.24938 15.3523 8.08576 15.6521 7.7444C15.8864 7.47705 16.0154 7.1454 15.9967 6.85659C15.9888 6.73563 15.9506 6.56632 15.8125 6.44507L14.689 5.45862L13.3026 4.24126L12.1792 3.25481C12.0415 3.13398 11.8404 3.09437 11.6132 3.14424C11.36 3.19948 11.111 3.35513 10.9119 3.58184C10.6776 3.84919 10.5486 4.18084 10.5673 4.4692C10.5709 4.52487 10.5826 4.59103 10.6059 4.65787C10.6331 4.73643 10.6771 4.81579 10.752 4.88114L14.3844 8.07101Z" fill="white" />
                                  <path d="M9.78935 14.0893C9.78245 14.0271 9.7658 13.9519 9.73209 13.8778C9.70083 13.8094 9.65534 13.7416 9.58611 13.6862L5.81142 10.6645C5.74833 10.614 5.67237 10.5854 5.59165 10.5684C5.27416 10.5025 4.85234 10.6837 4.56039 11.049C4.33847 11.3267 4.22467 11.6639 4.25649 11.9511C4.26984 12.0715 4.31577 12.2389 4.45923 12.3538L5.74544 13.3834L6.94776 14.3463L8.23397 15.3759C8.54582 15.6251 9.11889 15.4494 9.48546 14.9915C9.70737 14.7137 9.82117 14.3765 9.78935 14.0893Z" fill="white" />
                                  <path d="M3 19.3383C3.56081 19.283 4.16222 19.2673 4.79033 19.2673C5.0581 19.2673 5.33227 19.2692 5.61154 19.2715C6.19033 19.2761 6.80234 19.2761 7.38118 19.2715C7.66045 19.2692 7.93462 19.2673 8.20239 19.2673C8.8305 19.2673 9.43191 19.283 9.99272 19.3383V18.001H3V19.3383Z" fill="white" />
                                  <path d="M8.17666 20.001C7.91031 20.001 7.6375 20.0028 7.35913 20.0051C6.77757 20.0093 6.16369 20.0093 5.58258 20.0051C5.30421 20.0028 5.0314 20.001 4.76505 20.001C4.11296 20.001 3.51478 20.0139 2.97472 20.065C1.19357 20.2333 0.0760263 20.8342 0 22.902H12.9413C12.8653 20.8342 11.7477 20.2338 9.96659 20.0651C9.42692 20.0139 8.82824 20.001 8.17666 20.001Z" fill="white" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_290_552">
                                    <rect width="27" height="27" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                            <Link
                              className="font-size-16"
                              to={Product.isLogin() ?
                                "/auctionRegisterToBid/" +
                                productDataId +
                                "/" +
                                liveStreamingId : "/login"
                              }
                            >
                              Register To Bid
                            </Link>
                          </div>
                        </div>
                        {(preBidDetail && preBidDetail.length > 0) &&
                          <>
                            <p className="d-block number-reveal-btn Bids_btn" onClick={() => this.setState({ showBidders: true })}>
                              {`${preBidDetail.length} Bids`}
                            </p>
                          </>
                        }

                        {startTime != "" &&
                          <span className="font-weight-bold">
                            {moment(startTime).diff(
                              moment(),
                              "seconds"
                            ) > 1800 ? (<> {this.getDiffInDayHour(startTime)} | <Moment format="dddd, hh:mm a">{startTime}</Moment></>) :
                              moment(startTime).diff(
                                moment(),
                                "seconds"
                              ) > 0 ? (
                                <Countdown
                                  date={startTime}
                                  zeroPadTime={2}
                                  renderer={({ days, hours, minutes, seconds }) => (
                                    <time>
                                      {minutes +
                                        ":" +
                                        seconds}{" "}
                                      to go
                                    </time>
                                  )}
                                />) : <time>Live</time>}
                          </span>
                        }
                      </div>
                    </div>
                    <div className="product-all-detail-tab mobile_view mt-3">
                      <ul className="nav nav-tabs my-custom-tab" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#product-detail"
                            role="tab"
                          >
                            Detail
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#customers-reviews"
                            role="tab"
                          >
                            Customers Reviews

                          </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="Photo-tab2" data-toggle="tab" href="#Photo2" role="tab" aria-controls="Photo2" aria-selected="false">
                            Photo
                          </a>
                        </li>


                      </ul>
                      <div className="tab-content">
                        <div
                          className="tab-pane active"
                          id="product-detail"
                          role="tabpanel"
                        >
                          <div className="product-detail-tab">
                            <div className="row">
                              <div className="col-lg-3 col-6">
                                <p className="product-specs">Seller Type</p>
                              </div>
                              <div className="col-lg-3 col-6">
                                <p className="product-specs bold-text">
                                  {products.userId && products.userId.sellerType == "1" ? (
                                    "Company"
                                  ) : products.userId && products.userId.sellerType == "2" ? (
                                    "Soletrader"
                                  ) : products.userId && products.userId.sellerType == "3" ? (
                                    "Private"
                                  ) : (
                                    <>{products.userId?.sellerType}</>
                                  )}
                                </p>
                              </div>
                              <div className="col-lg-3 col-6">
                                <p className="product-specs">Posted</p>
                              </div>
                              <div className="col-lg-3 col-6">
                                <p className="product-specs">
                                  <Moment fromNow>{products.time}</Moment>
                                </p>
                              </div>
                              {productCustomFields.length > 0 && (
                                <div className="col-lg-12">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <h2 className="product-sub-heading">
                                        Key Information
                                      </h2>
                                    </div>
                                    {productCustomFields &&
                                      productCustomFields.map((field, index) => (
                                        <>
                                          <div className="col-md-3 col-6">
                                            <p className="product-specs">
                                              {field.field_name}
                                            </p>
                                          </div>
                                          <div className="col-md-3 col-6">
                                            <p className="product-specs">
                                              <b>{field.field_value_id &&
                                                field.field_value_id.name}</b>
                                            </p>
                                          </div>
                                        </>
                                      ))}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="line-divider"></div>
                            <div className="product-discription">
                              <h2 className="same-bx-heading font-size-16">Description</h2>
                              <p className="description_track">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: products.productDescription.replace(/\n/g, "<br />"),
                                  }}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="customers-reviews"
                          role="tabpanel"
                        >
                          <div className="row align-items-end">
                            <div className="col-xl-6">
                              <div className="customer-review-bx">
                                <h2 className="p-0 mb-2">
                                  {reviews && reviews.length} Reviews from Customers
                                </h2>
                                <div className="rating_row_main_box">
                                  <div class="star star-filter"><span>(All)</span></div>
                                  <div className="rating-row">
                                    <div className="star_rating_track">
                                      <div className="star-txt">5 Stars</div>
                                      <div className="rating-view col">

                                        <LinearProgress
                                          variant="determinate"
                                          value={General.showRatingPercentage(
                                            reviews,
                                            5
                                          )}
                                        />
                                      </div>
                                      <div className="rating-text col">
                                        <h4>
                                          {General.showRatingPercentage(reviews, 5)}%
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="star col">
                                      <span className="margin-left-0">
                                        ({General.showRatingCount(reviews, 5)})
                                      </span>
                                      <StarRatings
                                        rating={5}
                                        starRatedColor="#249ee4"
                                        starDimension="17px"
                                        starSpacing="1px"
                                        numberOfStars={5}
                                      />
                                    </div>
                                  </div>
                                  <div className="rating-row">

                                    <div className="star_rating_track">
                                      <div className="star-txt">4 Stars</div>
                                      <div className="rating-view col">

                                        <LinearProgress
                                          variant="determinate"
                                          value={General.showRatingPercentage(
                                            reviews,
                                            4
                                          )}
                                        />
                                      </div>
                                      <div className="rating-text col">
                                        <h4>
                                          {General.showRatingPercentage(reviews, 4)}%
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="star col">
                                      <span>
                                        ({General.showRatingCount(reviews, 4)})
                                      </span>
                                      <StarRatings
                                        rating={4}
                                        starRatedColor="#249ee4"
                                        starDimension="17px"
                                        starSpacing="1px"
                                        numberOfStars={5}
                                      />
                                    </div>
                                  </div>
                                  <div className="rating-row">

                                    <div className="star_rating_track">
                                      <div className="star-txt">3 Stars</div>
                                      <div className="rating-view col">

                                        <LinearProgress
                                          variant="determinate"
                                          value={General.showRatingPercentage(
                                            reviews,
                                            3
                                          )}
                                        />
                                      </div>
                                      <div className="rating-text col">
                                        <h4>
                                          {General.showRatingPercentage(reviews, 3)}%
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="star col">
                                      <span>
                                        ({General.showRatingCount(reviews, 3)})
                                      </span>
                                      <StarRatings
                                        rating={3}
                                        starRatedColor="#249ee4"
                                        starDimension="17px"
                                        starSpacing="1px"
                                        numberOfStars={5}
                                      />
                                    </div>
                                  </div>
                                  <div className="rating-row">

                                    <div className="star_rating_track">
                                      <div className="star-txt">2 Stars</div>
                                      <div className="rating-view col">

                                        <LinearProgress
                                          variant="determinate"
                                          value={General.showRatingPercentage(
                                            reviews,
                                            2
                                          )}
                                        />
                                      </div>
                                      <div className="rating-text col">
                                        <h4>
                                          {General.showRatingPercentage(reviews, 2)}%
                                        </h4>
                                      </div>
                                    </div>

                                    <div className="star col">
                                      <span>
                                        ({General.showRatingCount(reviews, 2)})
                                      </span>
                                      <StarRatings
                                        rating={2}
                                        starRatedColor="#249ee4"
                                        starDimension="17px"
                                        starSpacing="1px"
                                        numberOfStars={5}
                                      />
                                    </div>
                                  </div>
                                  <div className="rating-row">

                                    <div className="star_rating_track">
                                      <div className="star-txt">1 Stars</div>
                                      <div className="rating-view col">

                                        <LinearProgress
                                          variant="determinate"
                                          value={General.showRatingPercentage(
                                            reviews,
                                            1
                                          )}
                                        />
                                      </div>
                                      <div className="rating-text col">
                                        <h4>
                                          {General.showRatingPercentage(reviews, 1)}%
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="star col">
                                      <span>
                                        ({General.showRatingCount(reviews, 1)})
                                      </span>
                                      <StarRatings
                                        rating={1}
                                        starRatedColor="#249ee4"
                                        starDimension="17px"
                                        starSpacing="1px"
                                        numberOfStars={5}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="customer-review-profile_block">
                            <div className="row pb-3">
                              <div className="col-md-3">
                                <div className="view-more-review">
                                  View
                                  <b> {reviews.length} More </b> reviews
                                </div>
                              </div>
                              <div className="col-md-3">Share your reviews</div>
                              <div className="col-md-3">
                                <div className="review-share">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fal"
                                    data-icon="share"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    className="svg-inline--fa fa-share fa-w-18 fa-2x"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M564.907 196.35L388.91 12.366C364.216-13.45 320 3.746 320 40.016v88.154C154.548 130.155 0 160.103 0 331.19c0 94.98 55.84 150.231 89.13 174.571 24.233 17.722 58.021-4.992 49.68-34.51C100.937 336.887 165.575 321.972 320 320.16V408c0 36.239 44.19 53.494 68.91 27.65l175.998-184c14.79-15.47 14.79-39.83-.001-55.3zm-23.127 33.18l-176 184c-4.933 5.16-13.78 1.73-13.78-5.53V288c-171.396 0-295.313 9.707-243.98 191.7C72 453.36 32 405.59 32 331.19 32 171.18 194.886 160 352 160V40c0-7.262 8.851-10.69 13.78-5.53l176 184a7.978 7.978 0 0 1 0 11.06z"
                                      className=""
                                    ></path>
                                  </svg>
                                  Share
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="rated-customer-bx-inner">
                                  <div className="rated-star text-left">
                                    <span>Rated</span>

                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {reviews &&
                              reviews.map((review) => (
                                <div key={review._id} className="review_block">
                                  <div className="row align-items-center">
                                    <div className="col-lg-9">
                                      <div className="detail-owner-name-bx clearfix review_author align-items-start">
                                        <div className="detail-owner-img">
                                          {review.userId &&
                                            review.userId.profilePicture ? (
                                            <img
                                              src={General.showImageNew(
                                                "profile",
                                                review.userId.profilePicture
                                              )}
                                              alt={review.userId.profilePicture}
                                            />
                                          ) : (
                                            <img
                                              src="/images/no-image.jpg"
                                              alt="image1"
                                            />
                                          )}
                                        </div>
                                        <div className="detail-owner-name">
                                          <h3>
                                            {review?.userId?.firstName} {""}{" "}
                                            {review?.userId?.lastName}
                                          </h3>
                                          <div className="rated-customer-bx-inner">
                                            <div className="rated-star">
                                              <span>Rated</span>{" "}
                                              <StarRatings
                                                rating={review.rating}
                                                starRatedColor="#249ee4"
                                                starDimension="18px"
                                                starSpacing="0"
                                                numberOfStars={5}
                                              />
                                            </div>
                                            <h2 className="font-weight-400">{review.review}</h2>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {this.state.id ===
                                      this.state.productUserId && (
                                        <div className="col-lg-3">
                                          <div className="rated-customer-bx d-block text-right">
                                            <Link
                                              to="#!"
                                              onClick={(e) =>
                                                this.toggleReplyAsSeller(e, review._id)
                                              }
                                              className="number-reveal-btn"
                                            >
                                              <img
                                                src="/images/reply-icon.png"
                                                alt=""
                                              />
                                              <span>Reply As Seller</span>
                                            </Link>
                                          </div>
                                        </div>
                                      )}
                                    {review.replies &&
                                      review.replies.map((reviewReply, index) => (
                                        <div className="review_reply" key={index}>
                                          <div className="row align-items-center w-100 no-gutters">
                                            <div className="col-auto">
                                              <div className="detail-owner-name-bx clearfix review_author py-0">
                                                <div className="detail-owner-img reply_img_b">
                                                  {reviewReply.userId &&
                                                    reviewReply.userId
                                                      .profilePicture ? (
                                                    <img
                                                      src={General.showImageNew(
                                                        "profile",
                                                        reviewReply.userId
                                                          .profilePicture
                                                      )}
                                                      alt={
                                                        reviewReply.userId
                                                          .profilePicture
                                                      }
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/images/no-image.jpg"
                                                      alt="image1"
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col ">
                                              <div className="rated-customer-bx-inner review_author">
                                                <h3>
                                                  {reviewReply?.userId?.firstName}{" "}
                                                  {""} {reviewReply?.userId?.lastName}
                                                </h3>

                                                <h2>{reviewReply.reply}</h2>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    {this.state.showReplyAsSeller ===
                                      review._id && (
                                        <div className="review_reply">
                                          <div className="row align-items-start w-100 no-gutters">
                                            <div className="col-auto">
                                              <div className="detail-owner-name-bx clearfix">
                                                <div className="detail-owner-img reply_img_b">
                                                  {this.state.profilePicture ? (
                                                    <img
                                                      src={General.showImageNew(
                                                        "profile",
                                                        this.state.profilePicture
                                                      )}
                                                      alt={this.state.profilePicture}
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/images/no-image.jpg"
                                                      alt="image1"
                                                    />
                                                  )}
                                                  <span className="live-dot"></span>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col">
                                              <div className="form-group mb-0">
                                                <textarea
                                                  className="form-control-line h-30px"
                                                  rows="2"
                                                  name="reply"
                                                  value={this.state.reply}
                                                  onChange={(e) =>
                                                    this.setState({
                                                      reply: e.target.value,
                                                    })
                                                  }
                                                ></textarea>
                                              </div>
                                              <div className="form-group mt-3">
                                                <button
                                                  className="btnx btn-secondaryx float-right"
                                                  onClick={(e) =>
                                                    this.handleReplySubmit(
                                                      e,
                                                      review._id
                                                    )
                                                  }
                                                >
                                                  Reply
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              ))}
                            {Product.isLogin() &&
                              this.state.is_review_post === 1 && (
                                <div className="review_mess">
                                  <form onSubmit={this.handleSubmit}>
                                    <div className="review_reply pr-0 ">
                                      <div className="row w-100">
                                        <div className="col-auto">
                                          <div className="detail-owner-name-bx clearfix">
                                            <div className="detail-owner-img">
                                              {this.state.profilePicture ? (
                                                <img
                                                  src={General.showImageNew(
                                                    "profile",
                                                    this.state.profilePicture
                                                  )}
                                                  alt={this.state.profilePicture}
                                                />
                                              ) : (
                                                <img
                                                  src="/images/no-image.jpg"
                                                  alt="image1"
                                                />
                                              )}

                                              <span className="live-dot"></span>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col pl-3">
                                          <div className="detail-owner-name pl-0">
                                            <div className="rated-customer-bx-inner mess-sender-name">
                                              <h2>{this.state.name}</h2>
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <textarea
                                              name="review"
                                              value={this.state.review}
                                              onChange={this.handleChange}
                                              className="form-control-line h-20px"
                                              rows="2"
                                              placeholder="share your review"
                                              maxLength={this.state.maxLength}
                                            ></textarea>
                                          </div>
                                          <div className="d-flex">
                                            {this.state.review.length}/
                                            {this.state.maxLength}
                                            <div className="flex-g">
                                              <div className="rated-star mr-4">
                                                <Rating
                                                  name="rating"
                                                  value={this.state.rating}
                                                  onChange={(event, newValue) => {
                                                    this.setState({
                                                      rating: event.target.value,
                                                    });
                                                  }}
                                                />
                                              </div>
                                              <div className="form-group">
                                                <button
                                                  type="submit"
                                                  className="btnx btn-secondaryx font-size-14"
                                                >
                                                  Submit
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              )}
                          </div>
                        </div>
                        <div class="tab-pane fade" id="Photo2" role="tabpanel" aria-labelledby="Photo-tab2">
                          <div className="photo_section">
                            {products.productImages ? (
                              products.productImages.map((image, index) => (
                                <div className="photo_img">
                                  <a
                                    key={index}
                                    href={General.showImage("products/resize-566-400", image)}
                                    data-fancybox={`mobile-product-${image}`}
                                    data-caption={`Image ${index + 1}`}
                                  >
                                    <img
                                      key={image}
                                      src={General.showImage(
                                        "products/resize-566-400",
                                        image
                                      )}
                                      alt={image}
                                    />
                                  </a>
                                </div>
                              ))
                            ) : (
                              <div class="alert alert-info">
                                <strong>Info!</strong> Nothing here.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4  col-lg-5 offset-xl-2 mt-3 padding-bottom-mobile">
                    <div className="product-detail-owner-bx ">
                      <div className="detail-owner-header">
                        <div>
                          <h2 className="user__name font-size-16">
                            {products.userId && products?.userId?.firstName} {" "} {" "}
                            {products.userId && products?.userId?.lastName}
                          </h2>
                        </div>
                        <div className="text-right mt-0">
                          {products.userId && (
                            <Link
                              to={`/user/all-products/${products.userId._id}`}
                              className="see-all-btn font-size-14"
                            >
                              All Items{" "}
                              <i className="fas fa-arrow-right"></i>
                            </Link>
                          )}
                          <p>
                            {/* Posted <Moment fromNow>{products.time}</Moment>{" "} */}
                            Listed,{" "}
                            {moment(products.time).fromNow(true) +
                              ", " +
                              moment(products.time).format("LT")}
                          </p>
                        </div>
                      </div>

                      <div className="detail-owner-name-bx clearfix">
                        <div className="detail-owner-img">
                          {products.userId && products.userId.profilePicture ? (
                            <img
                              src={General.showImageNew(
                                "profile",
                                products.userId.profilePicture
                              )}
                              onError={
                                (e) => e.target.src = '/images/user.png'
                              }
                              alt={products.userId.profilePicture}
                            />
                          ) : (
                            <img src="/images/user.png" alt="image1" />
                          )}
                          {products.userId &&
                            products.userId.isOnline === true ? (
                            <span className="live-dot"></span>
                          ) : (
                            <span
                              className="live-dot"
                              style={{ background: "rgb(203, 211, 227)" }}
                            ></span>
                          )}
                        </div>
                        <div className="detail-owner-name">
                          <p>
                            {products?.userId?.isOnline &&
                              products.userId.goesOffline === 1 ? (
                              <span>Online</span>
                            ) : (
                              <>
                                <span>Last Seen :</span>{" "}
                                <Moment fromNow>
                                  {products?.userId?.lastLogin}
                                </Moment>
                              </>
                            )}
                          </p>
                          {/* <p className="m-0 profile-user-name">{products.userId.username}</p> */}
                          <div className="rated-star">
                            <span className="pr-2">Rated</span>
                            {""}
                            <span>
                              <strong>
                                {avgRating ? avgRating.toFixed(1) : 0.0}
                              </strong>
                            </span>
                            <StarRatings
                              rating={avgRating ? avgRating : 0}
                              starRatedColor="#249ee4"
                              starDimension="20px"
                              starSpacing="0"
                              numberOfStars={5}
                            />
                            {/* <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i> */}
                          </div>
                        </div>
                      </div>

                      <div className="contact-bx desktop_view">
                        <div className="livestriming_lsec">
                          <div className="joinleft_lsec">
                            {/* <p>Join</p> */}
                          </div>
                          <div className="joinright_lsec">
                            <span className="hammer_icon">
                              <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_290_552)">
                                  <path d="M25.4236 18.6237L18.1709 12.7615C17.479 12.2023 16.5668 12.1174 15.8028 12.4463L13.5574 10.6314C13.9871 9.9723 14.3153 9.30119 14.538 8.65199C14.3923 8.61972 14.2553 8.56052 14.1377 8.46547L10.3773 5.42604C10.2607 5.33179 10.1754 5.20925 10.115 5.07153C9.26457 5.5825 8.41291 6.31788 7.66402 7.24442C6.90035 8.18923 6.35145 9.19696 6.03293 10.1525C6.16996 10.1861 6.2996 10.2419 6.41159 10.332L10.172 13.3714C10.283 13.4612 10.369 13.573 10.4321 13.7011C11.0365 13.3437 11.6411 12.87 12.2115 12.296L14.4569 14.1109C14.2955 14.927 14.5698 15.8011 15.2617 16.3603L22.5145 22.2233C23.5084 23.0267 24.9653 22.8723 25.7687 21.8783C26.572 20.8844 26.4175 19.4271 25.4236 18.6237Z" fill="white" />
                                  <path d="M14.3844 8.07101C14.452 8.13037 14.535 8.16559 14.6259 8.18464C14.9377 8.24938 15.3523 8.08576 15.6521 7.7444C15.8864 7.47705 16.0154 7.1454 15.9967 6.85659C15.9888 6.73563 15.9506 6.56632 15.8125 6.44507L14.689 5.45862L13.3026 4.24126L12.1792 3.25481C12.0415 3.13398 11.8404 3.09437 11.6132 3.14424C11.36 3.19948 11.111 3.35513 10.9119 3.58184C10.6776 3.84919 10.5486 4.18084 10.5673 4.4692C10.5709 4.52487 10.5826 4.59103 10.6059 4.65787C10.6331 4.73643 10.6771 4.81579 10.752 4.88114L14.3844 8.07101Z" fill="white" />
                                  <path d="M9.78935 14.0893C9.78245 14.0271 9.7658 13.9519 9.73209 13.8778C9.70083 13.8094 9.65534 13.7416 9.58611 13.6862L5.81142 10.6645C5.74833 10.614 5.67237 10.5854 5.59165 10.5684C5.27416 10.5025 4.85234 10.6837 4.56039 11.049C4.33847 11.3267 4.22467 11.6639 4.25649 11.9511C4.26984 12.0715 4.31577 12.2389 4.45923 12.3538L5.74544 13.3834L6.94776 14.3463L8.23397 15.3759C8.54582 15.6251 9.11889 15.4494 9.48546 14.9915C9.70737 14.7137 9.82117 14.3765 9.78935 14.0893Z" fill="white" />
                                  <path d="M3 19.3383C3.56081 19.283 4.16222 19.2673 4.79033 19.2673C5.0581 19.2673 5.33227 19.2692 5.61154 19.2715C6.19033 19.2761 6.80234 19.2761 7.38118 19.2715C7.66045 19.2692 7.93462 19.2673 8.20239 19.2673C8.8305 19.2673 9.43191 19.283 9.99272 19.3383V18.001H3V19.3383Z" fill="white" />
                                  <path d="M8.17666 20.001C7.91031 20.001 7.6375 20.0028 7.35913 20.0051C6.77757 20.0093 6.16369 20.0093 5.58258 20.0051C5.30421 20.0028 5.0314 20.001 4.76505 20.001C4.11296 20.001 3.51478 20.0139 2.97472 20.065C1.19357 20.2333 0.0760263 20.8342 0 22.902H12.9413C12.8653 20.8342 11.7477 20.2338 9.96659 20.0651C9.42692 20.0139 8.82824 20.001 8.17666 20.001Z" fill="white" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_290_552">
                                    <rect width="27" height="27" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                            <Link
                              className="font-size-16"
                              to={Product.isLogin() ?
                                "/auctionRegisterToBid/" +
                                productDataId +
                                "/" +
                                liveStreamingId : "/login"
                              }
                            >
                              Register To Bid
                              {/* <img
                                src="https://dlevel.b-cdn.net/images/logo.png"
                                //src="https://dlevel.b-cdn.net/images/streaming-logo.jpg"
                                alt="logo"
                              /> */}
                            </Link>
                          </div>
                        </div>

                        {(preBidDetail && preBidDetail.length > 0) &&
                          <>
                            <p className="d-block number-reveal-btn Bids_btn" onClick={() => this.setState({ showBidders: true })}>
                              {`${preBidDetail.length} Bids`}
                            </p>
                          </>
                        }
                        <div className="bid_time">
                          {startTime != "" &&
                            <span className="">
                              {moment(startTime).diff(
                                moment(),
                                "seconds"
                              ) > 1800 ? (<> {this.getDiffInDayHour(startTime)} | <Moment format="dddd, hh:mm a">{startTime}</Moment></>) :
                                moment(startTime).diff(
                                  moment(),
                                  "seconds"
                                ) > 0 ? (
                                  <Countdown
                                    date={startTime}
                                    zeroPadTime={2}
                                    renderer={({ days, hours, minutes, seconds }) => (
                                      <time>
                                        {minutes +
                                          ":" +
                                          seconds}{" "}
                                        to go
                                      </time>
                                    )}
                                  />) : <time>Live</time>}
                            </span>
                          }
                          <Link
                            to="#!"
                            className="number-reveal-btn"
                            onClick={this.setClassActive}
                          >
                            <img src="/images/msg-icon.png" alt="icon" />{" "}
                            <span>Message</span>
                          </Link>
                        </div>

                      </div>
                      <div className="safety-tips-bx desktop_view">
                        <h2>Delivery</h2>
                        <div>
                          <ul>
                            {this.state.showDeliveryCharge === true ? (
                              <>
                                {
                                  products.delivery == 2 && products.freeDeliveryOptions == 1 ? (
                                    <li className="delivery_itesm" >
                                      Shipping :
                                      <span className="delivery_status_track pl-1">
                                        Free
                                      </span>{" "}
                                    </li>
                                  ) : (
                                    <li className="delivery_itesm" >
                                      Shipping :
                                      <span className="delivery_status_track pl-1">
                                        {General.showPriceDecimal(
                                          products?.deliveryCost
                                        )}
                                      </span>{" "}
                                    </li>
                                  )
                                }
                              </>
                            ) : (
                              <li className="delivery_itesm">Local collection from seller</li>
                            )}

                            <li>Order protection : <span className="delivery_status_track pl-1">{General.showPercentDecimal(
                              products?.buyerProtectionCharge
                            )} + {General.showPriceDecimal(this.state.adminCost)}</span></li>
                            {/* <li>Service charge : ({General.showPercentDecimal(this.state.adminCost)})</li> */}
                            <li>Secure payment</li>
                          </ul>
                        </div>
                      </div>
                      <div className="detail-owner-btn">
                        {/* {this.state.isWishlist ? (
                          <a
                            href="javascript:void(0);"
                            onClick={() =>
                              this.removeWishlistHandler(products._id)
                            }
                          >
                            <span>
                              <i className="fas fa-heart"></i>
                            </span>{" "}
                            Wishlist{" "}
                          </a>
                        ) : (
                          <a
                            href="javascript:void(0);"
                            onClick={() =>
                              this.addWishlistHandler(products._id)
                            }
                          >
                            {" "}
                            <span>
                              <i className="far fa-heart"></i>
                            </span>{" "}
                            Wishlist{" "}
                          </a>
                        )} */}
                        <a
                          className="btn btn-secondary dropdown-toggle"
                          href={void (0)}
                          onClick={() =>
                            this.setState({
                              showSecurityTips: !this.state.showSecurityTips,
                              showReport: false,
                            })
                          }
                        >
                          <span>
                            <i className="fas fa-hand-paper"></i>
                          </span>{" "}
                          Safety Tips{" "}
                        </a>

                        <div className="dropdown">
                          <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            onClick={() =>
                              this.setState({
                                showReport: !this.state.showReport,
                              })
                            }
                          >
                            <span>
                              <i className="fas fa-exclamation-triangle"></i>
                            </span>{" "}
                            Report
                          </button>
                        </div>
                      </div>
                      {this.state.showSecurityTips &&
                        this.state.productSafetyTips && (
                          <div className="safety-tips-bx">
                            <h2>Safety Tips</h2>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.productSafetyTips,
                              }}
                            />
                          </div>
                        )}
                      {this.state.showReport && (
                        <div className="report_section">
                          <div className="report_section_padding">
                            <div className="text-center report_section_heading">
                              <span>
                                <i
                                  className="fas fa-exclamation-triangle"
                                  aria-hidden="true"
                                ></i>
                              </span>{" "}
                              Report
                            </div>
                            <div className="report_ul">
                              <ul>
                                {this.state.reports &&
                                  this.state.reports.map((report) => (
                                    <li key={report._id}>
                                      <div className="check-box big">
                                        <input
                                          onChange={this.reportCheckHandler}
                                          value={report._id}
                                          className="form-control-line New_control"
                                          type="checkbox"
                                          id={report._id}
                                          checked={
                                            this.state.selectedReports.indexOf(
                                              report._id
                                            ) > -1
                                          }
                                        />
                                        <label htmlFor={report._id}>
                                          {report.name}
                                        </label>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                            {!Product.isLogin() && (
                              <>
                                <div className="information_more_report">
                                  Please provide more information
                                </div>
                                <div className="row">
                                  <div className="col md-12">
                                    <div className="form-group">
                                      <label>Name</label>
                                      <input
                                        type="text"
                                        value={this.state.reportName}
                                        onChange={(e) =>
                                          this.setState({
                                            reportName: e.target.value,
                                          })
                                        }
                                        className="form-control-line"
                                        placeholder="Enter name"
                                      ></input>
                                    </div>
                                    <div className="form-group">
                                      <label>Email address</label>
                                      <input
                                        type="email"
                                        value={this.state.reportEmail}
                                        onChange={(e) =>
                                          this.setState({
                                            reportEmail: e.target.value,
                                          })
                                        }
                                        className="form-control-line"
                                        placeholder="Enter email"
                                      ></input>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          <div className="detail-owner-btn">
                            <Link
                              to="#!"
                              onClick={() =>
                                this.setState({
                                  showReport: false,
                                })
                              }
                              className="Cancel_report_btn"
                            >
                              <span></span> Cancel{" "}
                            </Link>

                            <Link
                              to="#!"
                              onClick={this.reportSubmitHandle}
                              className="send_report_btn"
                            >
                              <span></span> Send Report{" "}
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="share-bx desktop_view">
                      <p>Share:</p>
                      <ul>
                        <li>
                          <FacebookShareButton
                            url={
                              process.env.REACT_APP_FRONTEND_URL +
                              `/product/${products ? products._id : ""}`
                            }
                          >
                            <FacebookIcon
                              iconFillColor="white"
                              size={48}
                              round={true}
                            />
                          </FacebookShareButton>
                        </li>
                        <li>
                          <TwitterShareButton
                            url={
                              process.env.REACT_APP_FRONTEND_URL +
                              `/product/${products ? products._id : ""}`
                            }
                          >
                            <TwitterIcon
                              iconFillColor="white"
                              size={48}
                              round={true}
                            />
                          </TwitterShareButton>
                        </li>
                        <li>
                          <EmailShareButton
                            url={
                              process.env.REACT_APP_FRONTEND_URL +
                              `/product/${products ? products._id : ""}`
                            }
                          >
                            <EmailIcon
                              iconFillColor="white"
                              size={48}
                              round={true}
                            />
                          </EmailShareButton>
                        </li>
                        {/* <li><a href="#" className="facebook-btn"><i className="fab fa-facebook-f"></i></a></li> */}
                        {/* <li><a href="#" className="twitter-btn"><i className="fab fa-twitter"></i></a></li> */}
                        {/* <li><a href="#" className="insta-btn"><i className="fab fa-instagram"></i></a></li> */}
                        {/* <li><a href="#" className="email-btn"><i className="far fa-envelope"></i></a></li> */}
                      </ul>
                    </div>
                  </div>
                </div>



                <div className="product-all-detail-tab desktop_view">
                  <ul class="nav nav-tabs my-custom-tab" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" id="Item_Detail" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                        Item Detail

                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="Customers_Reviews" data-toggle="tab" href="#customer" role="tab" aria-controls="customer" aria-selected="false">
                        Customers Reviews

                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="Photo-tab" data-toggle="tab" href="#Photo" role="tab" aria-controls="Photo" aria-selected="false">
                        Photo
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="Item_Detail">
                      <div className="product-detail-tab">
                        <div className="row">
                          <div className="col-lg-3">
                            <p className="product-specs">Seller Type</p>
                          </div>
                          <div className="col-lg-3">
                            <p className="product-specs bold-text">
                              {products.userId && products.userId.sellerType == "1" ? (
                                "Company"
                              ) : products.userId && products.userId.sellerType == "2" ? (
                                "Soletrader"
                              ) : products.userId && products.userId.sellerType == "3" ? (
                                "Private"
                              ) : (
                                <>{products.userId?.sellerType}</>
                              )}
                            </p>
                          </div>
                          <div className="col-lg-3">
                            <p className="product-specs">Listed</p>
                          </div>
                          <div className="col-lg-3">
                            <p className="product-specs bold-text">
                              <Moment fromNow>{products.time}</Moment>
                            </p>
                          </div>
                          {productCustomFields.length > 0 && (
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-lg-12">
                                  <h2 className="product-sub-heading">

                                    Key Information
                                  </h2>
                                </div>
                                {productCustomFields &&
                                  productCustomFields.map((field, index) => (
                                    <>
                                      <div className="col-md-3">
                                        <p className="product-specs">
                                          {field.field_name}
                                        </p>
                                      </div>
                                      <div className="col-md-3">
                                        <p className="product-specs">
                                          <b>{field.field_value_id &&
                                            field.field_value_id.name}</b>
                                        </p>
                                      </div>
                                    </>
                                  ))}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="line-divider"></div>
                        <div className="product-discription">
                          <h2 className="same-bx-heading">Description</h2>
                          <p className="description_text">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: products.productDescription.replace(/\n/g, "<br />"),
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="customer" role="tabpanel" aria-labelledby="Customers_Reviews">
                      <div className="row">
                        <div className="col-xl-12 customer-review-bx d-flex flex-wrap justify-content-between">
                          <h2>
                            {reviews && reviews.length} Reviews from Customers
                          </h2>
                          <div className="d-flex rating_section">
                            <div class="star star-filter"><span>(All)</span></div>
                            <div className="star col d-content">
                              <span>
                                ({General.showRatingCount(reviews, 5)})
                              </span>
                              <StarRatings
                                rating={5}
                                starRatedColor="#249ee4"
                                starDimension="17px"
                                starSpacing="1px"
                                numberOfStars={5}
                              />
                            </div>
                            <div className="star col d-content">
                              <span>
                                ({General.showRatingCount(reviews, 4)})
                              </span>
                              <StarRatings
                                rating={4}
                                starRatedColor="#249ee4"
                                starDimension="17px"
                                starSpacing="1px"
                                numberOfStars={5}
                              />
                            </div>
                            <div className="star col d-content">
                              <span>
                                ({General.showRatingCount(reviews, 3)})
                              </span>
                              <StarRatings
                                rating={3}
                                starRatedColor="#249ee4"
                                starDimension="17px"
                                starSpacing="1px"
                                numberOfStars={5}
                              />
                            </div>
                            <div className="star col d-content">
                              <span>
                                ({General.showRatingCount(reviews, 2)})
                              </span>
                              <StarRatings
                                rating={2}
                                starRatedColor="#249ee4"
                                starDimension="17px"
                                starSpacing="1px"
                                numberOfStars={5}
                              />
                            </div>
                            <div className="star col d-content">
                              <span>
                                ({General.showRatingCount(reviews, 1)})
                              </span>
                              <StarRatings
                                rating={1}
                                starRatedColor="#249ee4"
                                starDimension="17px"
                                starSpacing="1px"
                                numberOfStars={5}
                              />
                            </div>
                          </div>
                        </div>



                        <div className="col-xl-4">
                          <div className="customer-review-bx">

                            <div className="rating-row row align-items-center">
                              <div className="star-txt">5 Stars</div>
                              <div className="rating-view col">
                                <LinearProgress
                                  variant="determinate"
                                  value={General.showRatingPercentage(
                                    reviews,
                                    5
                                  )}
                                />
                              </div>
                              <div className="rating-text col">
                                <h4>
                                  {General.showRatingPercentage(reviews, 5)}%
                                </h4>
                              </div>

                            </div>
                            <div className="rating-row row align-items-center">
                              <div className="star-txt">4 Stars</div>
                              <div className="rating-view col">
                                <LinearProgress
                                  variant="determinate"
                                  value={General.showRatingPercentage(
                                    reviews,
                                    4
                                  )}
                                />
                              </div>
                              <div className="rating-text col">
                                <h4>
                                  {General.showRatingPercentage(reviews, 4)}%
                                </h4>
                              </div>

                            </div>
                            <div className="rating-row row align-items-center">
                              <div className="star-txt">3 Stars</div>
                              <div className="rating-view col">
                                <LinearProgress
                                  variant="determinate"
                                  value={General.showRatingPercentage(
                                    reviews,
                                    3
                                  )}
                                />
                              </div>
                              <div className="rating-text col">
                                <h4>
                                  {General.showRatingPercentage(reviews, 3)}%
                                </h4>
                              </div>

                            </div>
                            <div className="rating-row row align-items-center">
                              <div className="star-txt">2 Stars</div>
                              <div className="rating-view col">
                                <LinearProgress
                                  variant="determinate"
                                  value={General.showRatingPercentage(
                                    reviews,
                                    2
                                  )}
                                />
                              </div>
                              <div className="rating-text col">
                                <h4>
                                  {General.showRatingPercentage(reviews, 2)}%
                                </h4>
                              </div>

                            </div>
                            <div className="rating-row row align-items-center">
                              <div className="star-txt">1 Stars</div>
                              <div className="rating-view col">
                                <LinearProgress
                                  variant="determinate"
                                  value={General.showRatingPercentage(
                                    reviews,
                                    1
                                  )}
                                />
                              </div>
                              <div className="rating-text col">
                                <h4>
                                  {General.showRatingPercentage(reviews, 1)}%
                                </h4>
                              </div>

                            </div>
                          </div>
                        </div>

                        <div className="col-xl-8">
                          <div className="customer-review-profile_block">
                            <div className="row pb-3">
                              <div className="col-md-3">
                                <div className="view-more-review">
                                  View
                                  <b> {reviews.length} More </b> reviews
                                </div>
                              </div>
                              <div className="col-md-3">Share your reviews</div>
                              <div className="col-md-3">
                                <div className="review-share">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fal"
                                    data-icon="share"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    className="svg-inline--fa fa-share fa-w-18 fa-2x"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M564.907 196.35L388.91 12.366C364.216-13.45 320 3.746 320 40.016v88.154C154.548 130.155 0 160.103 0 331.19c0 94.98 55.84 150.231 89.13 174.571 24.233 17.722 58.021-4.992 49.68-34.51C100.937 336.887 165.575 321.972 320 320.16V408c0 36.239 44.19 53.494 68.91 27.65l175.998-184c14.79-15.47 14.79-39.83-.001-55.3zm-23.127 33.18l-176 184c-4.933 5.16-13.78 1.73-13.78-5.53V288c-171.396 0-295.313 9.707-243.98 191.7C72 453.36 32 405.59 32 331.19 32 171.18 194.886 160 352 160V40c0-7.262 8.851-10.69 13.78-5.53l176 184a7.978 7.978 0 0 1 0 11.06z"
                                      className=""
                                    ></path>
                                  </svg>
                                  Share
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="rated-customer-bx-inner">
                                  <div className="rated-star text-left">
                                    <span>Rated</span>

                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {reviews &&
                              reviews.map((review) => (
                                <div key={review._id} className="review_block">
                                  <div className="row align-items-center">
                                    <div className="col-lg-9">
                                      <div className="detail-owner-name-bx clearfix review_author align-items-start">
                                        <div className="detail-owner-img">
                                          {review.userId &&
                                            review.userId.profilePicture ? (
                                            <img
                                              src={General.showImageNew(
                                                "profile",
                                                review.userId.profilePicture
                                              )}
                                              alt={review.userId.profilePicture}
                                            />
                                          ) : (
                                            <img
                                              src="/images/no-image.jpg"
                                              alt="image1"
                                            />
                                          )}
                                        </div>
                                        <div className="detail-owner-name">
                                          <h3>
                                            {review?.userId?.firstName} {""}{" "}
                                            {review?.userId?.lastName}
                                          </h3>
                                          <div className="rated-customer-bx-inner">
                                            <div className="rated-star">
                                              <span>Rated</span>{" "}
                                              <StarRatings
                                                rating={review.rating}
                                                starRatedColor="#249ee4"
                                                starDimension="18px"
                                                starSpacing="0"
                                                numberOfStars={5}
                                              />
                                            </div>
                                            <h2 className="font-weight-400">{review.review}</h2>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {this.state.id ===
                                      this.state.productUserId && (
                                        <div className="col-lg-3">
                                          <div className="rated-customer-bx d-block text-right">
                                            <Link
                                              to="#!"
                                              onClick={(e) =>
                                                this.toggleReplyAsSeller(e, review._id)
                                              }
                                              className="number-reveal-btn"
                                            >
                                              <img
                                                src="/images/reply-icon.png"
                                                alt=""
                                              />
                                              <span>Reply As Seller</span>
                                            </Link>
                                          </div>
                                        </div>
                                      )}
                                    {review.replies &&
                                      review.replies.map((reviewReply, index) => (
                                        <div className="review_reply" key={index}>
                                          <div className="row align-items-center w-100 no-gutters">
                                            <div className="col-auto">
                                              <div className="detail-owner-name-bx clearfix review_author py-0">
                                                <div className="detail-owner-img reply_img_b">
                                                  {reviewReply.userId &&
                                                    reviewReply.userId
                                                      .profilePicture ? (
                                                    <img
                                                      src={General.showImageNew(
                                                        "profile",
                                                        reviewReply.userId
                                                          .profilePicture
                                                      )}
                                                      alt={
                                                        reviewReply.userId
                                                          .profilePicture
                                                      }
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/images/no-image.jpg"
                                                      alt="image1"
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col ">
                                              <div className="rated-customer-bx-inner review_author">
                                                <h3>
                                                  {reviewReply.userId.firstName}{" "}
                                                  {""} {reviewReply.userId.lastName}
                                                </h3>

                                                <h2>{reviewReply.reply}</h2>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    {this.state.showReplyAsSeller ===
                                      review._id && (
                                        <div className="review_reply">
                                          <div className="row align-items-start w-100 no-gutters">
                                            <div className="col-auto">
                                              <div className="detail-owner-name-bx clearfix">
                                                <div className="detail-owner-img reply_img_b">
                                                  {this.state.profilePicture ? (
                                                    <img
                                                      src={General.showImageNew(
                                                        "profile",
                                                        this.state.profilePicture
                                                      )}
                                                      alt={this.state.profilePicture}
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/images/no-image.jpg"
                                                      alt="image1"
                                                    />
                                                  )}
                                                  <span className="live-dot"></span>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col">
                                              <div className="form-group mb-0">
                                                <textarea
                                                  className="form-control-line h-30px"
                                                  rows="2"
                                                  name="reply"
                                                  value={this.state.reply}
                                                  onChange={(e) =>
                                                    this.setState({
                                                      reply: e.target.value,
                                                    })
                                                  }
                                                ></textarea>
                                              </div>
                                              <div className="form-group mt-3">
                                                <button
                                                  className="btnx btn-secondaryx float-right"
                                                  onClick={(e) =>
                                                    this.handleReplySubmit(
                                                      e,
                                                      review._id
                                                    )
                                                  }
                                                >
                                                  Reply
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              ))}
                            {Product.isLogin() &&
                              this.state.is_review_post === 1 && (
                                <div className="review_mess">
                                  <form onSubmit={this.handleSubmit}>
                                    <div className="review_reply pr-0 ">
                                      <div className="row w-100">
                                        <div className="col-auto">
                                          <div className="detail-owner-name-bx clearfix">
                                            <div className="detail-owner-img">
                                              {this.state.profilePicture ? (
                                                <img
                                                  src={General.showImageNew(
                                                    "profile",
                                                    this.state.profilePicture
                                                  )}
                                                  alt={this.state.profilePicture}
                                                />
                                              ) : (
                                                <img
                                                  src="/images/no-image.jpg"
                                                  alt="image1"
                                                />
                                              )}

                                              <span className="live-dot"></span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col pl-3">
                                          <div className="detail-owner-name pl-0">
                                            <div className="rated-customer-bx-inner mess-sender-name">
                                              <h2>{this.state.name}</h2>
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <textarea
                                              name="review"
                                              value={this.state.review}
                                              onChange={this.handleChange}
                                              className="form-control-line h-20px"
                                              rows="2"
                                              placeholder="share your review"
                                              maxLength={this.state.maxLength}
                                            ></textarea>
                                          </div>
                                          <div className="d-flex">
                                            {this.state.review.length}/
                                            {this.state.maxLength}
                                            <div className="flex-g">
                                              <div className="rated-star mr-4">
                                                <Rating
                                                  name="rating"
                                                  value={this.state.rating}
                                                  onChange={(event, newValue) => {
                                                    this.setState({
                                                      rating: event.target.value,
                                                    });
                                                  }}
                                                />
                                              </div>
                                              <div className="form-group">
                                                <button
                                                  type="submit"
                                                  className="btnx btn-secondaryx font-size-14"
                                                >
                                                  Submit
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="Photo" role="tabpanel" aria-labelledby="Photo-tab">
                      <div className="photo_section">
                        {products.productImages ? (
                          products.productImages.map((image, index) => (
                            <div className="photo_img">
                              <a
                                key={index}
                                href={General.showImage("products/resize-566-400", image)}
                                data-fancybox={`desktop-product-${image}`}
                                data-caption={`Image ${index + 1}`}
                              >
                                <img
                                  key={image}
                                  src={General.showImage(
                                    "products/resize-566-400",
                                    image
                                  )}
                                  alt={image}
                                />
                              </a>
                            </div>
                          ))
                        ) : (
                          <div class="alert alert-info">
                            <strong>Info!</strong> Nothing here.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {similarProducts && similarProducts.length > 0 && (
                  <div className="similar-products-bx featured-product-section margin_bottonnone">
                    {products.categoryId && (
                      <div className="section-heading">
                        <h2>Similar Items</h2>
                        <Link
                          to={`/live-auction/${products.categoryId.slug}`}
                          className="see-all-btn"
                        >
                          See All <i className="fas fa-arrow-right"></i>
                        </Link>
                      </div>
                    )}
                    <div className="row low-margin">
                      {similarProducts.map((similarPro, index) => (
                        <div className="col" key={"similar-" + similarPro.productId}>
                          {/* {console.log('Rendering product:', similarPro.productId)} */}
                          <div className="product-outer-bx">
                            <Link to={
                              similarPro.userId === this.state.id
                                ? {
                                  pathname: "/livestreamingProduct/" + similarPro._id,
                                  query: {
                                    productId: similarPro.productSlug,
                                  },
                                }
                                : "/upcomingLiveStreamingProduct/" +
                                similarPro.productSlug +
                                "/" +
                                similarPro._id
                            }>
                              <div className="product-img-bx">
                                {similarPro.adType && (
                                  <div className="specialtags">
                                    <span
                                      className={
                                        (similarPro.adType === "Featured"
                                          ? "black"
                                          : similarPro.adType === "Urgent"
                                            ? "red"
                                            : "green") + "_special"
                                      }
                                    >
                                      {similarPro.adType}{" "}
                                    </span>
                                  </div>
                                )}
                                {similarPro.productMainImage ? (
                                  <img
                                    src={General.showImage(
                                      "products/resize-180-180",
                                      similarPro.productMainImage
                                    )}
                                    alt={similarPro.productName}
                                  />
                                ) : (
                                  <img
                                    src="/images/no-image.jpg"
                                    alt={similarPro.productName}
                                  />
                                )}
                              </div>
                              <div className="product-name-bx">
                                <span className="similar_item_countdown">
                                  {moment(similarPro?.startTime).diff(
                                    moment(),
                                    "seconds"
                                  ) > 0 ? (
                                    <Countdown
                                      date={similarPro?.startTime}
                                      zeroPadTime={2}
                                      renderer={({ days, hours, minutes, seconds }) => (
                                        <time>
                                          {days +
                                            " days" +
                                            " " +
                                            hours +
                                            ":" +
                                            minutes +
                                            ":" +
                                            seconds}{" "}
                                          to go
                                        </time>
                                      )}
                                    />) : ""}
                                </span>
                                <h6>
                                  <span>
                                    {" "}
                                    {General.showPriceDecimal(similarPro.starting_price)}
                                  </span>
                                </h6>
                                <h4>{similarPro.productName}</h4>
                                <p>
                                  {products && products?.location ? products.location : "N/A"}
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </section>
            <section className="bg-light-dark">
              <div></div>
            </section>{" "}
          </>
        ) : (
          <section className="repeat-section product-detail-main-bx bg-light-dark onmobilp_topnon ondesktop_topnon mobiledisplay_oneline">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-7">
                  <ContentLoader
                    speed={2}
                    width={400}
                    height={460}
                    viewBox="0 0 400 460"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  // {...props}
                  >
                    <circle cx="31" cy="31" r="15" />
                    <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
                    <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
                    <rect x="0" y="60" rx="2" ry="2" width="400" height="400" />
                  </ContentLoader>
                </div>
                <div className="col-xl-4  col-lg-5 offset-xl-2">
                  <ContentLoader viewBox="0 0 400 475" height={610} width={400}>
                    <rect x="0" y="50" rx="5" ry="5" width="400" height="610" />
                  </ContentLoader>
                  <div>
                    <ContentLoader
                      viewBox="0 0 380 130"
                      height={130}
                      width={380}
                    >
                      <rect
                        x="0"
                        y="50"
                        rx="5"
                        ry="5"
                        width="380"
                        height="130"
                      />
                    </ContentLoader>
                  </div>
                </div>
              </div>
              <div className="similar-products-bx featured-product-section margin_bottonnone">
                <ContentLoader viewBox="0 0 1360 900" height={900} width={1360}>
                  <rect x="30" y="20" rx="8" ry="8" width="200" height="200" />
                  <rect x="30" y="250" rx="0" ry="0" width="200" height="18" />
                  <rect x="30" y="275" rx="0" ry="0" width="120" height="20" />
                  <rect x="250" y="20" rx="8" ry="8" width="200" height="200" />
                  <rect x="250" y="250" rx="0" ry="0" width="200" height="18" />
                  <rect x="250" y="275" rx="0" ry="0" width="120" height="20" />
                  <rect x="470" y="20" rx="8" ry="8" width="200" height="200" />
                  <rect x="470" y="250" rx="0" ry="0" width="200" height="18" />
                  <rect x="470" y="275" rx="0" ry="0" width="120" height="20" />
                  <rect x="690" y="20" rx="8" ry="8" width="200" height="200" />
                  <rect x="690" y="250" rx="0" ry="0" width="200" height="18" />
                  <rect x="690" y="275" rx="0" ry="0" width="120" height="20" />
                  <rect x="910" y="20" rx="8" ry="8" width="200" height="200" />
                  <rect x="910" y="250" rx="0" ry="0" width="200" height="18" />
                  <rect x="910" y="275" rx="0" ry="0" width="120" height="20" />
                  <rect
                    x="1130"
                    y="20"
                    rx="8"
                    ry="8"
                    width="200"
                    height="200"
                  />
                  <rect
                    x="1130"
                    y="250"
                    rx="0"
                    ry="0"
                    width="200"
                    height="18"
                  />
                  <rect
                    x="1130"
                    y="275"
                    rx="0"
                    ry="0"
                    width="120"
                    height="20"
                  />
                  <rect x="30" y="340" rx="8" ry="8" width="200" height="200" />
                  <rect x="30" y="570" rx="0" ry="0" width="200" height="18" />
                  <rect x="30" y="595" rx="0" ry="0" width="120" height="20" />
                  <rect
                    x="250"
                    y="340"
                    rx="8"
                    ry="8"
                    width="200"
                    height="200"
                  />
                  <rect x="250" y="570" rx="0" ry="0" width="200" height="18" />
                  <rect x="250" y="595" rx="0" ry="0" width="120" height="20" />
                  <rect
                    x="470"
                    y="340"
                    rx="8"
                    ry="8"
                    width="200"
                    height="200"
                  />
                  <rect x="470" y="570" rx="0" ry="0" width="200" height="18" />
                  <rect x="470" y="595" rx="0" ry="0" width="120" height="20" />
                  <rect
                    x="690"
                    y="340"
                    rx="8"
                    ry="8"
                    width="200"
                    height="200"
                  />
                  <rect x="690" y="570" rx="0" ry="0" width="200" height="18" />
                  <rect x="690" y="595" rx="0" ry="0" width="120" height="20" />
                  <rect
                    x="910"
                    y="340"
                    rx="8"
                    ry="8"
                    width="200"
                    height="200"
                  />
                  <rect x="910" y="570" rx="0" ry="0" width="200" height="18" />
                  <rect x="910" y="595" rx="0" ry="0" width="120" height="20" />
                  <rect
                    x="1130"
                    y="340"
                    rx="8"
                    ry="8"
                    width="200"
                    height="200"
                  />
                  <rect
                    x="1130"
                    y="570"
                    rx="0"
                    ry="0"
                    width="200"
                    height="18"
                  />
                  <rect
                    x="1130"
                    y="595"
                    rx="0"
                    ry="0"
                    width="120"
                    height="20"
                  />
                </ContentLoader>
              </div>
            </div>
          </section>
        )}
        <Modal
          isOpen={this.state.showBidders}
          onRequestClose={() =>
            this.setState({
              showBidders: false
            })
          }
          style={customStyles}
          ariaHideApp={false}
          className="shipModal"
        >
          <button onClick={() => this.setState({
            showBidders: false
          })} className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>
          <table className="table table-dark mb-0">
            <thead>
              <tr>
                <th scope="col">Amount</th>
                <th scope="col">Bidder</th>
                <th scope="col">Time</th>
              </tr>
            </thead>
            <tbody>
              {preBidDetail && preBidDetail.length > 0 && preBidDetail.map((data, i) => {
                return (
                  <tr key={i}>
                    <td>{General.showPriceDecimal(data.amount)}</td>
                    <td>{`${data.userId?.firstName} ${data.userId?.lastName}`}</td>
                    <td><Moment fromNow>{data.updatedAt}</Moment></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(null, {
  OpenMessageBox,
  MessageData,
  SetSelectedChannel,
  SetOutgoingCallState,
  SetQuickbloxSession,
  SetSelectedUser,
  SetContactList,
})(UpcomingLiveStreamingProductDetailComponent);
