import React, { Component } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import General from "Helpers/General";
import MyProfile from "Models/MyProfile";
import { Link, Redirect } from "react-router-dom";
import Moment from "react-moment";
import StarRatings from "react-star-ratings";
import Storage from "Helpers/Storage";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import "react-day-picker/lib/style.css";

import ContentLoader from "react-content-loader";
import Profile from "./Profile";
import Balance from "./Balance";
import PremiumService from "./PremiumService";
import AccountSettings from "./AccountSettings";
import Activity from "./Activity";
const SERVER = process.env.REACT_APP_SERVER;

class MyProfileComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      id: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
      firstName: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["firstName"]
        : "",
      lastName: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["lastName"]
        : "",
      memberSince: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["date"]
        : "",
      email: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["email"]
        : "",
      profilePicture: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["profilePicture"]
        : "",
      username: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["username"]
        : "",
      location: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["location"]
        : "",
      about: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["about"]
        : "",
      companyName: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["companyName"]
        : "",
      totalCount: localStorage.getItem("MyProducts")
        ? JSON.parse(localStorage.getItem("MyProducts")).length
        : 0,
      avgRating: 0,
      viewTab: (this.props.match.params.slug != undefined) ? this.props.match.params.slug : "profile",
      isLoading: false,
    }
  }

  componentDidMount = () => {
    this.getAllProducts();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.setState({ viewTab: this.props.match.params.slug });
    }
  }

  getAllProducts = () => {
    MyProfile.getApi(
      "APIS_GET_MY_PROFILE_PRODUCT",
      async (response) => {
        Storage.setLocalData("MyAvgRating", JSON.stringify(response.avgRating));
        this.setState({ avgRating: response.avgRating });
        this.setState({ totalCount: response.totalCount });
      },
      true
    );
  };

  // Get User By Email
  getUserByEmail = () => {
    MyProfile.postApi(
      "API_GET_USER_BY_EMAIL",
      { email: localStorage.getItem("username") },
      async (response) => {
        if (response) {
          Storage.setLocalData("UserData", JSON.stringify(response));
          this.setState({
            id: response._id,
            firstName: response.firstName,
            lastName: response.lastName,
            email: response.email,
            companyName: response.companyName,
            memberSince: response.date,
            profilePicture: response.profilePicture
              ? response.profilePicture
              : "",
            username: response.username
              ? response.username
              : "",
            location: response.location
              ? response.location
              : "",
            about: response.about
              ? response.about
              : ""
          });
        }
      },
      false
    );
  };

  render() {
    if (!MyProfile.isLogin()) {
      MyProfile.redirectTo("login");
    }

    const { viewTab, totalCount, isLoading } = this.state;

    return (
      <React.Fragment>
        {isLoading && (
          <div className="loader-wrapper" id="loader_img">
            <div className="loader">
              <img src={this.state.headerLogo} alt="" />
              <div className="material-spinner"></div>
            </div>
          </div>
        )}
        <section className="repeat-section product-detail-main-bx bg-light-dark page_6tabmain_top margin-bottom-mobile">
          <div className="container">
            {viewTab === "profile" &&
              <div className="row">
                <div className="col-xl-12  col-lg-12">
                  <div className="top_rated_sec  onlypage06 post_product_box">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="detail-owner-name-bx clearfix">
                          <div className="detail-owner-img">
                            {this.state.profilePicture ? (
                              <img
                                src={General.showImageNew(
                                  "profile",
                                  this.state.profilePicture
                                )}
                                onError={
                                  (e) => e.target.src = '/images/user.png'
                                }
                                alt=""
                              />
                            ) : (
                              <img src="/images/user.png" alt="image" />
                            )}
                            <span className="live-dot"></span>
                          </div>
                          <div className="detail-owner-name">
                            <div className="rated-star">
                              <span>Rated </span>
                              {""}
                              <span>
                                <strong>
                                  {this.state.avgRating
                                    ? this.state.avgRating.toFixed(1)
                                    : 0.0}
                                </strong>
                              </span>
                              <StarRatings
                                rating={
                                  this.state.avgRating ? this.state.avgRating : 0
                                }
                                starRatedColor="#249ee4"
                                starDimension="20px"
                                starSpacing="0"
                                numberOfStars={5}
                              />
                            </div>
                            <h2 className="font-size-16">
                              {this.state.firstName} {this.state.lastName}
                            </h2>
                            <p className="m-0 profile-user-name">@{this.state.username}</p>
                            <p className="m-0 profile-user-location">{this.state.location}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="Jvideo_cus_perent ">
                          <div className="rated-customer-bx">
                            <div className="rated-customer-bx-inner">
                              <span className="member_box">
                                Member Since{" "}
                                <Moment format="MMM YYYY">
                                  {this.state.memberSince}
                                </Moment>
                              </span>
                              <h2>{totalCount} Listed Items</h2>
                              <Link
                                to={`/my-profile/activity`}
                                className="see-all-btn font-size-14"
                              >
                                Activity{" "}
                                <i className="fas fa-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-1">
                        <Link
                          to={`/my-profile/account-settings`}
                          className="number-reveal-btn"
                        >
                          <span className="font-size-12">Edit{" "}
                            <img
                              src="/images/edit-icon.png"
                              alt="images"
                            />
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {
              (viewTab === "profile") ?
                <Profile />
                : (viewTab === "activity") ?
                  <Activity />
                  : (viewTab === "balance") ?
                    <Balance /> :
                    (viewTab === "premium-services") ?
                      <PremiumService /> :
                      (viewTab === "account-settings") ?
                        <AccountSettings /> :
                        <section className="bg-light-dark">
                          <ContentLoader
                            speed={2}
                            width={400}
                            height={460}
                            viewBox="0 0 400 460"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                          >
                            <rect x="130" y="5" rx="2" ry="2" width="150" height="10" />
                            <rect x="20" y="20" rx="2" ry="2" width="350" height="50" />
                            <rect x="20" y="75" rx="2" ry="2" width="350" height="70" />
                            <rect x="20" y="150" rx="2" ry="2" width="350" height="50" />
                            <rect x="20" y="205" rx="2" ry="2" width="350" height="30" />
                            <rect x="20" y="240" rx="2" ry="2" width="350" height="50" />
                            <rect x="20" y="295" rx="2" ry="2" width="350" height="50" />
                            <rect x="20" y="350" rx="2" ry="2" width="350" height="70" />
                            <rect x="20" y="435" rx="2" ry="2" width="350" height="70" />
                          </ContentLoader>
                        </section>
            }
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default MyProfileComponent;
