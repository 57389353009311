import React, { Component } from "react";
import General from "Helpers/General";
import MyProfile from "Models/MyProfile";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import StarRatings from "react-star-ratings";
import {
  OpenMessageBox,
  SetOutgoingCallState,
  SetQuickbloxSession,
} from "Helpers/Actions";
import { connect } from "react-redux";
import QB, { playCallAudio } from "Helpers/Quickblox";
import { Fancybox } from "@fancyapps/fancybox";
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";
import { Carousel } from "react-responsive-carousel";
import Zoom from "react-medium-image-zoom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Modal from "react-modal";

export class MyOwnLevelComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      followers: [],
      followings: {},
      userFollowersData: [],
      userFollowingsData: [],
      userView: "follower",
      search: "",
      isLoading: false,
      isFollowingModalOpen: false,
      isFollowerModalOpen: false,
    };
    this.handleFollowingOpenModal = this.handleFollowingOpenModal.bind(this);
    this.handleFollowingCloseModal = this.handleFollowingCloseModal.bind(this);
    this.handleFollowerOpenModal = this.handleFollowerOpenModal.bind(this);
    this.handleFollowerCloseModal = this.handleFollowerCloseModal.bind(this);
  }

  handleFollowingOpenModal() {
    this.setState({ isFollowingModalOpen: true });
  }

  handleFollowingCloseModal() {
    this.setState({ isFollowingModalOpen: false });
  }

  handleFollowerOpenModal() {
    this.setState({ isFollowerModalOpen: true });
  }

  handleFollowerCloseModal() {
    this.setState({ isFollowerModalOpen: false });
  }

  componentDidMount = () => {
    this.getAllFollowers();
  };

  getAllFollowers = () => {
    const { search } = this.state;
    this.setState({ isLoading: true });
    MyProfile.postApi(
      "API_SEARCH_FOLLOWERS",
      { search },
      async ({ response, followers, followings }) => {
        console.log("All followers", response);
        this.setState({
          followers: response.followers,
          followings: response.followings,
          userFollowersData: followers,
          userFollowingsData: followings,
          isLoading: false,
        });
      },
      true
    );
  };

  getAllProfileDetails = (id) => {
    const { userView } = this.state;
    this.setState({ isLoading: true });
    MyProfile.postApi(
      "API_GET_USER_PROFILE_DETAILS",
      { id },
      async (response) => {
        if (userView == "follower") {
          this.setState({
            userFollowersData: response,
            isLoading: false,
          });
        } else {
          this.setState({
            userFollowingsData: response,
            isLoading: false,
          });
        }
      },
      true
    );
  };

  followHandler = (id) => {
    this.setState({ isLoading: true });
    MyProfile.postApi(
      "APIS_FOLLOW_USER",
      { id },
      async (response) => {
        this.getAllFollowers();
        this.setState({
          isLoading: false,
        });
      },
      true
    );
  };

  unfollowHandler = (id) => {
    this.setState({ isLoading: true });
    MyProfile.postApi(
      "APIS_UNFOLLOW_USER",
      { id },
      async (response) => {
        this.getAllFollowers();
      },
      true
    );
  };

  addWishlist = (productId) => {
    this.setState({ isLoading: true });
    MyProfile.postApiLoader(
      "APIS_ADD_WISHLIST",
      { productId: productId },
      async (response) => {
        this.getAllFollowers();
      },
      true
    );
  };

  sendMessageHandler = () => {
    if (localStorage.getItem("AuthIdentity")) {
      this.props.OpenMessageBox(true);
      localStorage.setItem("isOpenMessageBox", JSON.stringify(true));
      localStorage.setItem("isClosedMessageBox", JSON.stringify(false));
    }
  };

  startVideoCall = (email) => {
    if (email) {
      QB.users.get({ login: email }, (error, user) => {
        if (!error) {
          this.initiateCall(user.id);
        } else {
          console.log("Couldn't find user");
        }
      });
    }
  };

  initiateCall = (userId) => {
    this.props.SetOutgoingCallState(true);
    var calleesIds = [userId]; // Users' ids
    var sessionType = QB.webrtc.CallType.VIDEO; // AUDIO is also possible
    var additionalOptions = {};

    var session = QB.webrtc.createNewSession(
      calleesIds,
      sessionType,
      null,
      additionalOptions
    );

    var mediaParams = {
      audio: true,
      video: true,
      options: {
        muted: true,
        mirror: true,
      },
      elemId: "localVideoElem",
    };
    this.props.SetQuickbloxSession(session);
    session.stop({});
    session.getUserMedia(mediaParams, (error, stream) => {
      if (error) {
        this.props.SetOutgoingCallState(false);
        this.props.SetQuickbloxSession(null);
        session.stop({});
        console.log("Get user stream error => ", error);
      } else {
        //run call function here
        // session.attachMediaStream("localVideoElem", stream);
        if (stream) {
          const callee = JSON.parse(localStorage.getItem("QB_USER"));
          session.call({ callee: callee?.full_name }, (error1) => {
            playCallAudio();
            console.log(error1);
          });
        }
      }
    });
  };

  render() {
    const {
      followers,
      followings,
      userFollowersData,
      userFollowingsData,
      isLoading,
      isFollowingModalOpen,
      isFollowerModalOpen,
    } = this.state;
    return (
      <>
        {isLoading && (
          <div className="loader-wrapper" id="loader_img">
            <div className="loader">
              <img src={this.state.headerLogo} alt="" />
              <div className="material-spinner"></div>
            </div>
          </div>
        )}
        <div className="tab-pane active in this_subManage_Listings">
          <div className="subtab_prodlist_NEW subtab_prodlist_NEW_forpadd paddint_toppage06 ownprofilepage ">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-4 col-xl-3 subtab_firstmain paddingrigt_none_Cfilter"
                  style={{
                    zIndex: "1",
                    position: "static",
                    top: "auto",
                  }}
                >
                  <div className="product-sideBar-parent new_perenttop_forspecals myown_follow">
                    <div className="product-listing-sideBar product_listing_sideBar_mobile">
                      <div className="sidebarleft_newinner">
                        <div className="product-listing-sideBar-colum sideBar-location">
                          <div className="form-group mb-0 sideBar-choose-distance">
                            <div className="form-group-icon-bx">
                              <input
                                type="search"
                                name="search"
                                defaultValue={this.state.search}
                                onChange={(e) =>
                                  this.setState({ search: e.target.value })
                                }
                                placeholder="Search for . . ."
                                className="header-input"
                              />
                            </div>
                            <div className="search-btn">
                              <button onClick={() => this.getAllFollowers()}>
                                <img
                                  src="images/search-icon.png"
                                  alt="search-icon.png"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="right_owe_tabs ">
                          <ul
                            className="nav nav-tabs fororange_colorbg  new_ctab_inntab02 specialshap_likedetailP_innerT cuttan_newimg p-1 text-center  followers_tab_block"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className="number-reveal-btn nav-link active in"
                                data-toggle="tab"
                                href="#subtabowe01"
                                role="tab"

                              >

                                <span className="tab_title_track"
                                  onClick={() =>
                                    this.setState(
                                      { userView: "follower" },
                                      () => {
                                        this.getAllFollowers();
                                      }
                                    )
                                  }>My Followers </span>
                                <span className="followers_count"
                                  onClick={() =>
                                    this.setState(
                                      { userView: "follower" },
                                      () => {
                                        this.getAllFollowers();
                                        this.handleFollowerOpenModal();
                                      }
                                    )
                                  }>{userFollowersData?.length}</span>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="number-reveal-btn nav-link"
                                data-toggle="tab"
                                href="#subtabowe02"
                                role="tab"

                              >
                                <span className="tab_title_track"
                                  onClick={() =>
                                    this.setState(
                                      { userView: "following" },
                                      () => {
                                        this.getAllFollowers();
                                      }
                                    )
                                  }>My Following</span>
                                <span className="followers_count following" onClick={() =>
                                  this.setState(
                                    { userView: "following" },
                                    () => {
                                      this.getAllFollowers();
                                      this.handleFollowingOpenModal();
                                    }
                                  )
                                }>{userFollowingsData?.length}</span>
                              </a>
                            </li>
                          </ul>
                          <Modal
                            isOpen={isFollowerModalOpen}
                            onRequestClose={this.handleFollowerCloseModal}
                            contentLabel="Order Protection"
                            className="modal-content"
                            overlayClassName="modal-overlay followers_modal_pareant"
                          >
                            <div className="tab-content subtab_tabcont">
                              <div
                                className="tab-pane in this_subManage_Listings active "
                                id="subtabowe01"
                                role="tabpanel"
                              >
                                <div className="follow_list">
                                  <ul className="followers_modal">
                                    {isLoading ? (
                                      <li>Loading...</li>
                                    ) :
                                      followers && followers.length > 0 ? (
                                        followers.map((follower) => (
                                          <li
                                            key={`follower_${follower?._id}`}
                                            onClick={() =>
                                              this.getAllProfileDetails(
                                                follower?._id
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            <div className="li_block ">
                                              <div className="detail-owner-img">
                                                {follower?.profilePicture ? (
                                                  <img
                                                    src={General.showImageNew(
                                                      "profile",
                                                      follower?.profilePicture
                                                    )}
                                                    onError={(e) =>
                                                    (e.target.src =
                                                      "/images/user.png")
                                                    }
                                                    alt={follower?.profilePicture}
                                                    title={
                                                      follower?.firstName +
                                                      " " +
                                                      follower?.lastName
                                                    }
                                                  />
                                                ) : (
                                                  <img
                                                    src="/images/user.png"
                                                    alt={
                                                      follower?.firstName +
                                                      " " +
                                                      follower?.lastName
                                                    }
                                                    title={
                                                      follower?.firstName +
                                                      " " +
                                                      follower?.lastName
                                                    }
                                                  />
                                                )}
                                                {follower?.isOnline === true ? (
                                                  <span className="live-dot"></span>
                                                ) : (
                                                  <span
                                                    className="live-dot"
                                                    style={{
                                                      background:
                                                        "rgb(203, 211, 227)",
                                                    }}
                                                  ></span>
                                                )}
                                              </div>
                                              <div className="font-size-12">{follower?.firstName}</div>
                                            </div>
                                          </li>
                                        ))
                                      ) : (
                                        <li>No record found</li>
                                      )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Modal>
                          <Modal
                            isOpen={isFollowingModalOpen}
                            onRequestClose={this.handleFollowingCloseModal}
                            contentLabel="Order Protection"
                            className="modal-content"
                            overlayClassName="modal-overlay followers_modal_pareant"
                          >
                            <div className="tab-content subtab_tabcont">
                              <div
                                className="tab-pane  in this_subManage_Listings active"
                                id="subtabowe02"
                                role="tabpanel"
                              >
                                <div className="follow_list following">
                                  <ul className="followers_modal">
                                    {isLoading ? (
                                      <li>Loading...</li>
                                    ) : followings && followings.length > 0 ? (
                                      followings.map((following, index) => (
                                        <li
                                          onClick={() => {
                                            this.getAllProfileDetails(
                                              following._id
                                            );
                                          }}
                                          style={{ cursor: "pointer" }}
                                          key={`following_${index}_${following?._id}`}
                                        >
                                          <div className="detail-owner-img" onClick={this.handleFollowingCloseModal}>
                                            {following?.profilePicture ? (
                                              <img
                                                src={General.showImageNew(
                                                  "profile",
                                                  following?.profilePicture
                                                )}
                                                onError={(e) =>
                                                (e.target.src =
                                                  "/images/user.png")
                                                }
                                                alt={following?.profilePicture}
                                                title={
                                                  following?.firstName +
                                                  " " +
                                                  following?.lastName
                                                }
                                              />
                                            ) : (
                                              <img
                                                src="/images/user.png"
                                                alt={
                                                  following?.firstName +
                                                  " " +
                                                  following?.lastName
                                                }
                                                title={
                                                  following?.firstName +
                                                  " " +
                                                  following?.lastName
                                                }
                                              />
                                            )}
                                            {following?.isOnline === true ? (
                                              <span className="live-dot"></span>
                                            ) : (
                                              <span
                                                className="live-dot"
                                                style={{
                                                  background:
                                                    "rgb(203, 211, 227)",
                                                }}
                                              ></span>
                                            )}
                                          </div>
                                          <div onClick={this.handleFollowingCloseModal}>{following?.firstName}</div>
                                        </li>
                                      ))
                                    ) : (
                                      <li>No record found</li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Modal>
                          <div className="tab-content subtab_tabcont d-none d-md-block">
                            <div
                              className="tab-pane in this_subManage_Listings active "
                              id="subtabowe01"
                              role="tabpanel"
                            >
                              <div className="follow_list">
                                <ul>
                                  {followers && followers.length > 0 ? (
                                    followers.map((follower) => (
                                      <li
                                        key={`follower_${follower?._id}`}
                                        onClick={() =>
                                          this.getAllProfileDetails(
                                            follower?._id
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <div className="li_block">
                                          <div className="detail-owner-img">
                                            {follower?.profilePicture ? (
                                              <img
                                                src={General.showImageNew(
                                                  "profile",
                                                  follower?.profilePicture
                                                )}
                                                onError={(e) =>
                                                (e.target.src =
                                                  "/images/user.png")
                                                }
                                                alt={follower?.profilePicture}
                                                title={
                                                  follower?.firstName +
                                                  " " +
                                                  follower?.lastName
                                                }
                                              />
                                            ) : (
                                              <img
                                                src="/images/user.png"
                                                alt={
                                                  follower?.firstName +
                                                  " " +
                                                  follower?.lastName
                                                }
                                                title={
                                                  follower?.firstName +
                                                  " " +
                                                  follower?.lastName
                                                }
                                              />
                                            )}
                                            {follower?.isOnline === true ? (
                                              <span className="live-dot"></span>
                                            ) : (
                                              <span
                                                className="live-dot"
                                                style={{
                                                  background:
                                                    "rgb(203, 211, 227)",
                                                }}
                                              ></span>
                                            )}
                                          </div>
                                          <div className="font-size-12">{follower?.firstName}</div>
                                        </div>
                                      </li>
                                    ))
                                  ) : (
                                    <li>No record found</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div
                              className="tab-pane  in this_subManage_Listings"
                              id="subtabowe02"
                              role="tabpanel"
                            >
                              <div className="follow_list following">
                                <ul>
                                  {followings && followings.length > 0 ? (
                                    followings.map((following, index) => (
                                      <li
                                        onClick={() =>
                                          this.getAllProfileDetails(
                                            following?._id
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                        key={`following_${index}_${following?._id}`}
                                      >
                                        <div className="detail-owner-img">
                                          {following?.profilePicture ? (
                                            <img
                                              src={General.showImageNew(
                                                "profile",
                                                following?.profilePicture
                                              )}
                                              onError={(e) =>
                                              (e.target.src =
                                                "/images/user.png")
                                              }
                                              alt={following?.profilePicture}
                                              title={
                                                following?.firstName +
                                                " " +
                                                following?.lastName
                                              }
                                            />
                                          ) : (
                                            <img
                                              src="/images/user.png"
                                              alt={
                                                following?.firstName +
                                                " " +
                                                following?.lastName
                                              }
                                              title={
                                                following?.firstName +
                                                " " +
                                                following?.lastName
                                              }
                                            />
                                          )}
                                          {following?.isOnline === true ? (
                                            <span className="live-dot"></span>
                                          ) : (
                                            <span
                                              className="live-dot"
                                              style={{
                                                background:
                                                  "rgb(203, 211, 227)",
                                              }}
                                            ></span>
                                          )}
                                        </div>
                                        <div>{following?.firstName}</div>
                                      </li>
                                    ))
                                  ) : (
                                    <li>No record found</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 mt-3 mt-lg-0">
                  {this.state.userView === "follower" &&
                    userFollowersData &&
                    userFollowersData.map((userdetail) => (
                      <div
                        className="max_width"
                        key={`top_follower_user_${userdetail?.user._id}`}
                      >
                        {!userdetail?.products &&
                          typeof userdetail.products === "undefined" ? (
                          <div className="col-lg-12 listside_newperent">
                            <div
                              className=" top_rated_sec  onlypage06 px-4 py-2 mb-2 myowe_sec myowe_sec_box "
                              key={`follower_users_${userdetail?.user._id}`}
                            >
                              <div className="row mb-2">
                                <div className="col-lg-5">
                                  <div className="clearfix pl-0 top_bar">
                                    <div className="livestriming_lsec page-6striming  d-md-none">
                                      <div className="chat_icon">
                                        <a
                                          href={void 0}
                                          className="number-reveal-btn"
                                          onClick={this.sendMessageHandler}
                                        >
                                          {" "}
                                          <i className="far fa-comments"></i>
                                          <span className="font-size-12">ChatNow</span>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="detail-owner-img">
                                      {userdetail?.user?.profilePicture ? (
                                        <img
                                          src={General.showImageNew(
                                            "profile",
                                            userdetail?.user?.profilePicture
                                          )}
                                          onError={(e) =>
                                            (e.target.src = "/images/user.png")
                                          }
                                          alt={userdetail?.user?.profilePicture}
                                        />
                                      ) : (
                                        <img
                                          src="/images/user.png"
                                          alt="image1"
                                        />
                                      )}
                                      {userdetail?.user?.isOnline === true ? (
                                        <span className="live-dot"></span>
                                      ) : (
                                        <span
                                          className="live-dot"
                                          style={{
                                            background: "rgb(203, 211, 227)",
                                          }}
                                        ></span>
                                      )}
                                    </div>
                                    <div className="detail-owner-name">
                                      <div className="rated-star">
                                        <span className="font-size-14">Rated </span>
                                        <span className="font-size-14">
                                          <strong>
                                            {userdetail.avgRating
                                              ? userdetail.avgRating.toFixed(1)
                                              : 0.0}
                                          </strong>
                                        </span>
                                        <StarRatings
                                          rating={
                                            userdetail.avgRating
                                              ? userdetail.avgRating
                                              : 0
                                          }
                                          starRatedColor="#249ee4"
                                          starDimension="20px"
                                          starSpacing="0"
                                          numberOfStars={5}
                                        />
                                      </div>
                                      <h2 className="font-size-16">{userdetail?.user?.companyName}</h2>
                                      <p className="font-size-14">
                                        {userdetail?.user?.firstName +
                                          " " +
                                          userdetail?.user?.lastName}
                                      </p>
                                      <div className="usednew_button position-relative pl-3">
                                        {userdetail.isFollowing ? (
                                          <Link
                                            to="#!"
                                            className="btnnew_used font-size-14"
                                            onClick={() =>
                                              this.unfollowHandler(
                                                userdetail.user._id
                                              )
                                            }
                                          >
                                            unfollow
                                          </Link>
                                        ) : (
                                          <Link
                                            to="#!"
                                            className="btnnew_used font-size-14"
                                            onClick={() =>
                                              this.followHandler(
                                                userdetail.user._id
                                              )
                                            }
                                          >
                                            follow
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-7">
                                  <div className="Jvideo_cus_perent">
                                    <div className="rated-customer-bx">
                                      <div className="rated-customer-bx-inner ">
                                        <span className="font-size-14">Member Since{" "}
                                          <Moment format="MMM YYYY">
                                            {userdetail?.user?.date}
                                          </Moment>
                                        </span>

                                        {userdetail?.productsLenght &&
                                          userdetail?.productsLenght > 0 && (
                                            <>
                                              <div className="all_product_mobile">
                                                <h2 className="font-size-14">
                                                  {userdetail?.productsLenght}{" "}
                                                  Listed items
                                                </h2>

                                                <Link
                                                  to={`/user/all-products/${userdetail?.user?._id}`}
                                                  className="see-all-btn"
                                                >
                                                  All items{" "}
                                                  <i className="fas fa-arrow-right"></i>
                                                </Link>
                                              </div>
                                            </>
                                          )}
                                        {userdetail.user.lastLogin && (
                                          <p>
                                            <span className="or_text">
                                              Last Seen:
                                            </span>{" "}
                                            <Moment fromNow>
                                              {userdetail?.user?.lastLogin}
                                            </Moment>
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="livestriming_lsec page-6striming d-none d-md-block">
                                      <div className="chat_icon">
                                        <a
                                          href={void 0}
                                          className="number-reveal-btn"
                                          onClick={this.sendMessageHandler}
                                        >
                                          {" "}
                                          <i className="far fa-comments"></i>
                                          <span>ChatNow</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            {userdetail.products.map((product) => (
                              <div
                                className="col-lg-12 listside_newperent"
                                key={`followers_product_${product?._id}`}
                              >
                                <div
                                  className=" top_rated_sec onlypage06 bb-0 px-4 py-2 mb-0 myowe_sec"
                                  key={`follower_user_${userdetail?.user._id}`}
                                >
                                  <div className="row mb-2">
                                    <div className="col-lg-5">
                                      <div className="detail-owner-name-bx clearfix pl-0 myfollowersBox">
                                        <div className="detail-owner-img">
                                          {userdetail?.user?.profilePicture ? (
                                            <img
                                              src={General.showImageNew(
                                                "profile",
                                                userdetail?.user?.profilePicture
                                              )}
                                              onError={(e) =>
                                              (e.target.src =
                                                "/images/user.png")
                                              }
                                              alt={
                                                userdetail?.user?.profilePicture
                                              }
                                            />
                                          ) : (
                                            <img
                                              src="/images/user.png"
                                              alt="image1"
                                            />
                                          )}
                                          {userdetail?.user?.isOnline === true ? (
                                            <span className="live-dot"></span>
                                          ) : (
                                            <span
                                              className="live-dot"
                                              style={{
                                                background:
                                                  "rgb(203, 211, 227)",
                                              }}
                                            ></span>
                                          )}
                                        </div>
                                        <div className="detail-owner-name">
                                          <div className="rated-star">
                                            <span>Rated </span>
                                            <span>
                                              <strong>
                                                {userdetail.avgRating
                                                  ? userdetail.avgRating.toFixed(
                                                    1
                                                  )
                                                  : 0.0}
                                              </strong>
                                            </span>
                                            <StarRatings
                                              rating={
                                                userdetail.avgRating
                                                  ? userdetail.avgRating
                                                  : 0
                                              }
                                              starRatedColor="#249ee4"
                                              starDimension="20px"
                                              starSpacing="0"
                                              numberOfStars={5}
                                            />
                                          </div>
                                          <h2>
                                            {userdetail?.user?.companyName}
                                          </h2>
                                          <p>
                                            {userdetail?.user?.firstName +
                                              " " +
                                              userdetail?.user?.lastName}
                                          </p>
                                          <div className="usednew_button position-relative pl-3">
                                            {userdetail.isFollowing ? (
                                              <Link
                                                to="#!"
                                                className="btnnew_used"
                                                onClick={() =>
                                                  this.unfollowHandler(
                                                    userdetail.user._id
                                                  )
                                                }
                                              >
                                                unfollow
                                              </Link>
                                            ) : (
                                              <Link
                                                to="#!"
                                                className="btnnew_used"
                                                onClick={() =>
                                                  this.followHandler(
                                                    userdetail.user._id
                                                  )
                                                }
                                              >
                                                follow
                                              </Link>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-7">
                                      <div className="Jvideo_cus_perent">
                                        <div className="rated-customer-bx">
                                          <div className="rated-customer-bx-inner">
                                            Member Since{" "}
                                            <Moment format="MMM YYYY">
                                              {userdetail?.user?.date}
                                            </Moment>
                                            {userdetail?.productsLenght &&
                                              userdetail?.productsLenght >
                                              0 && (
                                                <>
                                                  <h2>
                                                    {userdetail?.productsLenght}{" "}
                                                    Listed Items
                                                  </h2>
                                                  <Link
                                                    to={`/user/all-products/${userdetail?.user?._id}`}
                                                    className="see-all-btn"
                                                  >
                                                    All Items{" "}
                                                    <i className="fas fa-arrow-right"></i>
                                                  </Link>
                                                </>
                                              )}
                                            {userdetail.user.lastLogin && (
                                              <p>
                                                <span className="or_text">
                                                  Last Seen:
                                                </span>{" "}
                                                <Moment fromNow>
                                                  {userdetail?.user?.lastLogin}
                                                </Moment>
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                        <div className="livestriming_lsec page-6striming">
                                          <div className="chat_icon">
                                            <a
                                              href={void 0}
                                              className="number-reveal-btn"
                                              onClick={this.sendMessageHandler}
                                            >
                                              {" "}
                                              <i className="far fa-comments"></i>
                                              <span>ChatNow</span>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="product-listing-product cardImg">
                                  <div className="row">
                                    <div className="col-12 col-md-12">
                                      <div className="product-img-bx p-0">
                                        {product.adType &&
                                          product.adType === "Featured" && (
                                            <div className="specialtags">
                                              <span
                                                className={
                                                  (product.adType === "Featured"
                                                    ? "black"
                                                    : "green") + "_special"
                                                }
                                              >
                                                {product.adType}{" "}
                                              </span>
                                            </div>
                                          )}
                                        {product.productImages &&
                                          product.productImages.length > 0 ? (
                                          <div className="fancy">
                                            <div className="product-detailslider sliderBox">
                                              <OwlCarousel
                                                className="owl-theme"
                                                margin={20}
                                                nav
                                                items={1}
                                                dots={false}
                                                responsive={
                                                  this.state.responsive1
                                                }
                                              >
                                                {product.productImages ? (
                                                  product.productImages.map(
                                                    (image, index) => (
                                                      <a
                                                        className={
                                                          index === 0 ? "" : "d-none"
                                                        }
                                                        key={index}
                                                        data-fancybox={`product-follow-${product._id}`}
                                                        href={General.showImage(
                                                          "products/resize-566-400",
                                                          image
                                                        )}
                                                      >
                                                        <img
                                                          src={General.showImage(
                                                            "products/resize-566-400",
                                                            image
                                                          )}
                                                          onError={(e) =>
                                                          (e.target.src =
                                                            "/images/no-image.jpg")
                                                          }
                                                          alt={product.productName}
                                                        />
                                                      </a>
                                                    )
                                                  )
                                                ) : (
                                                  <img
                                                    src="/images/no-image.jpg"
                                                    alt=""
                                                  />
                                                )}
                                              </OwlCarousel>
                                            </div>
                                          </div>
                                        ) : (
                                          <img
                                            src="/images/no-image.jpg"
                                            alt="product"
                                          />
                                        )}
                                        <div className="cardImgBtn">
                                          <div className="totalimg_sectab_">
                                            {product?.productLikeUser ? (
                                              <div className="text_col_blue">
                                                <a
                                                  href={void 0}
                                                  onClick={() =>
                                                    this.addWishlist(
                                                      product._id
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className="fa fa-thumbs-up"
                                                    aria-hidden="true"
                                                  ></i>{" "}
                                                  Like{" "}
                                                </a>
                                              </div>
                                            ) : (
                                              <div className="text_col_grey">
                                                <a
                                                  href={void 0}
                                                  onClick={() =>
                                                    this.addWishlist(
                                                      product._id
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className="fa fa-thumbs-up"
                                                    aria-hidden="true"
                                                  ></i>{" "}
                                                  Like{" "}
                                                </a>
                                              </div>
                                            )}
                                          </div>
                                          <div className="like_sec_ text_col_grey">
                                            <span>
                                              {product.like_count} Like{" "}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    ))}

                  {this.state.userView === "following" &&
                    userFollowingsData &&
                    userFollowingsData.map((userdetail) => (
                      <div
                        className="max_width"
                        key={`top_following_user_${userdetail?.user._id}`}
                      >
                        {!userdetail?.products &&
                          typeof userdetail.products === "undefined" ? (
                          <div className="col-lg-12 listside_newperent">
                            <div
                              className=" top_rated_sec onlypage06 px-4 py-2 mb-2 myowe_sec"
                              key={`following_users_${userdetail?.user._id}`}
                            >
                              <div className="row mb-2">
                                <div className="col-lg-5">
                                  <div className="detail-owner-name-bx clearfix pl-0">
                                    <div className="detail-owner-img">
                                      {userdetail?.user?.profilePicture ? (
                                        <img
                                          src={General.showImageNew(
                                            "profile",
                                            userdetail?.user?.profilePicture
                                          )}
                                          onError={(e) =>
                                            (e.target.src = "/images/user.png")
                                          }
                                          alt={userdetail?.user?.profilePicture}
                                        />
                                      ) : (
                                        <img
                                          src="/images/user.png"
                                          alt="image1"
                                        />
                                      )}
                                      {userdetail?.user?.isOnline === true ? (
                                        <span className="live-dot"></span>
                                      ) : (
                                        <span
                                          className="live-dot"
                                          style={{
                                            background:
                                              "rgb(203, 211, 227)",
                                          }}
                                        ></span>
                                      )}
                                    </div>
                                    <div className="detail-owner-name">
                                      <div className="rated-star">
                                        <span>Rated </span>
                                        <span>
                                          <strong>
                                            {userdetail.avgRating
                                              ? userdetail.avgRating.toFixed(1)
                                              : 0.0}
                                          </strong>
                                        </span>
                                        <StarRatings
                                          rating={
                                            userdetail.avgRating
                                              ? userdetail.avgRating
                                              : 0
                                          }
                                          starRatedColor="#249ee4"
                                          starDimension="20px"
                                          starSpacing="0"
                                          numberOfStars={5}
                                        />
                                      </div>
                                      <h2>{userdetail?.user?.companyName}</h2>
                                      <p>
                                        {userdetail?.user?.firstName +
                                          " " +
                                          userdetail?.user?.lastName}
                                      </p>
                                      <div className="usednew_button position-relative pl-3">
                                        {userdetail.isFollowing ? (
                                          <Link
                                            to="#!"
                                            className="btnnew_used"
                                            onClick={() =>
                                              this.unfollowHandler(
                                                userdetail.user._id
                                              )
                                            }
                                          >
                                            unfollow
                                          </Link>
                                        ) : (
                                          <Link
                                            to="#!"
                                            className="btnnew_used"
                                            onClick={() =>
                                              this.followHandler(
                                                userdetail.user._id
                                              )
                                            }
                                          >
                                            follow
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-7">
                                  <div className="Jvideo_cus_perent ">
                                    <div className="rated-customer-bx">
                                      <div className="rated-customer-bx-inner">
                                        Member Since{" "}
                                        <Moment format="MMM YYYY">
                                          {userdetail?.user?.date}
                                        </Moment>
                                        {userdetail?.productsLenght &&
                                          userdetail?.productsLenght > 0 && (
                                            <>
                                              <h2>
                                                {userdetail?.productsLenght}{" "}
                                                Listed Items
                                              </h2>
                                              <Link
                                                to={`/user/all-products/${userdetail?.user?._id}`}
                                                className="see-all-btn font-size-14"
                                              >
                                                All Items{" "}
                                                <i className="fas fa-arrow-right"></i>
                                              </Link>
                                            </>
                                          )}
                                        {userdetail.user.lastLogin && (
                                          <p>
                                            <span className="or_text">
                                              Last Seen:
                                            </span>{" "}
                                            <Moment fromNow>
                                              {userdetail?.user?.lastLogin}
                                            </Moment>
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="livestriming_lsec page-6striming">
                                      <div className="chat_icon">
                                        <a
                                          href={void 0}
                                          className="number-reveal-btn"
                                          onClick={this.sendMessageHandler}
                                        >
                                          {" "}
                                          <i className="far fa-comments"></i>
                                          <span>ChatNow</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            {userdetail.products.map((product) => (
                              <div
                                className="col-lg-12 listside_newperent"
                                key={`following_product_${product._id}`}
                              >
                                <div
                                  className="top_rated_sec onlypage06 bb-0 mb-0 px-4 py-2 myowe_sec myowe_sec_box"
                                  key={`following_user_${userdetail?.user._id}`}
                                >
                                  <div className="row mb-2">
                                    <div className="col-lg-5">
                                      <div className=" clearfix pl-0 top_bar">
                                        <div className=" livestriming_lsec chat_icon d-md-none">
                                          <a
                                            href={void 0}
                                            className="number-reveal-btn"
                                            onClick={this.sendMessageHandler}
                                          >
                                            <i className="far fa-comments"></i>{" "}
                                            <span>Chat Now</span>
                                          </a>
                                        </div>
                                        <div className="detail-owner-img">
                                          {userdetail?.user?.profilePicture ? (
                                            <img
                                              src={General.showImageNew(
                                                "profile",
                                                userdetail?.user?.profilePicture
                                              )}
                                              onError={(e) =>
                                              (e.target.src =
                                                "/images/user.png")
                                              }
                                              alt={
                                                userdetail?.user?.profilePicture
                                              }
                                            />
                                          ) : (
                                            <img
                                              src="/images/user.png"
                                              alt="image1"
                                            />
                                          )}
                                          {userdetail?.user?.isOnline === true ? (
                                            <span className="live-dot"></span>
                                          ) : (
                                            <span
                                              className="live-dot"
                                              style={{
                                                background:
                                                  "rgb(203, 211, 227)",
                                              }}
                                            ></span>
                                          )}
                                        </div>
                                        <div className="detail-owner-name">
                                          <div className="rated-star">
                                            <span>Rated </span>
                                            <strong>
                                              {userdetail.avgRating
                                                ? userdetail.avgRating.toFixed(
                                                  1
                                                )
                                                : 0.0}
                                            </strong>
                                            <StarRatings
                                              rating={userdetail.avgRating || 0}
                                              starRatedColor="#249ee4"
                                              starDimension="20px"
                                              starSpacing="0"
                                              numberOfStars={5}
                                            />
                                          </div>
                                          <h2>
                                            {userdetail?.user?.companyName}
                                          </h2>
                                          <p>{`${userdetail?.user?.firstName} ${userdetail?.user?.lastName}`}</p>
                                          <div className="usednew_button position-relative pl-3">
                                            {userdetail.isFollowing ? (
                                              <Link
                                                to="#!"
                                                className="btnnew_used"
                                                onClick={() =>
                                                  this.unfollowHandler(
                                                    userdetail.user._id
                                                  )
                                                }
                                              >
                                                Unfollow
                                              </Link>
                                            ) : (
                                              <Link
                                                to="#!"
                                                className="btnnew_used"
                                                onClick={() =>
                                                  this.followHandler(
                                                    userdetail.user._id
                                                  )
                                                }
                                              >
                                                Follow
                                              </Link>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-7">
                                      <div className="Jvideo_cus_perent Jvideo_cus_block ">
                                        <div className="rated-customer-bx">
                                          <div className="rated-customer-bx-inner">
                                            Member Since{" "}
                                            <Moment format="MMM YYYY">
                                              {userdetail?.user?.date}
                                            </Moment>
                                            {userdetail?.productsLenght}
                                            {userdetail?.productsLenght &&
                                              userdetail?.productsLenght >
                                              0 && (
                                                <>
                                                  <h2>
                                                    {userdetail?.productsLenght}{" "}
                                                    Listed Items
                                                  </h2>
                                                  <Link
                                                    to={`/user/all-products/${userdetail?.user?._id}`}
                                                    className="see-all-btn font-size-14"
                                                  >
                                                    All Items{" "}
                                                    <i className="fas fa-arrow-right"></i>
                                                  </Link>
                                                </>
                                              )}
                                            {userdetail.user.lastLogin && (
                                              <p>
                                                <span className="or_text">
                                                  Last Seen:
                                                </span>{" "}
                                                <Moment fromNow>
                                                  {userdetail?.user?.lastLogin}
                                                </Moment>
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                        <div className="livestriming_lsec page-6striming Jvideochat_cus">
                                          <div className="chat_icon d-none d-md-block">
                                            <a
                                              href={void 0}
                                              className="number-reveal-btn"
                                              onClick={this.sendMessageHandler}
                                            >
                                              <i className="far fa-comments"></i>{" "}
                                              <span>Chat Now</span>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="product-listing-product cardImg">
                                  <div className="row">
                                    <div className="col-12 col-md-12">
                                      <div className="product-img-bx p-0">
                                        {product.adType &&
                                          product.adType === "Featured" && (
                                            <div className="specialtags">
                                              <span
                                                className={`${product.adType === "Featured"
                                                  ? "black"
                                                  : "green"
                                                  }_special`}
                                              >
                                                {product.adType}
                                              </span>
                                            </div>
                                          )}
                                        {product.productImages &&
                                          product.productImages.length > 0 ? (
                                          <div className="fancy">
                                            <div className="product-detailslider sliderBox">
                                              <OwlCarousel
                                                className="owl-theme"
                                                margin={20}
                                                nav
                                                items={1}
                                                dots={false}
                                                responsive={
                                                  this.state.responsive1
                                                }
                                              >
                                                {product.productImages ? (
                                                  product.productImages.map(
                                                    (image, index) => (
                                                      <a
                                                        className={
                                                          index === 0 ? "" : "d-none"
                                                        }
                                                        key={index}
                                                        data-fancybox={`product-following-${product._id}`}
                                                        href={General.showImage(
                                                          "products/resize-566-400",
                                                          image
                                                        )}
                                                      >
                                                        <img
                                                          src={General.showImage(
                                                            "products/resize-566-400",
                                                            image
                                                          )}
                                                          onError={(e) =>
                                                          (e.target.src =
                                                            "/images/no-image.jpg")
                                                          }
                                                          alt={product.productName}
                                                        />
                                                      </a>
                                                    )
                                                  )
                                                ) : (
                                                  <img
                                                    src="/images/no-image.jpg"
                                                    alt=""
                                                  />
                                                )}
                                              </OwlCarousel>
                                            </div>
                                          </div>
                                        ) : (
                                          <img
                                            src="/images/no-image.jpg"
                                            alt="product"
                                          />
                                        )}
                                        <div className="cardImgBtn">
                                          <div className="totalimg_sectab_">
                                            <div
                                              className={
                                                product.productLikeUser
                                                  ? "text_col_blue"
                                                  : "text_col_grey"
                                              }
                                            >
                                              <a
                                                href={void 0}
                                                onClick={() =>
                                                  this.addWishlist(product._id)
                                                }
                                              >
                                                <i
                                                  className="fa fa-thumbs-up"
                                                  aria-hidden="true"
                                                ></i>{" "}
                                                Like
                                              </a>
                                            </div>
                                          </div>
                                          <div className="like_sec_ text_col_grey">
                                            <span>
                                              {product.like_count} Like
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, {
  OpenMessageBox,
  SetOutgoingCallState,
  SetQuickbloxSession,
})(MyOwnLevelComponent);
