import React, { Component } from "react";
import Product from "Models/Product";
import SubCategoryD from "Models/SubCategory";
import General from "Helpers/General";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Countdown from "react-countdown";
import MyProfile from "Models/MyProfile";
import Category from "Models/Category";
import Modal from "react-modal";
import User from "Models/User";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Business } from "@material-ui/icons";
var qs = require("qs");

class PostAnAdCategoryComponent extends Component {
  constructor(props) {

    super(props);

    if (!User.isLogin()) {
      User.redirectTo("");
    }

    let code_token = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).code;

    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      MainCategories: localStorage.getItem("MainCategories")
        ? JSON.parse(localStorage.getItem("MainCategories"))
        : [],
      PostCategories: localStorage.getItem("PostCategories")
        ? JSON.parse(localStorage.getItem("PostCategories"))
        : [],
      PostsubCategoriesByCAT: [],
      PostsubCategories: [],
      selectedSubCatId: "",
      showFirstCat: true,
      childPostSubCategories: [],
      firstName: "",
      lastName: "",
      sellerType: 0,
      code_token: (code_token) ? code_token : "",
      stripe_account_id: "",
      // sellerType : localStorage.getItem("UserData")
      // ? JSON.parse(localStorage.getItem("UserData"))["sellerType"]
      // : 0,
      showSellerTypeModel: false,
      showBusinessTypeModel: false,
      businessType: 0,
      /*  businessType : localStorage.getItem("UserData")
       ? JSON.parse(localStorage.getItem("UserData"))["businessType"]
       : 0, */
      stripe_url: "",
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    var self = this;
    let stripe_code = this.state.code_token;
    if (stripe_code) {

      self.setState({ isLoading: true });
      const stripeRespo = await this.saveStripeAccountDetails(stripe_code);

      if (stripeRespo.status === "success") {
        Product.redirectTo("post-an-ad");
      } else {
        this.setState({ isLoading: false });
      }
    }

    this.getUserByEmail();
    Category.getApi(
      "APIS_ALL_CATEGORIES_DATA",
      async (response) => {
        self.fetchSubCategores(response[0]._id);
        localStorage.setItem("MainCategories", JSON.stringify(response));
        self.setState({ MainCategories: response });
      },
      false
    );
    Category.getApi(
      "APIS_ALL_CATEGORIES",
      async (response) => {
        localStorage.setItem("PostCategories", JSON.stringify(response));
        self.setState({ PostCategories: response });
      },
      false
    );
  };

  // Function to save Stripe account details
  saveStripeAccountDetails = (stripe_code) => {
    return new Promise((resolve) => {
      MyProfile.postApi(
        "SAVE_STRIPE_ACCOUNT_DETAILS",
        { code: stripe_code },
        (response, status) => {
          console.log("stripe")
          console.log(response, status);
          resolve({ 'status': status });
        },
        true
      );
    });
  };

  getUserByEmail = () => {
    MyProfile.postApi(
      "API_GET_USER_BY_EMAIL",
      { email: localStorage.getItem("username") },
      async (response) => {
        if (response) {
          this.setState({
            firstName: response.firstName,
            lastName: response.lastName,
            stripe_account_id: response.stripe_account_id,
            //sellerType : response.sellerType, 
            //businessType : response.businessType,
          });
          // console.log("abc",response.stripe_account_id)
          if (!response.stripe_account_id) {
            this.setState({
              showSellerTypeModel: true,
            });
          } else if (response.businessType == 0 && response.stripe_account_id != null && response.stripe_account_id) {
            this.setState({
              showBusinessTypeModel: true,
            });
          }
        }
      },
      true
    );
  };

  fetchSubCategores(categoryId) {
    this.setState({ childPostSubCategories: [] });
  }

  fetchChildCategories(subCategoryId) {
    var self = this;
    self.setState({ isLoading: true });
    SubCategoryD.getApiByParams(
      "APIS_ALL_CHILD_SUB_CATEGORIES",
      subCategoryId,
      async (response) => {
        self.setState({ childPostSubCategories: response, isLoading: false });
      },
      false
    );
  }

  handleSubmitStripe = () => {
    this.setState({ isLoading: true, showSellerTypeModel: false });
    let stripeUrl = process.env.REACT_APP_STRIPE_AUTH_URL;
    let email = localStorage.getItem("username");
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let frontUrl = process.env.REACT_APP_FRONTEND_URL;
    let redirectUrl = `${frontUrl}/post-an-ad`;
    let clientId = process.env.REACT_APP_STRIPE_CLIENT_ID;
    window.location.href = `${stripeUrl}?response_type=code&client_id=${clientId}&scope=read_write&stripe_user[email]=${email}&stripe_user[first_name]=${firstName}&stripe_user[last_name]=${lastName}&redirect_uri=${redirectUrl}`;
  };

  submitBusinessTypeDetail = async (event) => {
    const { businessType } = this.state;
    if (
      (await MyProfile.checkValidation(await MyProfile.validationRules(), {
        businessType
      })) === true
    ) {
      this.setState({ isLoading: true });
      MyProfile.postApi(
        "API_UPDATE_ACCOUNT_DETAIL",
        {
          businessType,
        },
        async (response, err) => {
          if (response) {
            this.setState({ isLoading: false, showBusinessTypeModel: false });
            //Product.redirectTo("post-an-ad");
          }
        },
        true
      );
    }
  };

  onChangeInAutocomplete = (event, value) => {
    // SubCategoryD.getApi(
    //   "APIS_ALL_SUB_CATEGORIES_AUTOCOMPLETE",
    //   async (response) => {
    //     this.setState({ PostsubCategories: response?.catData });
    //   },
    //   {params : { searchText : value } },
    //   false
    // );
    SubCategoryD.postApi(
      "SEARCH_AUTO_CATEGORY_DATA",
      {
        searchWord: value,
      },
      async (response) => {
        console.log(response);
        this.setState({ PostsubCategories: response });
      },
      true
    );
  };

  render() {
    var context = this;
    const {
      childPostSubCategories,
      PostsubCategoriesByCAT,
      fetchChildCategories,
      PostCategories,
      showFirstCat,
      showSellerTypeModel,
      showBusinessTypeModel,
      isLoading,
    } = this.state;
    console.log("PostsubCategories", this.state.PostsubCategories);
    const renderOption = (option) => {
      return (
        <div className="normal">
          <span className="text">{option.name} - </span>
          <span className="text-color-sky"> {((option?.parentName) ? option?.parentName : '')}</span>
        </div>
      );
    };

    return (
      <React.Fragment>
        {isLoading && (
          <div className="loader-wrapper">
            <div className="loader">
              <img src={this.state.headerLogo} alt="" />
              <div className="material-spinner"></div>
            </div>
          </div>
        )}
        <section className="product-header bg-light-dark">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="breadcrumb-bx">
                  <div className="breadcrumb-bx-inner">
                    <Link to="/" className="btnx btn-primaryx">
                      <i className="fas fa-long-arrow-alt-left"></i> Back
                    </Link>
                    <ol className="breadcrumb mb-0 ml-3">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">List an Item</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="repeat-section postanadd_main_ofallsec bg-light-dark padding_bottom150">
          <div className="container">
            <h4 className="addpost_titl_perent">List an Item</h4>
            <div className="addaadd">
              <h3>Category</h3>
              <div className="row">
                <div className="col-sm-12">
                  <Autocomplete
                    onChange={(event, value) =>
                      this.setState({ selectedSubCatId: value.slug })
                    }
                    onInputChange={(event, value) => this.onChangeInAutocomplete(event, value)}
                    id="combo-box-demo"
                    options={this.state.PostsubCategories}
                    getOptionLabel={(option) => option?.name || ""}
                    renderOption={renderOption}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tell us what you want to sell . . ."
                      />
                    )}
                  />
                </div>
                {this.state.selectedSubCatId && (
                  <div className="col-sm-12">
                    <div className="countinu_first text-right">
                      <Link
                        // Link
                        to={"post-an-ad/" + this.state.selectedSubCatId}
                        className="see-all-btn"
                      >
                        Continue <i className="fas fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <h6 className="ortitl_midd">or</h6>
            <div className="bfind_category">
              <h3>Browse to find a category</h3>
              <div className="bfind_buttons">
                <ul className="listtbtn_typ nav categoryBox account_setting_tabs ">
                  {this.state.MainCategories.map(function (row, index) {
                    return (
                      row.recordType == "Dynamic" && (
                        <li
                          onClick={context.fetchSubCategores.bind(
                            context,
                            row._id
                          )}
                          key={"MainCategories" + row._id}
                        >
                          <a
                            className={index == 0 ? "" : ""}
                            data-toggle="tab"
                            href={"#" + row.slug}
                          >
                            {row.categoryName}
                          </a>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
              <div className="tab-content">
                {PostCategories.map(function (row, index) {
                  return (
                    <div
                      id={row.slug}
                      key={index}
                      className={index == 0 ? "tab-pane fade" : "tab-pane fade"}
                    >
                      <div className="bcate_box">
                        <div
                          className="bcatelist_bx mCustomScrollbar"
                          data-mcs-theme="dark"
                        >
                          <ul className="bcatlist_li" id="">
                            {row.subCatData.map(function (rowss, index) {
                              return rowss.childCatData.length ? (
                                <li
                                  key={rowss._id}
                                  onClick={context.fetchChildCategories.bind(

                                    context,
                                    rowss._id
                                  )}
                                >
                                  <a href={void (0)}>
                                    {rowss.subCategoryName}
                                  </a>
                                </li>
                              ) : (
                                <li key={rowss._id}>
                                  {/* <Link to={"post-an-ad/" + rowss._id}> */}
                                  <Link to={"post-an-ad/" + rowss.slug}>
                                    {rowss.subCategoryName}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div
                          className="bcatelist_bx mCustomScrollbar cusotm-scroll"
                          data-mcs-theme="dark"
                        >
                          <ul className="bcatlist_li">
                            {childPostSubCategories.map(function (
                              rowss,
                              index
                            ) {
                              return (
                                <li key={rowss._id}>
                                  {/* <Link to={"post-an-ad/" + rowss._id}> */}
                                  <Link to={"post-an-ad/" + rowss.slug}>
                                    {rowss.subCategoryName}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        <Modal
          isOpen={showSellerTypeModel}
          ariaHideApp={false}
          onRequestClose={() =>
            this.setState({ showSellerTypeModel: false })
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
            },
          }}
          className="shipModal"
        >
          <button onClick={() => this.setState({ showSellerTypeModel: false })} className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>
          <div
            className="col-lg-12 listside_newperent"
          >
            <div className="formnotification_inner withbg_white border-radius">
              <div className="col-12">
                <div className="logo-bx modal-title">
                  {" "}
                  <a href="/">
                    {" "}
                    <img
                      src={this.state.headerLogo}
                      alt="logo.png"
                      width="250px"
                    />{" "}
                  </a>{" "}
                </div>
              </div>
              <p className="popup-info info-header">Setup your wallet and take control of your payment to start selling.</p>
              <p className="popup-info">It's free to list - you will need to activate your Wallet/Balance to receive and transfer your fund.</p>
              <p className="By-continu-title">By continuing your agree to our <a href="/term-conditions">Terms of Service</a></p>
              <div>
                <button
                  className="btnx btn-secondaryx d-block mx-auto mt-3"
                  onClick={() => this.handleSubmitStripe()}
                >
                  Setup Stripe Account
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={showBusinessTypeModel}
          ariaHideApp={false}
          onRequestClose={() =>
            this.setState({ showBusinessTypeModel: false })
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
            },
          }}
          className="shipModal"
        >
          <button onClick={() => this.setState({ showBusinessTypeModel: false })} className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>
          <div
            className="col-lg-12 listside_newperent"
          >
            <div className="formnotification_inner withbg_white border-radius">
              <div className="col-12">
                <div className="logo-bx modal-title">
                  {" "}
                  <a href="/">
                    {" "}
                    <img
                      src={this.state.headerLogo}
                      alt="logo.png"
                      width="250px"
                    />{" "}
                  </a>{" "}
                </div>
              </div>

              <div>
                <div className="mt-3 onlyformob_seller">
                  <p className="popup-info info-header">Please select your account type on stripe</p>
                  <ul className="businesstype">
                    <li>
                      <div className="check-box big">
                        <input
                          className="form-control-line New_control"
                          type="radio"
                          value="2"
                          onChange={() => this.setState({ businessType: 1 })}
                          name="sellerType"
                          id="Company"

                        />
                        <label htmlFor="Company">Company</label>
                      </div>
                    </li>
                    <li>
                      <div className="check-box big">
                        <input
                          className="form-control-line New_control"
                          type="radio"
                          value="2"
                          onChange={() => this.setState({ businessType: 2 })}
                          name="sellerType"
                          id="Soletrader"

                        />
                        <label htmlFor="Soletrader">Soletrader</label>
                      </div>
                    </li>
                    <li>
                      <div className="check-box big">
                        <input
                          className="form-control-line New_control"
                          type="radio"
                          value="1"
                          name="sellerType"
                          onChange={() => this.setState({ businessType: 3 })}
                          id="Private"

                        />
                        <label htmlFor="Private">Private</label>
                      </div>
                    </li>


                  </ul>
                  <div className="bottonsend text-center">
                    <button
                      type="submit"
                      className="btnx btn-secondaryx"
                      onClick={() => this.submitBusinessTypeDetail()}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default PostAnAdCategoryComponent;
