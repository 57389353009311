import React, { Component } from "react";
import General from "Helpers/General";
import { Link } from "react-router-dom";
import Moment from "react-moment";

export class MyRecentViewComponent extends Component {
  render() {
    const { product } = this.props;
    return (
      <div className="row">
        <div className="col-lg-12 listside_newperent px-3 mb-2">
          <div className="background-color">
            <div className="product-listing-product desktop_view">
              <div className="row">
                <div className="col-3">
                  <div className="product-img-bx">
                    <Link to={`/product/${product.productSlug}`}>
                      {product.adType && product.adType === "Featured" && (
                        <div className="specialtags">
                          <span
                            className={
                              (product.adType === "Featured"
                                ? "black"
                                : "green") + "_special"
                            }
                          >
                            {product.adType}
                          </span>
                        </div>
                      )}
                      {product.productMainImage ? (
                        <img
                          src={General.showImage(
                            "products/resize-566-400",
                            product.productMainImage
                          )}
                          alt={product.productName}
                        />
                      ) : (
                        <img src="/images/no-image.jpg" alt="product" />
                      )}
                      <div className="totalimg_sectab">
                        <Link
                          to={`/product/${product.productSlug}`}
                          className="totlimg_qt height-fit"
                        >
                          {product.productImages
                            ? product.productImages.length
                            : 0}{" "}
                          <i className="fas fa-camera"></i>
                        </Link>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-9">
                  <div className="product-outer-bx productBig-outer-bx">
                    <div className="product-name-bx content_gobottom_new">
                      {/*{/*<Moment fromNow>{product.time}</Moment>*/}
                      <Link to={`/product/${product.productSlug}`}>
                        <h4>{product.productName}</h4>
                      </Link>
                      <h5>{product.location}</h5>
                      <h6>
                        <span>{General.showPriceDecimal(product.productPrice)}</span>
                      </h6>
                      <ul className="listofsubtabbx">
                        <li>
                          <strong>Added</strong>
                          <span>
                            <Moment format="YYYY/MM/DD">{product.time}</Moment>
                          </span>
                        </li>
                        {product?.expiredAt && (
                          <li>
                            <strong>Expires</strong>
                            <span>
                              <Moment
                                format="YYYY/MM/DD"
                              >
                                {product?.expiredAt}
                              </Moment>
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-listing-product mobile_view position-relative">
              <div className="product_listing_product_block">
                <div className="product-img-bx">
                  <Link to={`/product/${product.productSlug}`}>
                    {product.adType && product.adType === "Featured" && (
                      <div className="specialtags">
                        <span
                          className={
                            (product.adType === "Featured"
                              ? "black"
                              : "green") + "_special"
                          }
                        >
                          {product.adType}
                        </span>
                      </div>
                    )}
                    {product.productMainImage ? (
                      <img
                        src={General.showImage(
                          "products/resize-566-400",
                          product.productMainImage
                        )}
                        alt={product.productName}
                      />
                    ) : (
                      <img src="/images/no-image.jpg" alt="product" />
                    )}
                    <div className="totalimg_sectab">
                      <Link
                        to={`/product/${product.productSlug}`}
                        className="totlimg_qt height-fit"
                      >
                        {product.productImages
                          ? product.productImages.length
                          : 0}{" "}
                        <i className="fas fa-camera"></i>
                      </Link>
                    </div>
                  </Link>
                </div>
                <div className="product-name-bx content_gobottom_new content_gobottom_new_mobile ">
                  {/*<Moment fromNow>{product.time}</Moment>*/}
                  <Link to={`/product/${product.productSlug}`}>
                    <h4>{product.productName}</h4>
                  </Link>
                  <h5>{product.location}</h5>
                  <h6>
                    <span>{General.showPriceDecimal(product.productPrice)}</span>
                  </h6>
                  <p className="d-none d-md-block">
                    {product.productDescription &&
                      product.productDescription.substring(0, 50)}
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    );
  }
}

export default MyRecentViewComponent;
