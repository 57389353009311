import React, { Component } from "react";
import General from "Helpers/General";
import MyProfile from "Models/MyProfile";
import Settings from "Models/Settings";
import Modal from "react-modal";
export class MyPendingTransaction extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
      isModalOpen: false,
      totalWithdrawlBalance: 0,
      totalPendingBalance: 0,
      generalSettingsData: localStorage.getItem("generalSettingsData")
        ? JSON.parse(localStorage.getItem("generalSettingsData"))
        : {},
    }
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ isModalOpen: true });
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  componentDidMount = () => {
    this.getPendingBalance();
    this.getAdminSettings();
  };

  getAdminSettings = (stripe_code) => {
    Settings.getApi(
      "APIS_GET_GENERAL_SETTING",
      async (response) => {
        console.log("generalSettingsData", JSON.stringify(response[0]));
        localStorage.setItem(
          "generalSettingsData",
          JSON.stringify(response[0])
        );
        this.setState({ generalSettingsData: response[0] });
      },
      false
    );
  };

  getPendingBalance = () => {
    const { userId } = this.state;
    MyProfile.getApi(
      "API_GET_SELLER_TOTAL_BALANCE",
      async (res) => {
        if (res) {
          this.setState({
            totalPendingBalance: res.totalPendingBalance,
            totalWithdrawlBalance: res.totalWithdrawlBalance
          });
        }
      },
      { params: { seller_id: userId } },
      true
    )
  };

  render() {
    const { totalPendingBalance, isModalOpen, generalSettingsData } = this.state;
    return (
      <>
        <div
          className="tab-pane active in this_subManage_Listings"
          id="pending-transaction"
          role="tabpanel"
        >
          <div className="subtab_prodlist_NEW_forpadd">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 d-none d-md-block">
                  <div className="subcateleft_imgsec">
                    <img
                      src="/images/account-settings-limg.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="formnotification_inner withbg_white border-radius border-bottom_1">
                    <div className="noti_enable repeatSec_innerr border-0">
                      <div>
                        {General.showPriceDecimal(totalPendingBalance)}
                      </div>
                      <div>
                        Uncompleted Transaction <span onClick={this.handleOpenModal}>
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              isOpen={isModalOpen}
              onRequestClose={this.handleCloseModal}
              contentLabel="Order Protection"
              className="modal-content"
              overlayClassName="modal-overlay"
            >
              <button
                onClick={this.handleCloseModal}
                className="shipCloseBtn"
              >
                <i className="fa fa-times "></i>
              </button>
              <div className="product-outer-bx productBig-outer-bx">
                <div className="product-name-bx content_gobottom_new prdNewRow_row">
                  <div className="cont_fuild">
                    {generalSettingsData["miscellaneous"]
                      ? generalSettingsData["miscellaneous"][0]["uncompletedTransaction"]
                      : ""}
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </>
    );
  }
}

export default MyPendingTransaction;
