import React, { Component } from "react";
import Menu from "Partials/Menu";
import General from "Helpers/General";
import Category from "Models/Category";
import Moment from "react-moment";
import AddProduct from "Models/AddProduct";
import AdsAddFormTerm from "Elements/AdsAddFormTerm";
import formData from "form-data";
import axios from "axios";
import ApiEndPoints from "Helpers/ApiEndPoints";
import SubCategory from "Models/SubCategory";
import Fields from "Models/Fields";
import Plans from "Models/Plans";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import PaymentPopUp from "../Stripe/CheckoutForm";
import CheckoutModel from "Models/CheckoutModel";
var qs = require("qs");
let frontUrl = process.env.REACT_APP_FRONTEND_URL;

class PostEditForm extends Component {
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);
    this.featuredPlan = React.createRef();
    this.urgentPlan = React.createRef();

    this.spotlightPlan = React.createRef();
    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      session_id: this.props.session_id ? this.props.session_id : "",
      session_resp: this.props.session_resp ? this.props.session_resp : "",
      AddProduct: {
        product_id: this.props.product._id,
        productName: this.props.product.productName,
        productDescription: this.props.product.productDescription,
        place_id: this.props.product.place_id
          ? this.props.product.place_id
          : "",
        productMainImage: this.props.product.productMainImage
          ? this.props.product.productMainImage
          : "",
        deliveryCost: this.props.product.deliveryCost,
        delivery: this.props.product.delivery,
        productPrice: this.props.product.productPrice,
        sellerType: this.props.product.sellerType,
        location: this.props.product.location,
        latitude: this.props.product.latitude,
        longitude: this.props.product.longitude,
        allowBuyNow: this.props.product.allowBuyNow,
        expiredAt: this.props.product.expiredAt,
        isShowMap: this.props.product.showMap,
        customFieldData: this.props.product.customFieldData,
        allCustomFields: [],
        avaliableExchange: this.props.product.avaliableExchange,
        avaliableNegotiation: this.props.product.avaliableNegotiation,
        plan_id: this.props.product.planId ? this.props.product.planId._id : "",
        plan_type: this.props.product.adType ? this.props.product.adType : "",
        adType: "",

        // session_slug: session_slug ? session_slug : "",
        customFields: this.props.productCustomFields
          ? this.props.productCustomFields
          : "",
        customFields2: this.props.productCustomFields
          ? this.props.productCustomFields
          : "",
        IsLiveStreaming: this.props.product.IsLiveStreaming
          ? this.props.product.IsLiveStreaming
          : false,
        liveStreamingStartTime: this.props.product.liveStreamingStartTime
          ? this.props.product.liveStreamingStartTime
          : "",
        liveStreamingEndTime: this.props.product.liveStreamingEndTime
          ? this.props.product.liveStreamingEndTime
          : "",
      },
      AddProductCustomField: this.props.productCustomFields,
      selectedCustomFields: this.props.selectedCustomFields,
      subCategoryId: props.subCategoryId,
      thirdCategoryId:
        this.props.product.thirdCategoryId &&
          this.props.product.thirdCategoryId != null
          ? this.props.product.thirdCategoryId._id
          : null,
      recordType: "",
      categoryType: "",
      categoryName: "",
      subCategoryName: "",
      categoryId: "",
      remainingTitleChars: 90 - this.props.product.productName.length,
      remainingDescriptionChars: 1000 - this.props.product.productDescription.length,
      mainImage: this.props.product.productMainImage,
      files: this.props.product.productImages
        ? this.props.product.productImages
        : [],
      singleSubCategoryData: localStorage.getItem("singleSubCategoryData")
        ? JSON.parse(localStorage.getItem("singleSubCategoryData"))
        : [],
      adTypeDataUrgent: localStorage.getItem("adTypeDataUrgent")
        ? JSON.parse(localStorage.getItem("adTypeDataUrgent"))
        : [],
      adTypeDataSpotlight: localStorage.getItem("adTypeDataSpotlight")
        ? JSON.parse(localStorage.getItem("adTypeDataSpotlight"))
        : [],
      adTypeDataFeatured: [],
      isLoading: false,
      imageData: [],

      place_id: this.props.product.place_id,
      address: this.props.product.location,
      lat: this.props.product.latitude,
      lng: this.props.product.longitude,
      productImageData: this.props.product.productImages
        ? this.props.product.productImages
        : [],
      maxImages: props.product?.planId ? 10 : 5,
      paymentbutton: true,
      packages: "",
      package_details: {},
      pack: "",
      current_index: 0,
      paymentAmount: 0,
      showPackage: false,
      checkedAttr: "",
      liveStreaming: false,
      showDeliveryPrice: this.props.product.showDeliveryPrice,
      deliveryCost: this.props.product.deliveryCost,
      delivery: this.props.product.delivery,
      freeDeliveryOptions: this.props.product.freeDeliveryOptions,
      transaction_type: "plan",
      transaction_type_other: "package",
      checkOutPopup: false,
      tokenReq: false,
      isFeatured: false,
      inputValue: "",
      validationError: "",
    };
    console.log("selectedCustomFields", this.props.selectedCustomFields);
  }

  showMapHandler = () => {
    let { AddProduct } = this.state;
    AddProduct["isShowMap"] = !AddProduct["isShowMap"];
    this.setState({ AddProduct: AddProduct });
  };

  handleChange = (address) => {
    this.setState({ address });
  };
  componentDidMount = () => {
    if (this.state.session_resp == "success") {
      let { AddProduct } = this.state;
      AddProduct["adType"] = "Featured";
      this.setState({ AddProduct: AddProduct });
      this.setState({ isFeatured: true });
      this.submithandlerFunction();
    } else if (this.state.session_resp == "error") {
      this.setState({ isFeatured: false });
      let { AddProduct } = this.state;
      AddProduct["adType"] = "";
      this.setState({ AddProduct: AddProduct });
      const currentUrl = window.location.href;
      const newUrl = currentUrl.split("?")[0];
      window.history.replaceState({ path: newUrl }, "", newUrl);
    }
    let customfieldArr = [];
    console.log("allCustomFields????", this.state.allCustomFields);
    for (let key in this.state.allCustomFields) {
      let newObj = this.state.allCustomFields[key];
      newObj.selectedValue = "";
      for (let key1 in this.props.productCustomFields) {
        if (
          this.state.allCustomFields[key]["_id"] ==
          this.props.productCustomFields[key1]["field_id"]
        ) {
          newObj = this.state.allCustomFields[key];
          newObj.selectedValue =
            this.props.productCustomFields[key1]["field_value"];
          break;
        }
      }
      customfieldArr.push(newObj);
    }
    this.setState({ allCustomFields: customfieldArr });
    console.log("customfieldArr", customfieldArr);
  };

  componentWillMount = () => {
    var self = this;
    let subCategoryId = this.state.subCategoryId;
    if (this.state.thirdCategoryId && this.state.thirdCategoryId != "") {
      subCategoryId = this.state.thirdCategoryId;
    }
    SubCategory.postApi(
      "APIS_GET_SUB_CATEGORY_DETAIL_BY_Id",
      { params: { subCategoryId } },
      async (response) => {
        self.setState({ categoryType: response.categoryId.slug });
        self.setState({ categoryId: response.categoryId._id });
        self.setState({ recordType: response.categoryId.categoryName });
        self.setState({ categoryName: response.categoryId.categoryName });
        self.setState({ subCategoryName: response.subCategoryName });
        if (response.parentId) {
          self.setState({ parentCatName: response.parentId.subCategoryName });
          self.setState({ subCategoryId: response.parentId._id });
          self.setState({ thirdCategoryId: subCategoryId });
        }
        localStorage.setItem("singleSubCategoryData", JSON.stringify(response));
        localStorage.setItem(
          "allowBuyNow",
          JSON.stringify(response.allowBuyNow)
        );
        self.setState({ singleSubCategoryData: response });
        self.setState({ allowBuyNow: response.allowBuyNow });
      },
      false
    );

    this.getFieldsValuesData();
    this.getAdTypePlans();
  };

  getAdTypePlans() {
    var self = this;
    let subCategoryId = this.state.subCategoryId;

    let { AddProduct } = this.state;

    /* SubCategory.getApi(
      "APIS_GET_PACKAGES",
      async (response) => {
        this.setState({ packages: response });
      },
      false
    ); */

    Plans.postApi(
      "APIS_GET_FEATURED_PLAN_BY_AD",
      { params: { productPrice: AddProduct.productPrice } },
      async (response, status) => {
        if (status == "success") {
          self.setState({ adTypeDataFeatured: response });
        }
      },
      true
    );
  }

  getFieldsValuesData() {
    var self = this;
    let subCategoryId = this.state.subCategoryId;
    if (this.state.thirdCategoryId && this.state.thirdCategoryId != "") {
      subCategoryId = this.state.thirdCategoryId;
    }
    Fields.getApiByParams(
      "APIS_GET_FIELDS_VALUES",
      subCategoryId,
      async (response) => {
        localStorage.setItem("allCustomFields", JSON.stringify(response));
        this.setState({ allCustomFields: response })
        console.log("allCustomFields-respon", response);
        let customfieldArr = [];
        console.log("allCustomFields????", this.state.allCustomFields);
        for (let key in this.state.allCustomFields) {
          let newObj = this.state.allCustomFields[key];
          newObj.selectedValue = "";
          for (let key1 in this.props.productCustomFields) {
            if (
              this.state.allCustomFields[key]["_id"] ==
              this.props.productCustomFields[key1]["field_id"]
            ) {
              newObj = this.state.allCustomFields[key];
              newObj.selectedValue =
                this.props.productCustomFields[key1]["field_value"];
              break;
            }
          }
          customfieldArr.push(newObj);
        }
        this.setState({ allCustomFields: customfieldArr });
        console.log("customfieldArr", customfieldArr);
      },
      false
    );
  }

  setimageAsMainImage = (event) => {
    var fileName = event.target.dataset.file;
    const { AddProduct } = this.state;
    AddProduct["productMainImage"] = fileName;
    this.setState({ AddProduct: AddProduct });
    this.setState({ mainImage: fileName });
  };

  submitHandler = (event) => {
    event.preventDefault();
    this.submithandlerFunction();
  };

  onSuccess2 = (ref) => {
    AddProduct.postApi(
      "API_UPDATE_TRANSACTION",
      {
        package_id: this.state.pack._id,
        video_stream_id:
          this.state.video_stream_id && this.state.video_stream_id != ""
            ? this.state.video_stream_id
            : null,
        intent_id: ref.id,
        payment_intent: this.state.session_id,
        status: "active",
      },
      async (response, status) => {
        if (status == "success") {
          this.setState({ current_package: response });
          //this.setState({ paymentbutton: true });
          this.setState({ purchaseSubPopup: false });
          this.afterPaymentCheckPackage();
          this.setState({ isLoading: false });
        }
      },
      true
    );
  };
  checkFieldValidation = () => {
    var rv = {};
    if (
      this.state.AddProductCustomField &&
      this.state.AddProductCustomField.length > 0
    ) {
      for (var i = 0; i < this.state.AddProductCustomField.length; i++)
        rv[this.state.AddProductCustomField[i].field_name.toLowerCase()] =
          this.state.AddProductCustomField[i];
    }
    return rv;
  };

  submithandlerFunction = async (pay_process = false) => {

    let customVaildationInput;
    if (this.state.allCustomFields && this.state.allCustomFields.length > 0) {
      customVaildationInput = this.state.allCustomFields
        .filter((element) => {
          if (element.isValidationRequired) {
            return element;
          }
        })
        .map((data) => data._id);
    }
    let error = 0;
    let selectedArrObj = this.checkFieldValidation();
    if (customVaildationInput && customVaildationInput.length > 0) {
      customVaildationInput.forEach((element) => {
        let val = false;
        for (const key in selectedArrObj) {
          if (selectedArrObj[key].field_id === element) {
            val = true;
            break;
          }

        }
        if (!val) {
          error = 1;
        }
      });
    }
    console.log("error", error)
    if (
      (await AddProduct.checkValidation(
        await AddProduct.validationRules(),
        this.state.AddProduct
      )) === true &&
      this.state.productImageData.length >= 3 &&
      error == 0
    ) {
      AddProduct.postApi(
        "APIS_UPDATE_ADS",
        this.state,
        async (response, status) => {
          if (status == "success") {
            if (pay_process === true) {
              AddProduct.postApi(
                "APIS_STRIPE_CHECKOUT",
                {
                  amount: this.state.paymentAmount,
                  buyer_id: this.state.id,
                  quantity: 1,
                  cancel_url: `${frontUrl}/edit-product/${response.productSlug}`,
                  success_url: `${frontUrl}/edit-product/${response.productSlug}`,
                  productName: this.state.AddProduct.productName,
                  categoryId: response._id,
                  slug: response.productSlug
                },
                async (response, status) => {
                  if (status === "success" && response) {
                    window.location.href = `${response.url}`;
                  } else {
                    console.error(
                      "Error creating Stripe session:",
                      response.error.message
                    );

                    this.setState({ products: { isSold: false } });
                  }
                }
              );
            } else {
              AddProduct.redirectTo("my-profile/activity", true);
            }

          } else {
            console.log("error")
          }
        },
        true
      );
    } else {
      if (this.state.productImageData.length < 3) {
        document.getElementById("image-error").innerHTML =
          "<span class='validation-span'>Minimum 3 images required.</span>";
      }

      if (customVaildationInput && customVaildationInput.length > 0) {
        customVaildationInput.forEach((element) => {
          let divEle = document.querySelector("[name=" + element + "]");
          if (!selectedArrObj.hasOwnProperty(element)) {
            if (!divEle.classList.contains("validation-error")) {
              divEle.classList.add("validation-error");
              if (!divEle.nextElementSibling) {
                let divElement = document.createElement("div");
                divElement.innerHTML =
                  "<span class='validation-span'>" +
                  divEle.getAttribute("data-name") +
                  " is required<span></div>";
                divEle.parentNode.appendChild(divElement);
              }
            }
          }
        });
      }

      SubCategory.toastAlert("error", "Please fill all required fields.");
      return;
    }
  };

  planChangeHandler = (planType) => {
    // console.log("planType",planType)
    let { AddProduct, isFeatured } = this.state;
    switch (planType) {
      case "Featured":
        AddProduct["plan_id"] = this.featuredPlan.current.value;
        this.checkPlan(
          "Featured",
          this.featuredPlan.current.value,
          AddProduct.productPrice,
          isFeatured
        );
        break;
      case "Urgent":
        AddProduct["plan_id"] = this.urgentPlan.current.value;
        break;
      case "Spotlight":
        AddProduct["plan_id"] = this.spotlightPlan.current.value;
        break;

      default:
        break;
    }
    this.setState({ AddProduct: AddProduct });
  };

  onChangeHanlde = (event) => {
    // console.log("event",event)
    if (event.target.name == "productName") {
      if (event.target.value.length <= 90) {
        this.setState({
          remainingTitleChars: 90 - event.target.value.length
        });
      }
    }
    if (event.target.name == "productDescription") {
      // console.log(event.target.value.length, "jj");
      if (event.target.value.length <= 1000) {
        this.setState({
          remainingDescriptionChars: 1000 - event.target.value.length
        });
      }
      if (event.target.value.length <= 50) {
        this.state.validationError =
          "Input must be at least 50 characters long";
      } else {
        this.state.validationError = "";
      }
    }
    let { AddProduct } = this.state;

    if (event.target.value) {
      if (event.target.classList.contains("validation-error")) {
        event.target.classList.remove("validation-error");
        if (event.target.nextElementSibling) {
          event.target.nextElementSibling.remove();
        }
      }
    }

    if (event.target.name === "productPrice") {
      // if (event.target.validity.valid) {
      //   AddProduct[event.target.name] = event.target.value;
      //   this.setState({ isFeatured: false, paymentbutton: true });
      // }
      if (/^\d*\.?\d*$/.test(event.target.value) || event.target.value === "") {
        AddProduct[event.target.name] = event.target.value;
        this.setState({ isFeatured: false, paymentbutton: true });
      }
    } else if (
      event.target.name === "avaliableNegotiation" ||
      event.target.name === "avaliableExchange"
    ) {
      if (document.getElementById(event.target.name).checked == true) {
        AddProduct[event.target.name] = "Yes";
      } else {
        AddProduct[event.target.name] = "No";
      }
    } else if (event.target.name === "adType") {
      if (document.getElementById("Featured").checked == true) {
        AddProduct[event.target.name] = event.target.value;
      } else {
        AddProduct[event.target.name] = "";
      }
    } else {
      AddProduct[event.target.name] = event.target.value;
    }
    switch (event.target.value) {
      case "Featured":
        if (Number(AddProduct.productPrice) > 0) {
          this.setState({ isFeatured: event.target.checked });
          Plans.postApi(
            "APIS_GET_FEATURED_PLAN_BY_AD",
            { params: { productPrice: AddProduct.productPrice } },
            async (response) => {
              this.setState({ adTypeDataFeatured: response });
            },
            true
          );

          AddProduct["plan_id"] = this.featuredPlan.current.value;
          this.checkPlan(
            "Featured",
            this.featuredPlan.current.value,
            AddProduct.productPrice,
            event.target.checked
          );

          if (document.getElementById("Featured").checked == false) {
            this.setState({ maxImages: 5 });
          } else {
            this.setState({ maxImages: 10 });
          }
        } else {
          this.setState({ isFeatured: false });
          SubCategory.toastAlert("error", "Please fill price before featured.");
        }
        break;

      default:
        break;
    }
    AddProduct["adType"] = "";
    this.setState({ AddProduct: AddProduct });
  };

  onChangeHanldeCustomField = (event) => {
    let newUpdateCustomField = {};

    if (event.target.value) {
      if (event.target.classList.contains("validation-error")) {
        event.target.classList.remove("validation-error");
        if (event.target.nextElementSibling) {
          event.target.nextElementSibling.remove();
        }
      }
    }

    newUpdateCustomField.field_id = event.target.dataset.id;
    newUpdateCustomField.field_name = event.target.dataset.name;
    if (event.target.dataset.type == "Dropdown") {
      var index = event.nativeEvent.target.selectedIndex;
      var field_value_text = event.target[index].text;
      newUpdateCustomField.field_value = field_value_text;
      newUpdateCustomField.field_value_id = event.target.value;
      newUpdateCustomField.slug = event.target.name;
    }
    if (event.target.dataset.type == "Checkbox") {
      if (event.target.checked) {
        newUpdateCustomField.field_value = "Yes";
      } else {
        newUpdateCustomField.field_value = "No";
      }
    } else {
      newUpdateCustomField.field_value = event.target.value;
    }
    newUpdateCustomField.field_type = event.target.dataset.type;
    let { AddProductCustomField, allCustomFields } = this.state;
    let updation = false;
    if (allCustomFields.length > 0) {
      for (let key1 in allCustomFields) {
        if (allCustomFields[key1]["fieldName"] == event.target.dataset.name) {
          allCustomFields[key1]["selectedValue"] =
            newUpdateCustomField.field_value;
          break;
        }
      }
    }

    if (newUpdateCustomField.field_value) {
      if (AddProductCustomField.length > 0) {
        for (let key1 in AddProductCustomField) {
          if (
            AddProductCustomField[key1]["field_name"] ==
            event.target.dataset.name
          ) {
            AddProductCustomField[key1] = newUpdateCustomField;
            updation = true;
            break;
          }
        }
      }

      if (updation == false) {
        AddProductCustomField[AddProductCustomField.length] =
          newUpdateCustomField;
      }
    } else {
      if (AddProductCustomField.length > 0) {
        for (let key1 in AddProductCustomField) {
          if (
            AddProductCustomField[key1]["field_name"] ==
            event.target.dataset.name
          ) {
            AddProductCustomField.splice(key1, 1);
            break;
          }
        }
      }
    }
    this.setState({
      AddProductCustomField: AddProductCustomField,
      allCustomFields: allCustomFields,
    });
  };

  handleProductImages = async (event) => {
    const { maxImages, productImageData } = this.state;

    if (maxImages < productImageData.length + 1) {
      alert("You can only add upto " + maxImages + " images.");
    } else {
      let self = this;
      let apiEndPoint = ApiEndPoints.getEndPoint("APIS_UPLOAD_IMAGES");
      let fmData = new formData();
      let error = 0;
      let totalImg = productImageData.length + event.target.files.length;

      if (maxImages < totalImg) {
        alert("You can only add upto " + maxImages + " images.");
        error = 1;
      } else {
        for (let i = 0; i < event.target.files.length; i++) {
          let files = event.target.files[i];
          fmData.append("ProductImages", files);
        }
      }

      if (totalImg >= 3) {
        document.getElementById("image-error").innerHTML = "";
      }

      if (error == 0) {
        self.setState({ isLoading: true });

        var config = {
          method: "post",
          url: apiEndPoint,
          data: fmData,
        };

        await axios(config)
          .then(function (response) {
            if (response.data.success == 1) {
              self.setState({
                productImageData: [
                  ...productImageData,
                  ...response.data.allValue,
                ],
              });

              const newArr = [...productImageData, ...response.data.allValue];
              self.setState({
                productImageData: [...new Set(newArr.map((data) => data))],
              });

              self.setState({
                files: [...new Set(newArr.map((data) => data))],
              });

              self.state.imageData = response.data.allValue;
              self.setState({ isLoading: false });
              if (response.data.errors.length > 0) {
                response.data.errors.forEach((singleError) => {
                  SubCategory.toastAlert("error", singleError.file + " - " + singleError.error);
                });
              }
            } else {
              self.setState({ isLoading: false });
              SubCategory.toastAlert("error", response.data.message);
              return;
            }
          })
          .catch(function (error) {
            self.setState({ isLoading: false });
            SubCategory.toastAlert("error", error.message);
            return;
            console.log(error);
          });
      }
    }
  };

  onDropHandler = (acceptedFiles) => {
    const { maxImages, productImageData } = this.state;

    if (maxImages < productImageData.length + 1) {
      alert("You can only add upto " + maxImages + " images.");
    } else {
      let self = this;
      let apiEndPoint = ApiEndPoints.getEndPoint("APIS_UPLOAD_IMAGES");
      let fmData = new formData();
      let error = 0;
      let totalImg = productImageData.length + acceptedFiles.length;

      if (maxImages < totalImg) {
        alert("You can only add upto " + maxImages + " images.");
        error = 1;
      } else {
        for (let i = 0; i < acceptedFiles.length; i++) {
          let files = acceptedFiles[i];
          fmData.append("ProductImages", files);
        }
      }

      if (totalImg >= 3) {
        document.getElementById("image-error").innerHTML = "";
      }

      if (error == 0) {
        self.setState({ isLoading: true });

        var config = {
          method: "post",
          url: apiEndPoint,
          data: fmData,
        };

        axios(config)
          .then(function (response) {
            if (response.data.success == 1) {
              self.setState({
                productImageData: [
                  ...productImageData,
                  ...response.data.allValue,
                ],
              });
              const newArr = [...productImageData, ...response.data.allValue];
              self.setState({
                productImageData: [...new Set(newArr.map((data) => data))],
              });
              self.setState({
                files: [...new Set(newArr.map((data) => data))],
              });
              self.state.imageData = response.data.allValue;
              self.setState({ isLoading: false });
              if (response.data.errors.length > 0) {
                response.data.errors.forEach((singleError) => {
                  SubCategory.toastAlert("error", singleError.file + " - " + singleError.error);
                });
              }
            } else {
              console.log("---", response);
              self.setState({ isLoading: false });
              SubCategory.toastAlert("error", response.data.message);
              return;
            }
          })
          .catch(function (error) {
            self.setState({ isLoading: false });
            SubCategory.toastAlert("error", error.message);
            return;
            console.log(error);
          });
      }
    }
  };

  selectedCustomField = (fieldName) => {
    if (fieldName) {
      switch (fieldName) {
        case "categorytype":
          return this.state.AddProduct["customFields"]
            ? this.state.AddProduct["customFields"]["categorytype"]
            : "";
          break;
        case "condition":
          return this.state.AddProduct["customFields"]
            ? this.state.AddProduct["customFields"]["condition"]
            : "";
          break;
        case "maker":
          return this.state.AddProduct["customFields"]
            ? this.state.AddProduct["customFields"]["maker"]
            : "";
          break;

        default:
          return "";
          break;
      }
    } else {
      return "";
    }
  };

  deleteHandler = (index) => {
    console.log("index", index);
    const { files, productImageData } = this.state;
    console.log("files--before", files, productImageData);
    const updatedFiles = [...files];
    const updatedProductImageData = [...productImageData];
    updatedFiles.splice(index, 1);
    updatedProductImageData.splice(index, 1);
    console.log("files--after", updatedFiles, updatedProductImageData);
    if (updatedFiles.length > 0) {
      let { AddProduct } = this.state;
      const fileName = updatedFiles[0];
      AddProduct = { ...AddProduct, productMainImage: fileName };
      this.setState({
        AddProduct,
        mainImage: fileName,
      });
    }
    this.setState({
      files: updatedFiles,
      productImageData: updatedProductImageData,
    });
    console.log("files", updatedFiles, updatedProductImageData);
  };

  handleDelivery = (event) => {
    var delivery = event.target.value;
    if (delivery) {
      switch (delivery) {
        case "1":
          this.setState({
            freeDeliveryOptions: 0,
            showDeliveryPrice: false,
            delivery: 1,
            deliveryCost: 0,
          });
          break;
        case "2":
          this.setState({
            freeDeliveryOptions: 0,
            delivery: 2,
          });
          break;
        default:
          this.setState({
            freeDeliveryOptions: 0,
            delivery: 0,
            showDeliveryPrice: false,
            deliveryCost: 0,
          });
          break;
      }
    }
  };

  handleFreeDeliveryOptions = (event) => {
    var freeDelivery = event.target.value;
    if (freeDelivery) {
      switch (freeDelivery) {
        case "1":
          this.setState({
            freeDeliveryOptions: 1,
            showDeliveryPrice: false,
            deliveryCost: 0,
          });
          break;
        case "2":
          this.setState({
            freeDeliveryOptions: 2,
            showDeliveryPrice: true,
          });
          break;
        default:
          this.setState({
            freeDeliveryOptions: 0,
            showDeliveryPrice: false,
            deliveryCost: 0,
          });
          break;
      }
    }
  };
  onNumberInputKeyDown = (event) => {
    if (event.key === "e" || event.key === "-" || event.keyCode === 189 || event.which === 189) event.preventDefault();
  };

  checkPlan = async (plan_type, plan_id, product_price, status) => {
    if (status == true) {
      SubCategory.postApi(
        "API_CHECK_PLAN",
        {
          type: plan_type,
          plan_id: plan_id,
          productPrice: product_price,
          form_type: "edit",
        },
        async (response2) => {
          this.setState({
            paymentbutton: response2.status,
            paymentAmount: response2.plan_amount,
          });
        },
        true
      );
    } else {
      this.setState({ paymentbutton: true });
    }
  };

  handleDeliveryCost = (event) => {
    if (Number(event.target.value)) {
      if (event.target.classList.contains("validation-error")) {
        event.target.classList.remove("validation-error");
        if (event.target.nextElementSibling) {
          event.target.nextElementSibling.remove();
        }
      }
    } else {
      if (!event.target.classList.contains("validation-error")) {
        event.target.classList.add("validation-error");
        if (!event.target.nextElementSibling) {
          let div = document.createElement("div");
          div.innerHTML =
            "<span class='validation-span'>Price is required<span></div>";
          event.target.parentNode.appendChild(div);
        }
      }
    }

    this.setState({
      deliveryCost: event.target.value,
    });
  };

  checkValidations = async () => {
    if (
      (await AddProduct.checkValidation(
        await AddProduct.validationRules(),
        this.state.AddProduct
      )) === true
    ) {
      this.setState({ checkOutPopup: true });
    }
  };

  saveDataHandler = () => {
    let slug = this.state.singleSubCategoryData.slug;

    AddProduct.postApi(
      "APIS_UPDATE_ADS",

      this.state,

      async (response, status) => {
        //console.log("object", status, response);
        if (status == "success") {
          const { postageDetail, delivery } = this.state;
          let frontUrl = process.env.REACT_APP_FRONTEND_URL;
          let pay_process = false;

          if (delivery === 2) {
            if (
              (await CheckoutModel.checkValidation(
                await CheckoutModel.validationRules(),
                postageDetail
              )) === true
            ) {
              pay_process = true;
              // this.setState({orderPaymentModel : true});
            }
          } else {
            pay_process = true;
            // this.setState({orderPaymentModel : true});
          }
          if (pay_process === true) {
            AddProduct.postApi(
              "APIS_STRIPE_CHECKOUT",
              {
                amount: this.state.paymentAmount,
                buyer_id: this.state.id,
                quantity: 1,
                cancel_url: `${frontUrl}/edit-product/${response.productSlug}`,
                success_url: `${frontUrl}/my-profile/activity`,
                productName: this.state.AddProduct.productName,
                categoryId: response._id,
                slug: slug
              },
              async (response, status) => {
                if (status === "success" && response) {
                  window.location.href = `${response.url}`;
                } else {
                  console.error(
                    "Error creating Stripe session:",
                    response.error.message
                  );

                  this.setState({ products: { isSold: false } });
                }
              }
            );
          } else {
            return;
          }
        } else {
        }
        // console.log("response", response);
      }
    );
  };

  render() {
    var context = this;
    const {
      imageData,
      files,
      pack,
      remainingTitleChars,
      remainingDescriptionChars,
      allCustomFields,
      selectedCustomFields,
      adTypeDataSpotlight,
      adTypeDataFeatured,
      adTypeDataUrgent,
      isLoading,
      maxImages,
      delivery,
      showDeliveryPrice,
    } = this.state;
    console.log("allCustomFields", allCustomFields);
    const PaystackHookExample = () => {
      let pay_process = true;
      if (!this.state.paymentbutton) {
        return (
          <div>
            <button
              type="button"
              className="payment-btn"
              onClick={() => {
                this.checkValidations();
                this.submithandlerFunction(pay_process);
              }}
            >
              Made Payment
            </button>
          </div>
        );
      } else {
        return (
          <button type="submit" className="number-reveal-btn right_img">
            <span>
              Update Listing<i class="fas fa-arrow-right"></i>
            </span>
          </button>
        );
      }
    };

    const onSuccess = (ref) => {
      let paymentIntent = window.btoa(JSON.stringify(ref));
      this.setState({ checkOutPopup: false });

      AddProduct.postApi(
        "API_UPDATE_TRANSACTION_STATUS",
        {
          plan_id: this.state.AddProduct.plan_id,
          intent_id: ref.id,
          payment_intent: paymentIntent,
          status: "active",
        },
        async (response, status) => {
          if (status == "success") {
            this.submithandlerFunction();
          }
        },
        true
      );
    };

    const onClose = () => {
      this.setState({ isLoading: false });
      this.setState({ checkOutPopup: false });
    };

    const onLoading = (value) => {
      this.setState({ isLoading: value });
    };

    return (
      <React.Fragment>
        {isLoading && (
          <div class="loader-wrapper" id="loader_imgs">
            <div class="loader">
              <img src={this.state.headerLogo} alt="" />
              <div class="material-spinner"></div>
            </div>
          </div>
        )}
        <section className="product-header bg-light-dark">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="breadcrumb-bx">
                  <div className="breadcrumb-bx-inner">
                    <Link to="/" className="btnx btn-primaryx">
                      <i className="fas fa-long-arrow-alt-left"></i> Back
                    </Link>
                    <ol className="breadcrumb mb-0 ml-3">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Edit an AD
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-light-dark">
          <form onSubmit={this.submitHandler} method="post" id="add-ads-form">
            <div className="container">
              <h2 className="same-bx-heading text-center">Edit an AD</h2>
              <div className="white_box">
                <div className="row align-items-bottom">
                  <div className="col">
                    <h4>
                      <span>
                        <img src="/images/click_icon.png" />
                      </span>
                      Category
                    </h4>
                    <div className="category_ul">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          {this.state.categoryName}
                        </li>
                        {this.state.parentCatName && (
                          <li className="breadcrumb-item">
                            {this.state.parentCatName}
                          </li>
                        )}
                        <li className="breadcrumb-item">
                          {this.state.subCategoryName}
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="col edit">
                    <div className="w-100 text-right"></div>
                  </div>
                </div>
              </div>
              <div className="white_box">
                <h4>
                  Listing Title<sup>*</sup>
                  <p className="text-muted">Maximum 90 characters</p>
                </h4>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="text"
                        value={this.state.AddProduct["productName"]}
                        onChange={this.onChangeHanlde}
                        name="productName"
                        className="form-control-line New_control"
                        placeholder="Title"
                        maxLength="90"
                      />
                      <p>{remainingTitleChars} characters remaining</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="white_box">
                <h4>
                  Delivery<sup>*</sup>
                </h4>
                <p>
                  Select an option to <label>reach your customers</label>
                </p>
                <div className="row">
                  <div className="col-sm-8">
                    <div className="ad_box">
                      <div className="form-group check-box big">
                        <ul>
                          <li>
                            {/* <div className="check-box big"> */}
                            <input
                              type="radio"
                              name="delivery"
                              value={1}
                              defaultChecked={this.state.delivery === 1}
                              id="meetBuyer"
                              onChange={this.handleDelivery}
                            />
                            <label for="meetBuyer">
                              Allow Buy Now - local collection or arrange to meet buyer{" "}
                            </label>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="delivery"
                              value={2}
                              id="freeDelivery"
                              defaultChecked={this.state.delivery === 2}
                              onChange={this.handleDelivery}
                            />
                            <label for="freeDelivery">
                              Allow Buy Now - Ship items and reach buyers nationwide{" "}
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {delivery === 2 && (
                      <ul>
                        <li>
                          <div className="check-box big">
                            <input
                              type="radio"
                              name="freeDeliveryOptions"
                              id="offerFreeDelivery"
                              value={1}
                              onChange={this.handleFreeDeliveryOptions}
                              defaultChecked={
                                this.state.freeDeliveryOptions === 1
                              }
                            />
                            <label for="offerFreeDelivery">
                              I/We offer free shipping
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="check-box big">
                            <input
                              type="radio"
                              name="freeDeliveryOptions"
                              value={2}
                              id="willCharge"
                              onChange={this.handleFreeDeliveryOptions}
                              defaultChecked={
                                this.state.freeDeliveryOptions === 2
                              }
                            />
                            <label for="willCharge">I/We will charge</label>
                          </div>
                          {showDeliveryPrice === true && (
                            <input
                              type="number"
                              value={this.state.deliveryCost}
                              onChange={this.handleDeliveryCost}
                              onKeyDown={this.onNumberInputKeyDown}
                              name="deliveryCost"
                              inputMode="decimal"
                              pattern="[0-9]*"
                              min={0}
                              className="form-control-line New_control"
                              placeholder="Price"
                            />
                          )}
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              <div className="white_box">
                <h4>
                  Price<sup>*</sup>
                </h4>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="number"
                        value={this.state.AddProduct["productPrice"]}
                        onChange={this.onChangeHanlde}
                        onKeyDown={this.onNumberInputKeyDown}
                        name="productPrice"
                        min="0"
                        className="form-control-line New_control"
                        placeholder="Price"
                        pattern="[0-9]+([.,][0-9]+)?"
                        inputMode="decimal"
                        title="Please enter only numbers" // Message to display if pattern mismatch
                        step="any"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="white_box formob_textcenterr">
                <div className="row">
                  <div className="col-lg-6">
                    <h4>
                      Description<sup>*</sup>
                      <p className="text-muted">Maximum 1000 characters</p>
                    </h4>
                  </div>
                  <div className="col-lg-6 text-right"></div>
                </div>
                <div className="form-group">
                  <textarea
                    type="text"
                    value={this.state.AddProduct["productDescription"]}
                    onChange={this.onChangeHanlde}
                    name="productDescription"
                    className="form-control-line New_control"
                    placeholder="Enter as much information possible"
                    maxLength="1000"
                  />
                  <p>{remainingDescriptionChars} characters remaining</p>
                  {this.state.validationError && (
                    <div style={{ color: "red" }}>
                      {this.state.validationError}
                    </div>
                  )}{" "}
                </div>
              </div>
              <div className="white_box special_makead_stand">
                <h4 className="pb-0">Make your AD standout</h4>
                {this.state.AddProduct["plan_type"] === "Featured" && (
                  <p className="p_note">
                    {" "}
                    This ad is already featured Expiry date is{" "}
                    <Moment format="YYYY/MM/DD">
                      {this.state?.AddProduct["expiredAt"]}
                    </Moment>{" "}
                    if you want to extends feature date you can mark as
                    featured.{" "}
                  </p>
                )}
                <div className="row">
                  <div className="col-sm-8">
                    <div className="ad_box">
                      <ul>
                        <li>
                          <div className="check-box big">
                            <input
                              type="checkbox"
                              name="adType"
                              value="Featured"
                              id="Featured"
                              onChange={this.onChangeHanlde}
                              checked={this.state.isFeatured}
                            /* defaultChecked={
                              this.state.AddProduct["plan_type"] ===
                              "Featured"
                            } */
                            />
                            <label for="Featured">
                              <span className="badge badge-primary">
                                Featured
                              </span>{" "}
                              Have your Ad appear at the top of the category
                              listing for 7 days, 14 days or when the item is
                              sold{" "}
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="drdon_right">
                      <div className="form-group select_box">
                        {adTypeDataFeatured && (
                          <select
                            className="form-control-line"
                            ref={this.featuredPlan}
                            onChange={() => this.planChangeHandler("Featured")}
                            value={this.state.AddProduct["plan_id"]}
                          >
                            {adTypeDataFeatured.map(function (rowfield) {
                              return (
                                <option className="item" value={rowfield._id}>
                                  {rowfield.duration != 0 && rowfield.duration}{" "}
                                  {rowfield.label} -{" "}
                                  {rowfield?.user_package
                                    ? `${rowfield.user_package?.number_of_ad} Paid`
                                    : General.showPriceDecimal(rowfield.amount)}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="white_box Upload_box mb-0">
                <div className=" row">
                  <div className="col-lg-6">
                    <h4 className="p-0">
                      Photos<sup>*</sup>
                    </h4>
                    <p>You can add up to {maxImages} images</p>
                  </div>
                  <div className="col-lg-6">
                    <div className="file_upload_btn text-right">
                      <input
                        id="fileupload"
                        multiple
                        onChange={this.handleProductImages}
                        type="file"
                        className="fuilddesh"
                        name="img"
                        accept=".jpeg, .jpg, .png"
                        placeholder="Product Images"
                      />
                      <label
                        htmlFor="fileupload"
                        className="number-reveal-btn right_img"
                      >
                        <span>
                          Upload Files
                          <img src="/images/edit-icon.png" alt="images" />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <Dropzone
                  accept={{
                    'image/jpeg': [],
                    'image/jpg': [],
                    'image/png': [],
                  }}
                  onDrop={this.onDropHandler}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        className="drop_file text-center"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <h3>Drop Your Files Here</h3>
                        <div className="upload_manyclear text-center">
                          <p>
                            Upload clear images as possible; this will get your
                            ad more views and replies!
                          </p>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <div id="image-error" className="text-center"></div>
              </div>
              <div className="uplodadGallery">
                {files &&
                  files.map((data, index) => {
                    return (
                      <div className="uploadGalleryBox">
                        <div className="upload-img-wall">
                          <img
                            src={General.showImage(
                              "products/resize-180-180",
                              data
                            )}
                            alt="uploaded image"
                          />
                          <span onClick={() => this.deleteHandler(index)}>
                            <i className="fa fa-trash"></i>
                          </span>
                        </div>
                        <div className="text-center py-2 text">
                          {context.state.mainImage == data ? (
                            <a data-file={data}>Default Image</a>
                          ) : (
                            <>
                              <input
                                type="checkbox"
                                onClick={context.setimageAsMainImage}
                                data-file={data}
                              />
                              <span
                                style={{
                                  color: "#fff",
                                  fontWeight: 600,
                                  fontSize: "14px",
                                }}
                              >
                                Mark as default
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>

              {(allCustomFields && allCustomFields.length > 0 && (
                <div className="white_box">
                  <h4>Details</h4>
                  <div class="row">
                    {allCustomFields.map(function (row) {
                      console.log("allCustomFields-selectedCustomFields", row, selectedCustomFields)
                      return (
                        <React.Fragment>
                          {row.fieldType == "Input" ? (
                            <div class="col-lg-4">
                              <div class="form-group">
                                <input
                                  type="text"
                                  name={row.slug}
                                  value={row.selectedValue}
                                  data-id={row._id}
                                  data-name={row.fieldName}
                                  data-type={row.fieldType}
                                  class="form-control-line"
                                  onChange={context.onChangeHanldeCustomField}
                                  placeholder={row.fieldName}
                                />
                              </div>
                            </div>
                          ) : row.fieldType == "Checkbox" ? (
                            <div className="custom_cheak mt-3 mb-3">
                              <ul>
                                <li>
                                  <div className="check-box big">
                                    <input
                                      type="checkbox"
                                      name={row.slug}
                                      id={row._id}
                                      data-id={row._id}
                                      data-name={row.fieldName}
                                      data-type={row.fieldType}
                                      value="Yes"
                                      className="form-control-line"
                                      onChange={
                                        context.onChangeHanldeCustomField
                                      }
                                      checked={row.selectedValue === "Yes"}
                                      placeholder={row.fieldName}
                                    />
                                    <label for={row._id}>
                                      {row.fieldName}
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          ) : row.fieldType == "Textarea" ? (
                            <div className="">
                              <div className="row">
                                <div className="col-lg-6">
                                  <h4>{row.fieldName}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <textarea
                                  name={row.slug}
                                  value={row.selectedValue}
                                  data-id={row._id}
                                  data-name={row.fieldName}
                                  data-type={row.fieldType}
                                  class="form-control-line"
                                  onChange={context.onChangeHanldeCustomField}
                                  placeholder={row.fieldName}
                                />
                              </div>
                            </div>
                          ) : row.fieldType == "Dropdown" ? (
                            row.slug == "categorytype" ? (
                              // row.fieldValues.length < 10 && (
                              <div class="col-lg-4">
                                <div class="form-group select_box">
                                  <select
                                    class="form-control-line"
                                    name={row.slug}
                                    data-id={row._id}
                                    data-name={row.fieldName}
                                    data-type={row.fieldType}
                                    value={row.selectedValue}
                                    onChange={
                                      context.onChangeHanldeCustomField
                                    }
                                  >
                                    <option value="">
                                      Select {row.fieldName}
                                    </option>
                                    {row.fieldValues.map(function (
                                      rowfield
                                    ) {
                                      return (
                                        <option
                                          className="item"
                                          value={rowfield._id}
                                        >
                                          {rowfield.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                              // )
                            ) : (
                              <div class="col-lg-4">
                                <div class="form-group select_box">
                                  <select
                                    class="form-control-line"
                                    name={row.slug}
                                    value={row.selectedValue}
                                    data-id={row._id}
                                    data-name={row.fieldName}
                                    data-type={row.fieldType}
                                    onChange={
                                      context.onChangeHanldeCustomField
                                    }
                                  >
                                    <option value="">
                                      Select {row.fieldName}
                                    </option>
                                    {row.fieldValues.map(function (rowfield) {
                                      return (
                                        <option
                                          className="item"
                                          value={rowfield._id}
                                        >
                                          {rowfield.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            )
                          ) : row.fieldType == "Radio" ? (
                            <div className="row radioMain">
                              <p className="pt-4">{row.fieldType}</p>
                              <div className=" mt-3">
                                <ul className="radioList">
                                  {row.fieldValues.map(function (rowfield) {
                                    return (
                                      <li>
                                        <div className="">
                                          <input
                                            type="radio"
                                            value={rowfield.name}
                                            name={row.slug}
                                            checked={
                                              row.selectedValue ===
                                              rowfield.name
                                            }
                                            data-id={row._id}
                                            data-name={row.fieldName}
                                            data-type={row.fieldType}
                                            class="form-control-line"
                                            onChange={
                                              context.onChangeHanldeCustomField
                                            }
                                            id={rowfield._id}
                                          />
                                          <label htmlFor={rowfield._id}>
                                            {rowfield.name}
                                          </label>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          ) : (
                            <> </>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              ))}

              <div className="white_box lastbottom_byselect">
                <div className="row">
                  <div className="col-lg-10 col-md-9">
                    <AdsAddFormTerm />
                  </div>
                  <div className="col-lg-2 col-md-3">
                    <div className="w-100 text-center post_an postanpage_bottom_advert">
                      <PaystackHookExample />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </React.Fragment>
    );
  }
}

export default PostEditForm;
