import React, { Component } from "react";

class LoginFooter extends Component {

	render() {
		return (
			<footer className="site-footer">
				<div className="container">
					<div className="copyright-bx">
						<div className="copyright-txt"> Copyright © 2025 <strong>dBoroll Inc.</strong> All Rights Reserved. </div>
						<div className="privacy-bx">
							<a href="/contact-us">Contact Us</a>
							<span>|</span>
							<a href="/term-conditions">Terms of Use</a>
							<span>|</span>
							<a href="/privacy-policy">Privacy Policy</a>
							<span>|</span>
							<a href="/cookies-policy">Cookies policy</a>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

export default LoginFooter;
