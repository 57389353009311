import React, { Component, createRef } from 'react';
import General from "Helpers/General";
import Product from "Models/Product";
import Search from "Models/Search";
import Category from "Models/Category";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Rating } from 'react-simple-star-rating';
import Countdown from "react-countdown";
import moment from "moment";
import CategorySearch from "Models/CategorySearch";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { withRouter } from "react-router-dom";
var qs = require("qs");

class ProductsList extends Component {
  constructor(props) {

    super(props);

    const lat = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).lat;
    const lng = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).lng;
    const keyword = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).keyword;
    const premium = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).premium;

    const weeklydeals = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).weeklydeals;

    this.state = {
      categoryFilers: CategorySearch.dbSchema(),
      headerLogo: localStorage.getItem("HeaderLogo"),
      searchFilers: Search.dbSchema(),
      allSearchProducts: [],
      totalRecords: "",
      premium: premium ? true : false,
      address: "",
      lat: lat ? lat : "",
      lng: lng ? lng : "",
      page_number: 1,
      keyword: keyword ? keyword : "",
      weeklydeals: weeklydeals == "true" ? 1 : 0,
      sortType: "",
      showHideListData: false,
      nothingD: false,
      showHideGridData: true,
      allCategories: [],
      adType: null,
      categoryId: null,
      min_price: null,
      max_price: null,
      avaliableExchange: false,
      availableLeasing: false,
      AvailableBuyNow: false,
      liveAuction: false,
      upcomingLiveAuction: false,
      AvailablePickUp: false,
      avaliableNegotiation: false,
      productSkeleton: [
        { id: 0 },
        { id: 340 },
        { id: 680 },
        { id: 1020 },
        { id: 1360 },
      ],
      openProductFilter: false,
      isLoading: false,
    };
    this.popupRef = createRef();
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
  }

  /* hideComponent = (name) => {
    switch (name) {
      case "showHideListData":
        this.setState({ showHideListData: !this.state.showHideListData });
        this.setState({ showHideGridData: false });
        break;
      case "showHideGridData":
        this.setState({ showHideGridData: !this.state.showHideGridData });
        this.setState({ showHideListData: false });
        break;
      default:
        this.setState({ showHideListData: !this.state.showHideListData });
        break;
    }
  }; */

  handleGoBack = () => {
    this.props.history.goBack(); // Navigate back to the previous page
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.getDataByfilters(1);
    //this.getFilterCountCategory();
    // document.addEventListener('click', this.handleClickOutside, true);
    const overlayElement = document.querySelector('.nodataOverlay');
    console.log("Calling222overlayElement", overlayElement);
    if (overlayElement) {
      console.log("Calling222");
      overlayElement.addEventListener('click', this.handleOverlayClick);
    }
  };

  handleOverlayClick() {
    console.log("Calling");
    document.body.classList.remove('filter-open');
    this.setState({ openProductFilter: false });
  }

  componentWillUnmount() {
    console.log("Calling2");
    const overlayElement = document.querySelector('.nodataOverlay');
    if (overlayElement) {
      console.log("Calling23");
      overlayElement.removeEventListener('click', this.handleOverlayClick);
    }
  }

  getDataByfilters(page_number) {
    var self = this;
    self.setState({ isLoading: true });
    self.setState({ allCategories: [] });
    var userId;
    if (Product.isLogin()) {
      userId = Product.getAuthUserId();
    }
    const {
      premium,
      lat,
      lng,
      radius,
      keyword,
      sortType,
      weeklydeals,
      adType,
      categoryId,
      min_price,
      max_price,
      availableLeasing,
      avaliableExchange,
      AvailableBuyNow,
      liveAuction,
      upcomingLiveAuction,
      AvailablePickUp,
      avaliableNegotiation,
    } = this.state;

    Product.postApiByParams(
      "APIS_GET_PRODUCTS_BY_SEARCH_PAGE_FILTERS",
      {
        page_number,
        premium,
        lat,
        lng,
        radius,
        keyword,
        weeklydeals,
        sortType,
        adType,
        categoryId,
        min_price,
        max_price,
        avaliableExchange,
        availableLeasing,
        AvailableBuyNow,
        liveAuction,
        upcomingLiveAuction,
        AvailablePickUp,
        avaliableNegotiation,
        user_id: userId,
      },
      async (response, status) => {
        if (response.status) {
          console.log("allSearchProducts", response.data);
          self.setState({ allSearchProducts: response.data });
          self.setState({ totalRecords: response.totalRecords });
          self.setState({ next_page_number: response.next_page_number });
          self.setState({ limit: response.limit });
          self.setState({ nothingD: true });
          self.setState({ allCategories: response.categoriesList });
        }
        self.setState({ isLoading: false });
      },
      false
    );
  }

  getFilterCountCategory() {
    /* const {
      keyword,
    } = this.state;

    Category.getApi(
      "APIS_ALL_CATEGORIES_TYPE_DATA",
      async (response) => {
        this.setState({ allCategories: response });
      },
      {params : { searchText : keyword } },
      false
    ); */
  }

  getDatabyPageno(next_page_number) {
    this.getDataByfilters(next_page_number);
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      const address = results[0].formatted_address;
      var self = this;
      self.setState({ address });
      getLatLng(results[0])
        .then((latLng) => {
          var self = this;
          self.setState({
            lat: latLng.lat,
            lng: latLng.lng,
          });
        })
        .catch((error) => console.error("Error", error));
    });
  };

  handleFilterSorting = (event) => {
    this.setState(
      { sortType: event.target.value },
      () => {
        this.getDataByfilters();
        this.handleOverlayClick();
      }
    );
  };

  handleRadiusFilter = (event) => {
    this.setState({ radius: event.target.value });
  };

  handleCatNegotiation = (event) => {
    this.getDataByfilters(this.state.page_number);
    this.setState({ openProductFilter: false });
    this.handleOverlayClick();
  };

  otherOptionHandler = (event) => {
    this.setState(
      { [event.target.name]: event.target.checked, openProductFilter: false },
      () => {
        this.getDataByfilters(this.state.page_number);
        this.handleOverlayClick();
      }
    );
  };

  handleDeliveryOptionSorting = (event) => {
    this.setState(
      { [event.target.name]: event.target.checked, openProductFilter: false },
      () => {
        this.getDataByfilters(this.state.page_number);
        this.handleOverlayClick();
      }
    );
  };

  handleLiveActionSorting = (event) => {
    this.setState(
      { [event.target.name]: event.target.checked, openProductFilter: false },
      () => {
        this.getDataByfilters(this.state.page_number);
        this.handleOverlayClick();
      }
    );
  };

  categoryHandler = (e, categoryId) => {
    this.setState({ categoryId }, () => {
      this.getDataByfilters(this.state.page_number);
    });
  };

  adTypeHandler = (e, adType) => {
    this.setState({ adType }, () => {
      this.getDataByfilters(this.state.page_number);
    });
  };

  priceHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      // this.getDataByfilters(this.state.page_number);
    });
  };

  handlePriceData = () => {
    this.getDataByfilters(this.state.page_number);
    this.handleOverlayClick();
  }

  addWishlistHandler = (id) => {
    if (Product.isLogin()) {
      Product.postApi(
        "APIS_ADD_TO_WISHLIST",
        { productId: id },
        async (response) => {
          this.getDataByfilters();
        },
        true
      );
    } else {
      window.location = `/login`;
    }
  };

  removeWishlistHandler = (id) => {
    if (Product.isLogin()) {
      Product.postApi(
        "APIS_REMOVE_FROM_WISHLIST",
        { productId: id },
        async (response) => {
          this.getDataByfilters();
        },
        true
      );
    } else {
      window.location = `/login`;
    }
  };

  getCategoryCount = (catId) => {
    if (this.state.allSearchProducts && catId) {
      return this.state.allSearchProducts.filter(
        (pro) => pro.categoryId._id === catId
      ).length;
    } else {
      return 0;
    }
  };

  render() {
    var context = this;
    const {
      showHideListData,
      showHideGridData,
      allSearchProducts,
      totalRecords,
      allCategories,
      lat,
      lng,
      keyword,
      weeklydeals,
      premium,
      isLoading
    } = this.state;
    console.log("new caaa", allCategories);
    return (
      <React.Fragment>
        {isLoading && (
          <div className="loader-wrapper" id="loader_img">
            <div className="loader">
              <img src={this.state.headerLogo} alt="" />
              <div className="material-spinner"></div>
            </div>
          </div>
        )}
        <section className="product-header special_prheadr_new">
          <div className="container">
            <div className="row justify-content-between align-items-center ad-track">
              <div className="col-6">
                <div className="breadcrumb-bx">
                  <h2>{totalRecords} Items</h2>
                  <div className="breadcrumb-bx-inner">
                    <button
                      onClick={this.handleGoBack}
                      className="btnx btn-primaryx"
                    >
                      <i className="fas fa-long-arrow-alt-left"></i> Back
                    </button>
                    <ol className="breadcrumb mb-0 ml-3">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">Search</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-5 d-none d-md-block">
                <div className="new_placetab_icon">
                  <div className="form-group-icon-bx">
                    <select
                      className="form-control-line"
                      onChange={context.handleFilterSorting}
                    >
                      <option value="">Most Recent First</option>
                      <option value="LowToHigh">Price: Low to High</option>
                      <option value="HighToLow">Price: High to Low</option>
                      <option value="RatingLowToHigh">Rating: Low to High</option>
                      <option value="RatingHighToLow">Rating: High to Low</option>
                    </select>
                    <span className="form-group-icon">
                      <img src="/images/down-arrow-icon.png" alt="icon" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="repeat-section product-listing-main-bx bg-light-dark forsalepage_listing">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 paddingrigt_none_Cfilter">
                <div
                  ref={this.popupRef}
                  className={`product-sideBar-parent new_perenttop_forspecals ${this.state.openProductFilter ? "active" : ""
                    }`}
                >
                  <div className="product-listing-sideBar desktop_fillter" id="side-bar-fixed">
                    <div className="sidebarleft_newinner">
                      <div id="filter-closer">
                        <img
                          src="/images/cancel.svg"
                          alt="icon"
                          onClick={() =>
                            this.setState({ openProductFilter: false })
                          }
                        />
                      </div>
                      <div className="filter-clear">
                        <button
                          className="btn btn-sm"
                          onClick={() =>
                            window.location.reload()
                          }
                        >
                          Clear Filter
                        </button>
                      </div>
                      <div className="product-listing-sideBar-colum sideBar-location">
                        <h2>Location</h2>
                        <div className="form-group">
                          <PlacesAutocomplete
                            searchOptions={{
                              componentRestrictions: {
                                country: "UK",
                              },
                            }}
                            value={this.state.address}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Search Places ...",
                                    className: "location-search-input",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    const style = suggestion.active
                                      ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                      : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        key={suggestion.placeId}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="form-group mb-0 sideBar-choose-distance">
                                  <div className="form-group-icon-bx">
                                    <input
                                      type="number"
                                      name="radius"
                                      onChange={context.handleRadiusFilter}
                                      className="form-control-line"
                                      placeholder="Distance"
                                    />
                                  </div>
                                  <button
                                    className="go-btn"
                                    onClick={context.handleCatNegotiation}
                                  >
                                    GO
                                  </button>
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </div>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          Category
                        </AccordionSummary>
                        <AccordionDetails>
                          <ul className="toggle-bx ">
                            {allCategories &&
                              allCategories.map((cat) => (
                                <li key={cat.slug}>
                                  <Link
                                    to={`${cat.categoryType !== "in_auction_show" ? `/sub-category/` : `/live-auctions/sub-category/`}${cat.slug}?lat=${lat}&lng=${lng}&keyword=${keyword}&weeklydeals=${weeklydeals}&page=search`}
                                    className="goclass01"
                                  >
                                    {cat.categoryName} {cat.categoryType === "in_auction_show" && <span className="text-color-sky">In Auction Show</span>} (
                                    {cat.productCount})
                                  </Link>

                                </li>
                              ))}
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                      <div className="product-listing-sideBar-colum sideBar-category">
                        <h2>Price</h2>
                        <div className="row">
                          <div className="col-5 pr-1">
                            <div className="form-group">
                              <input
                                type="number"
                                name="min_price"
                                onChange={this.priceHandler}
                                className="form-control-line"
                                placeholder="Min"
                              />
                            </div>
                          </div>
                          <div className="col-5 pr-1">
                            <div className="form-group">
                              <input
                                type="number"
                                name="max_price"
                                onChange={this.priceHandler}
                                className="form-control-line"
                                placeholder="Max"
                              />
                            </div>
                          </div>
                          <div className="col-2 px-0">
                            <button className="go-btn" onClick={context.handlePriceData}>GO</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="product-listing-sideBar-colum other-option otheropt_newcls">
                      <h2>Other Options</h2>
                      <div className="otheroption_cheperent ">
                        <div class="check-box">
                          <input
                            type="checkbox"
                            value="Yes"
                            name="liveAuction"
                            id="live-auction"
                            onClick={context.handleLiveActionSorting}
                          />
                          <label for="live-auction">
                            Live Auction
                          </label>
                        </div>
                        <div class="check-box">
                          <input
                            type="checkbox"
                            value="Yes"
                            name="upcomingLiveAuction"
                            id="upcoming-auction"
                            onClick={context.handleLiveActionSorting}
                          />
                          <label for="upcoming-auction">
                            Upcoming Live Auction
                          </label>
                        </div>
                        <div className="check-box">
                          <input
                            type="checkbox"
                            value="Yes"
                            name="AvailableBuyNow"
                            id="available-buy-now"
                            onChange={this.handleDeliveryOptionSorting}
                          />
                          <label htmlFor="available-buy-now">
                            Available for Shipping
                          </label>
                        </div>
                        <div className="check-box">
                          <input
                            type="checkbox"
                            value="Yes"
                            name="AvailablePickUp"
                            id="available-pickup"
                            onChange={this.handleDeliveryOptionSorting}
                          />
                          <label htmlFor="available-pickup">
                            Available for pick up
                          </label>
                        </div>
                        {General.showWeekendDeals() &&
                          <div className="check-box">
                            <input
                              type="checkbox"
                              value={1}
                              name="weeklydeals"
                              checked={this.state.weeklydeals}
                              id="weeklydeals"
                              onChange={this.handleDeliveryOptionSorting}
                            />
                            <label htmlFor="weeklydeals">
                              Weekend Deal
                            </label>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="product-listing-sideBar mobile_fillter" id="side-bar-fixed">
                    <div className="fillter_preant">
                      <div className="sidebarleft_newinner test2">
                        <div id="filter-closer">
                          <img
                            src="/images/cancel.svg"
                            alt="icon"
                            onClick={() => {
                              this.setState({ openProductFilter: false });
                              document.body.classList.remove('filter-open');
                            }
                            }
                          />
                        </div>
                        <div className="filter-clear">
                          <button
                            className="btn btn-sm"
                            onClick={() =>
                              window.location.reload()
                            }
                          >
                            Clear Filter
                          </button>
                        </div>
                        {this.state.filterKeyWord === "location" && <div className="product-listing-sideBar-colum sideBar-location">
                          <h2>Location</h2>
                          <div className="form-group">
                            <PlacesAutocomplete
                              searchOptions={{
                                componentRestrictions: {
                                  country: "UK",
                                },
                              }}
                              value={this.state.address}
                              onChange={this.handleChange}
                              onSelect={this.handleSelect}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: "Search Places ...",
                                      className: "location-search-input",
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";

                                      const style = suggestion.active
                                        ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                        : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                          })}
                                          key={suggestion.placeId}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div className="form-group mb-0 sideBar-choose-distance">
                                    <div className="form-group-icon-bx">
                                      <input
                                        type="number"
                                        name="radius"
                                        onChange={context.handleRadiusFilter}
                                        className="form-control-line"
                                        placeholder="Distance"
                                      />
                                    </div>
                                    <button
                                      className="go-btn"
                                      onClick={
                                        context.handleCatNegotiation
                                      }
                                    >
                                      GO
                                    </button>
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </div>}
                        {this.state.filterKeyWord === "category" &&
                          <Accordion defaultExpanded>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              Category
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul className="toggle-bx innerfilt_topcat">
                                {allCategories &&
                                  allCategories.map((cat) => (
                                    <li key={cat.slug}>
                                      <Link
                                        to={`${cat.categoryType !== "in_auction_show" ? `/sub-category/` : `/live-auctions/sub-category/`}${cat.slug}?lat=${lat}&lng=${lng}&keyword=${keyword}&weeklydeals=${weeklydeals}&page=search`}
                                        className="goclass01"
                                        onClick={context.handleOverlayClick}
                                      >
                                        {cat.categoryName} {cat.categoryType === "in_auction_show" && <span className="text-color-sky">In Auction Show</span>} (
                                        {cat.productCount})
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        }
                        {this.state.filterKeyWord === "price" &&
                          <div className="product-listing-sideBar-colum sideBar-category">
                            <h2>Price</h2>
                            <div className="row">
                              <div className="col-5 pr-1">
                                <div className="form-group">
                                  <input
                                    type="number"
                                    name="min_price"
                                    onChange={this.priceHandler}
                                    className="form-control-line"
                                    placeholder="Min"
                                  />
                                </div>
                              </div>
                              <div className="col-5 pr-1">
                                <div className="form-group">
                                  <input
                                    type="number"
                                    name="max_price"
                                    onChange={this.priceHandler}
                                    className="form-control-line"
                                    placeholder="Max"
                                  />
                                </div>
                              </div>
                              <div className="col-2 px-0">
                                <button className="go-btn" onClick={context.handlePriceData}>GO</button>
                              </div>
                            </div>
                          </div>
                        }
                        {this.state.filterKeyWord === "sort" && <div className="form-group-icon-bx">
                          <select
                            className="form-control-line"
                            onChange={context.handleFilterSorting}
                          >
                            <option value="">Most Recent First</option>
                            <option value="LowToHigh">Price: Low to High</option>
                            <option value="HighToLow">Price: High to Low</option>
                            <option value="RatingLowToHigh">Rating: Low to High</option>
                            <option value="RatingHighToLow">Rating: High to Low</option>
                          </select>
                          <span className="form-group-icon">
                            <img src="/images/down-arrow-icon.png" alt="icon" />
                          </span>
                        </div>}
                        {this.state.filterKeyWord === "other" &&
                          <div className="product-listing-sideBar-colum other-option otheropt_newcls">
                            <h2>Other Options</h2>
                            <div className="otheroption_cheperent ">
                              <div class="check-box">
                                <input
                                  type="checkbox"
                                  value="Yes"
                                  name="liveAuction"
                                  id="live-auction"
                                  onClick={context.handleLiveActionSorting}
                                />
                                <label for="live-auction">
                                  Live Auction
                                </label>
                              </div>
                              <div class="check-box">
                                <input
                                  type="checkbox"
                                  value="Yes"
                                  name="upcomingLiveAuction"
                                  id="upcoming-auction"
                                  onClick={context.handleLiveActionSorting}
                                />
                                <label for="upcoming-auction">
                                  Upcoming Live Auction
                                </label>
                              </div>
                              <div className="check-box">
                                <input
                                  type="checkbox"
                                  value="Yes"
                                  name="AvailableBuyNow"
                                  id="available-buy-now"
                                  onChange={this.handleDeliveryOptionSorting}
                                />
                                <label htmlFor="available-buy-now">
                                  Available for Shipping
                                </label>
                              </div>
                              <div className="check-box">
                                <input
                                  type="checkbox"
                                  value="Yes"
                                  name="AvailablePickUp"
                                  id="available-pickup"
                                  onChange={this.handleDeliveryOptionSorting}
                                />
                                <label htmlFor="available-pickup">
                                  Available for pick up
                                </label>
                              </div>
                              {General.showWeekendDeals() &&
                                <div className="check-box">
                                  <input
                                    type="checkbox"
                                    value={1}
                                    name="weeklydeals"
                                    checked={this.state.weeklydeals}
                                    id="weeklydeals"
                                    onChange={this.handleDeliveryOptionSorting}
                                  />
                                  <label htmlFor="weeklydeals">
                                    Weekend Deal
                                  </label>
                                </div>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                {/* <div
                  id="filter-toggler"
                  className="btnx btn-secondaryx mx-3"
                  onClick={() => this.setState({ openProductFilter: true })}
                >
                  All Filter
                </div> */}
                <div className="fillter_btn_box">
                  <div
                    id="filter-toggler"
                    className="btnx btn-secondaryx fillter_btn_track"
                    onClick={() => {
                      this.setState({ openProductFilter: true, filterKeyWord: "category" });
                      document.body.classList.add('filter-open');
                    }}
                  >
                    <div className="fillter_trackMobile">
                      <span>Category</span>
                      <span className="dropdown_arrow">
                        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    id="filter-toggler"
                    className="btnx btn-secondaryx fillter_btn_track"
                    onClick={() => {
                      this.setState({ openProductFilter: true, filterKeyWord: "location" })
                      document.body.classList.add('filter-open');
                    }}
                  >
                    <div className="fillter_trackMobile">
                      <span>Location</span>
                      <span className="dropdown_arrow">
                        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    id="filter-toggler"
                    className="btnx btn-secondaryx fillter_btn_track"
                    onClick={() => {
                      this.setState({ openProductFilter: true, filterKeyWord: "sort" });
                      document.body.classList.add('filter-open');
                    }}
                  >
                    <div className="fillter_trackMobile">
                      <span>Sort</span>
                      <span className="dropdown_arrow">
                        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    id="filter-toggler"
                    className="btnx btn-secondaryx fillter_btn_track"
                    onClick={() => {
                      this.setState({ openProductFilter: true, filterKeyWord: "price" })
                      document.body.classList.add('filter-open');
                    }}
                  >
                    <div className="fillter_trackMobile">
                      <span>Price</span>
                      <span className="dropdown_arrow">
                        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    id="filter-toggler"
                    className="btnx btn-secondaryx fillter_btn_track"
                    onClick={() => {
                      this.setState({ openProductFilter: true, filterKeyWord: "other" })
                      document.body.classList.add('filter-open');
                    }}
                  >
                    <div className="fillter_trackMobile">
                      <span>Other Filter</span>
                      <span className="dropdown_arrow">
                        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="Jcompny_allprod">
                  {showHideListData && (
                    <div className="Jtaballp_content atlistingside_new">
                      {allSearchProducts.length > 0 ? (
                        <>
                          {allSearchProducts.map(function (row) {
                            return (
                              <div className="row" key={row._id}>
                                <div
                                  className="col-lg-12 listside_newperent"
                                  key={"allSearchProducts" + row._id}
                                >
                                  <div className="product-listing-product">
                                    <div className="row">
                                      <div className="col-3">
                                        <div className="product-img-bx">
                                          {moment(row?.startTime).diff(
                                            moment(),
                                            "seconds"
                                          ) <= 0 ? (<span className="live-tag">
                                            <i className="fas fa-circle"></i> Live
                                          </span>) : ""}
                                          <Link
                                            to={"/product/" + row.productSlug}
                                          >
                                            {row.adType == "Featured" && (
                                              <div className="specialtags">
                                                <span className="black_special">
                                                  {row.adType}
                                                </span>
                                              </div>
                                            )}
                                            {row.productMainImage ? (
                                              <img
                                                src={General.showImage(
                                                  "products/resize-566-400",
                                                  row.productMainImage
                                                )}
                                                alt="uploaded image"
                                              />
                                            ) : (
                                              <img
                                                src="/images/no-image.jpg"
                                                alt="product"
                                              />
                                            )}
                                            <div className="totalimg_sectab">
                                              {/*<Link to="#" className="totlimg_qt">2 <i className="fas fa-camera"></i></Link>*/}
                                            </div>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="col-9">
                                        <div className="product-outer-bx productBig-outer-bx">
                                          <div className="product-name-bx content_gobottom_new">
                                            <time>{row.time}</time>
                                            <Link
                                              to={"/product/" + row.productSlug}
                                            >
                                              <h4>{row.productName}</h4>
                                            </Link>
                                            <h5>
                                              {row?.location}
                                            </h5>
                                            <h6>
                                              <span>
                                                {General.showPriceDecimal(
                                                  row.productPrice
                                                )}
                                              </span>
                                            </h6>
                                            {row.productDescription && (
                                              <p>{`${row.productDescription.substring(
                                                0,
                                                80
                                              )}...`}</p>
                                            )}
                                            {row.isWishlist === 1 ? (
                                              <button
                                                className="add-to-wishlist"
                                                onClick={() =>
                                                  context.removeWishlistHandler(
                                                    row._id
                                                  )
                                                }
                                              >
                                                <i className="fas fa-heart"></i>
                                              </button>
                                            ) : (
                                              <button
                                                className="add-to-wishlist"
                                                onClick={() =>
                                                  context.addWishlistHandler(
                                                    row._id
                                                  )
                                                }
                                              >
                                                <i className="far fa-heart"></i>
                                              </button>
                                            )}
                                            {row.condition && (
                                              <div className="usednew_button">
                                                <button
                                                  type="button"
                                                  className="btnnew_used"
                                                >
                                                  {row.condition}
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {this.state.nothingD ? (
                            <div className="alert alert-danger col-lg-12">
                              <strong>Info!</strong> Nothing here.
                            </div>
                          ) : (
                            <>
                              {this.state.productSkeleton.map(function (
                                rowsskeleton
                              ) {
                                return (
                                  <ContentLoader
                                    viewBox="0 0 1380 300"
                                    height={340}
                                    width={1380}
                                  >
                                    <rect
                                      x="20"
                                      y={rowsskeleton.id}
                                      rx="10"
                                      ry="10"
                                      width="300"
                                      height="300"
                                    />
                                    <rect
                                      x="360"
                                      y={rowsskeleton.id}
                                      rx="10"
                                      ry="10"
                                      width="1000"
                                      height="300"
                                    />
                                  </ContentLoader>
                                );
                              })}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {showHideGridData && (
                    <div className="Jtaballp_content02 auctionplist_main ">
                      <div className="col-sm-12 actionpage_list">
                        <div
                          className={
                            this.state.nothingD ? "row" : "row low-margin"
                          }
                        >
                          {allSearchProducts && allSearchProducts.length > 0 ? (
                            <>
                              {allSearchProducts.map(function (row) {
                                return (
                                  <div
                                    className="col-sm-3"
                                    key={"allSearchProducts" + row._id}
                                  >
                                    <div className="product-outer-bx product_card_box">
                                      <Link to={
                                        row?.proType && row?.proType === "auction"
                                          ? Product.isLogin()
                                            ? {
                                              pathname: `/livestreamingProduct/${row?.livestreamingId}`,
                                              query: {
                                                productId: row.productSlug,
                                              },
                                            }
                                            : `/upcomingLiveStreamingProduct/${row.productSlug}/${row?.livestreamingId}`
                                          : `/product/${row.productSlug}`
                                      }>
                                        <div className="product-img-bx">
                                          {moment(row?.startTime).diff(
                                            moment(),
                                            "seconds"
                                          ) <= 0 ? (<span className="live-tag">
                                            <i className="fas fa-circle"></i> Live
                                          </span>) : ""}
                                          {row.productMainImage ? (
                                            <img
                                              src={General.showImage(
                                                "products/resize-566-400",
                                                row.productMainImage
                                              )}
                                              alt="uploaded image"
                                            />
                                          ) : (
                                            <img
                                              src="/images/no-image.jpg"
                                              alt="product"
                                            />
                                          )}
                                          {row.adType == "Featured" && (
                                            <div className="specialtags">
                                              <span className="black_special">
                                                {row.adType}
                                              </span>
                                            </div>
                                          )}
                                          {row.adType == "Urgent" && (
                                            <div className="specialtags">
                                              <span className="red_special">
                                                {row.adType}
                                              </span>
                                            </div>
                                          )}
                                          {row.adType == "Spotlight" && (
                                            <div className="specialtags">
                                              <span className="green_special">
                                                {row.adType}
                                              </span>
                                            </div>
                                          )}
                                          <div className="totalimg_sectab">
                                          </div>
                                        </div>
                                        <div className="product-name-bx">

                                          {row?.proType && row?.proType === "auction" && moment(row?.startTime).diff(
                                            moment(),
                                            "seconds"
                                          ) > 0 ? (
                                            <Countdown
                                              date={row?.startTime}
                                              zeroPadTime={2}
                                              renderer={({ days, hours, minutes, seconds }) => (
                                                <time>
                                                  {days +
                                                    " days" +
                                                    " " +
                                                    hours +
                                                    ":" +
                                                    minutes +
                                                    ":" +
                                                    seconds}{" "}
                                                  to go
                                                </time>
                                              )}
                                            />) : ""}
                                          <h6>
                                            {row.isWeekEndDeal && row.weekDealStart ? (
                                              <div>
                                                <span>
                                                  {General.showPriceDecimal(row?.weekendPrice)}
                                                </span>
                                                <del className="ml-1">
                                                  {General.showPriceDecimal(row.productPrice)}
                                                </del>
                                                <p className="discountt">
                                                  -{row?.discountPercent}%
                                                </p>

                                              </div>
                                            ) : (
                                              <span>
                                                {General.showPriceDecimal(
                                                  row.productPrice
                                                )}
                                              </span>
                                            )}

                                          </h6>
                                          {/* <Rating
                                            fillColor="#2f9ee4"
                                            className="rating-product"
                                            initialValue={row?.rating}
                                            allowFraction={true}
                                            readonly={true}
                                            size={15}
                                          /> */}
                                          <h4>{row.productName}</h4>
                                          {console.log("row", row)}
                                          {row?.location && <h3>
                                            {row?.location}
                                          </h3>}
                                          {row?.condition &&
                                            (
                                              <>
                                                <div className="item-condition">
                                                  <span className="item-conditionTag">{row?.condition}</span>
                                                </div>
                                              </>
                                            )
                                          }
                                          {row?.userId && <div className="d-inline-flex- d-none">
                                            <div className="musr_img">
                                              {row?.userId &&
                                                row.userId
                                                  ?.profilePicture ? (
                                                <img
                                                  src={General.showImageNew(
                                                    "profile",
                                                    row.userId
                                                      ?.profilePicture
                                                  )}
                                                  alt={row.userId.firstName}
                                                  className="mCS_img_loaded"
                                                />
                                              ) : (
                                                <img
                                                  src="/images/user.png"
                                                  alt="image"
                                                  className="mCS_img_loaded"
                                                />
                                              )}
                                            </div>
                                            <h4 className="ml-2">{`${row?.userId?.firstName} ${row?.userId?.lastName}`}</h4>
                                          </div>}
                                        </div>
                                      </Link>
                                      {row.isWishlist === 1 ? (
                                        <button
                                          className="add-to-wishlist"
                                          onClick={() =>
                                            context.removeWishlistHandler(
                                              row._id
                                            )
                                          }
                                        >
                                          <i className="fas fa-heart"></i>
                                        </button>
                                      ) : (
                                        <button
                                          className="add-to-wishlist"
                                          onClick={() =>
                                            context.addWishlistHandler(row._id)
                                          }
                                        >
                                          <i className="far fa-heart"></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {this.state.nothingD ? (
                                <div className="alert alert-danger col-lg-12">
                                  <strong>Info!</strong> Nothing here.
                                </div>
                              ) : (
                                <>
                                  {this.state.productSkeleton.map(function (
                                    rowsskeleton
                                  ) {
                                    return (
                                      <ContentLoader
                                        viewBox="0 0 1380 300"
                                        height={340}
                                        width={1380}
                                        key={rowsskeleton.id}
                                      >
                                        <rect
                                          x="20"
                                          y={rowsskeleton.id}
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="360"
                                          y={rowsskeleton.id}
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="700"
                                          y={rowsskeleton.id}
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="1040"
                                          y={rowsskeleton.id}
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                      </ContentLoader>
                                    );
                                  })}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="loading-bx">
                  {this.state.totalRecords > 0 && (
                    <>
                      <p>
                        <span>
                          You have viewed {this.state.limit} of{" "}
                          {this.state.totalRecords} Ads
                        </span>
                      </p>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <span className="sr-only">70% Complete</span>
                        </div>
                      </div>
                    </>
                  )}

                  {this.state.next_page_number > 0 && (
                    <button
                      onClick={context.getDatabyPageno.bind(
                        context,
                        this.state.next_page_number
                      )}
                    >
                      LOAD MORE
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="side-bar-stoper"></div>
          </div>
        </section>
        <section className="bg-light-dark">
          <div></div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(ProductsList);
